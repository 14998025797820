/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { css } from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

const customError = css`
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  z-index: 9;
  background: white;

  a {
    color: var(--color-blue-violet);
  }
  
  a:hover {
    text-decoration: underline;
    color: var(--color-blue-violet);
  }
`;

const flexCol = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const titleError = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-28);
  margin: 2vh 0;
`;
const textError = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-20);
  margin-bottom: 2vh;
`;

const logo = css`
  position: relative;
  width: 25vw;
  height: 10vh;
`;

const buttonContained = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14vw;
  height: 6vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000070;
  border: 1px solid #5833FF;

  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-21);
  color: #5833FF;

  &:hover {
    background: #5833ff 0% 0% no-repeat padding-box;
    color: #FFFFFF !important;
  }
`;

const goBack = css`
    font-size: var(--size-21);
    margin-top: 2vh;
    cursor: pointer;

    &:hover {
      color: var(--color-blue-violet);
      text-decoration: underline;
    }
`;

const Error = ({ statusCode, err = null, res = null }) => {
  const router = useRouter();

  console.error({ err, res, statusCode });
  return (
    <>
      <div css={customError}>
        <div css={flexCol}>
          <div css={logo}>
            <Image src="/Logo.svg" alt="logo" fill sizes="100vw" />
          </div>
          {statusCode === 404 ? (
            <>
              <span css={titleError}>Page introuvable</span>
              <span css={textError}>La page n&apos;existe pas ou l&apos;adresse est incorrecte</span>
            </>
          ) : (
            <>
              <span css={titleError}>Une erreur est survenue</span>

              <span css={textError}>Désolé, le site web a rencontré une erreur inattendue.</span>
              <span css={textError}>
                Nous travaillons à résoudre ce problème. Si ce dernier persiste, vous pouvez directement
                {' '}
                <a href="https://happyneuronacademy.zendesk.com/hc/fr/requests/new" target="_blank" rel="noreferrer">nous contacter</a>
                .
              </span>
            </>
          )}

          <Link href="/mon-academy" shallow css={buttonContained}>Accéder à l&apos;Academy</Link>
          <div onKeyPress={() => router.back()} tabIndex={0} onClick={() => router.back()} css={goBack} role="link">Retour</div>
        </div>
      </div>
    </>
  );
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode, err, res };
};

export default Error;
