import { memo, useEffect, useState } from 'react';
import Image from 'next/image';
import useScrollableSteps from 'hooks/useScrollableSteps';
import { getPermanentUrl } from 'utils/url';
import SC from './style';
import useAuth from 'hooks/useAuth';
import { DivFlex } from 'styles/common';
import { text1 } from 'components/Texts/style';
import useWindowSize from 'hooks/useWindowSize';

export interface EventsProps {
  events: any
}

const Events: React.FC<EventsProps> = ({ events }) => {
  const [steps, setSteps] = useState([]);
  const [pauseInterval, setPauseInterval] = useState(false);
  const { currentStep, handleScroll } = useScrollableSteps('slideshow', steps);
  const { user } = useAuth();
  const size = useWindowSize();

  useEffect(() => {
    if (!events?.length) return;
    const elem = document.getElementById('slideshow') as any;
    const newSteps = events.map((_, i) => (i === 0 ? 0 : elem.clientWidth * i));
    setSteps(newSteps);
  }, [events, size]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pauseInterval) {
        handleScroll(currentStep + 1);
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [steps, handleScroll]);

  if (events?.error) return null;

  return (
    <div css={SC.events}>
      <DivFlex align="baseline">
        <h1 css={[SC.invisible, text1]} role="presentation">invisibl</h1>
      </DivFlex>
      <div css={SC.flexEnd}>
        {events?.map((e, i) => (
          <SC.Dot
            key={e.id}
            selected={i === currentStep}
            onClick={() => handleScroll(i)}
          />
        ))}
      </div>
      <div id="slideshow" css={SC.slideshow}>
        {events?.map((event) => (
          <a
            key={event.id}
            css={SC.event}
            onMouseEnter={() => setPauseInterval(true)}
            onMouseLeave={() => setPauseInterval(false)}
            href={user ? event.link : '/connect/login'}
            target="__blank"
          >
            <div css={SC.content}>
              <h2 css={SC.title}>{event.title}</h2>
              <p css={SC.desc}>{event.text}</p>
            </div>
            <div css={SC.image}>
              <SC.DivImage>
                <Image
                  css={!event.image && SC.marginImg}
                  src={event.image ? getPermanentUrl(event.image, '-164x46') : '/logo-HNA-small.svg'}
                  alt="evenement"
                  fill
                  sizes="100vw"
                />
              </SC.DivImage>
            </div>
          </a>
        ))}
      </div>
      <h3 css={SC.vosEvents}>Vos événements Happyneuron Academy</h3>
    </div>
  );
};

export default memo(Events);
