import styled, { css } from 'styled-components';

const SideBarFormation = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme: { mobile } }) => (mobile ? '100%' : '29vw')};
  margin:  ${({ theme: { mobile } }) => (mobile ? '0 0 4vh 0' : '0 4.74vw 0 0')};
  padding: ${({ theme: { mobile } }) => (mobile ? '0 4vw' : '0 0 0 12.34vw')};
  /* height: calc(100vh - var(--footer-height) - 9.07vh); */
  overflow-y: auto;
`;

const title = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  color: var(--color-black-02);
  font-size: var(--size-24);
  line-height: 1.55;
  margin-bottom: 0.463vh;
  -webkit-text-stroke: 1px var(--color-black);
`;

const button = css`
display: inline-flex;
align-items: center;
justify-content: center;
width: 100%;
min-height: 6.66vh;
height: 6.66vh;
background: var(--color-black) 0% 0% no-repeat padding-box;
/* box-shadow: 0px 3px 12px #00000070; */
margin: 1vh auto 0 auto;
cursor: pointer;

font-family: var(--font-family-sans);
font-weight: 600;
font-size: var(--size-24);
color: white;
border: none;

&:hover {
  color: white;
}
`;

const buttonDisabled = css`
display: inline-flex;
align-items: center;
justify-content: center;
width: 100%;
min-height: 6.66vh;
height: 6.66vh;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
margin: 1vh auto 0 auto;
cursor: default;

font-family: var(--font-family-sans);
font-weight: 600;
font-size: var(--size-24);
    color: rgba(0, 0, 0, 0.26);
border: none;
`;

const ButtonLive = styled.button<any>`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
background: ${({ ready }) => (ready ? 'var(--color-black) 0% 0% no-repeat padding-box' : 'rgb(26 26 26 / 40%) 0% 0% no-repeat padding-box')};
/* box-shadow: 0px 3px 12px #00000070; */
margin: 1vh auto 0 auto;
cursor: pointer;

font-family: var(--font-family-sans);
font-weight: 600;
font-size: var(--size-24);
color: white;
border: none;
padding: 0.5vh 0vw;

&:hover {
  color: white;
  #cta-search {
      visibility: visible;
      /* transition: visibility 0s ease-out 0s; */
    }
}
p {
  text-align: center;
  color: white;
  text-transform: uppercase;
  opacity: ${({ ready }) => (ready ? '1' : '0.4')};
}
`;

const LiveCircle = styled.div<any>`
  width: 0.52vw;
  height: 0.52vw;
  background-color: ${({ ready }) => (ready ? 'rgb(255 54 54)' : 'rgb(255 54 54 / 40%)')};
  margin-left: 0.46875vw;
  border-radius: 50%;
`;

const contentType = css`
  display: inline-flex;
  margin-right: auto;
  padding: 0.185vh 0.9375vw;
  text-transform: uppercase;
  font-size: var(--size-21);
  background-color: var(--color-deep-sky-blue);
  color: white;
  margin-bottom: 0.74vh;
  line-height: 1.3;
`;

const tagMain = css`
  color: var(--color-gray-warm);
  text-transform: uppercase;
  margin-right: auto;
  font-size: var(--size-21);
  line-height: 1.29;
  margin-bottom: 1.111vh;
`;

const themes = css`
  font-size: var(--size-16);
  color: var(--color-gray-warm);
  line-height: var(--size-17);
`;

const voteNbr = css`
  font-size: var(--size-16);
  color: var(--color-gray-warm);
  line-height: var(--size-17);
`;

const formateur = css`
  color: var(--color-black-02);
  font-size: var(--size-20);
  font-family: var(--font-family-sans);
  font-weight: bold;
  margin-top: 0.926vh;
  line-height: var(--size-25);
`;

const formateurName = css`
  color: var(--color-light-royal-blue);
  font-size: var(--size-21);
  line-height: var(--size-25);
  text-transform: uppercase;
  cursor: pointer;
`;

const ctaSubscription = css`
  visibility: hidden;
  position: absolute;
  background-color: rgb(26 26 26 / 91%);
  width: min-content;
  left: 30vw;
  z-index: 100;
  padding: 1.48vh 1.3vw;
  border-radius: 15px;

  transition: visibility 0.2s ease-out 0s;

  &:after {
    content: ' ';
    width: 0;
    height: 0px;
    border-top: 1.3888vh solid transparent;
    border-left: 1.0416vw solid rgb(26 26 26 / 91%);
    border-bottom: 1.3888vh solid transparent;
    border-right: 0 solid transparent;
    right: 100%;
    position: absolute;
    top: 44%;
    transform: rotate(180deg);
  }
`;

const sessionText = css`
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-24);
  margin-bottom: 1.48vh;
  margin-top: 3vh;
  line-height: var(--size-25);
  color: var(--color-black-02);
`;

const SessionBlock = styled.button<any>`
  min-height: 6.48vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5vh 0;
  ${({ available, buyed, disabled }) => (available ? css`
    background-color: ${disabled ? '#26262663' : 'var(--color-black-02)'};
    color: white;
    cursor: ${disabled ? 'default' : 'pointer'};
  ` : css`
    background-color: transparent;
    border-top: ${buyed ? 'none' : '0.01vh solid rgb(38 38 38 / 17%)'};
    border-bottom: ${buyed ? 'none' : '0.01vh solid rgb(38 38 38 / 17%)'};
    cursor: default;
    color: #000000;
  `)};

  ${({ disabled }) => disabled && css`
    &:hover {
    color: white;
    #cta-search {
        visibility: visible;
        /* transition: visibility 0s ease-out 0s; */
      }
    }
  `}
`;

const sessionAvailable = css`
  background-color: var(--color-black-02);
  height: 6.48vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.74vh;
  p {

  font-size: var(--size-20);
  color: white;
  font-family: var(--font-family-sans);
  font-weight: bold;
  line-height: var(--size-13);
  text-transform: uppercase;
  }
`;

const replayAvailable = css`
  background-color: var(--color-black-02);
  height: 6.48vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.74vh;
  p {

  font-size: var(--size-18);
  color: white;
  font-family: var(--font-family-sans);
  font-weight: bold;
  line-height: var(--size-13);
  text-transform: uppercase;
  }
`;

const sessionDate = css`
  font-size: var(--size-13);
  font-family: var(--font-family-sans);
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  color: #000000;
  text-align: left;
`;

const sessionPlace = css`
font-size: var(--size-12);
  color: #707070;
`;

const accessReplays = css`
  font-size: var(--size-18);
  font-family: var(--font-family-sans);
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  margin: auto;
`;

const votes = css`
  display: flex;
  margin: 2vh 0 0.44vh 0;
`;

const logo = css`
  position: relative;
  width: 10.78vw;
  min-height: 4.7vh;
  margin-bottom: 1.85vh;
`;

const connectedTxt = css`
  font-family: var(--font-family-sans);
  color: #000;
  font-size: var(--size-18);
  line-height: 1.76vh;
  text-align: center;
  width: 85%;
  margin: 2vh auto;
  &:hover {
    text-decoration: underline;
  }
`;

const loader = css`
  width: 100%;
  height: 60%;
`;

const subSession = css`
  font-size: var(--size-13);
  font-family: var(--font-family-sans);
  font-weight: bold;
  color: #000000;
  line-height: 1.31;
`;

const sessionList = css`
  width: 100%;
  max-height: 20vh;
  position: relative;
`;

const sessionListScroll = css`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const fading = css<any>`
  width: 100%;
  height: 5vh;
  background: transparent linear-gradient(180deg,#FFFFFF 0%,#FFFFFFFC 26%,#FFFFFFD6 45%,#FFFFFF00 100%) 0% 0% no-repeat padding-box;
  -webkit-transform: matrix(-1,0,0,-1,0,0);
  -ms-transform: matrix(-1,0,0,-1,0,0);
  transform: matrix(-1,0,0,-1,0,0);
  position: absolute;
  bottom: -0.5vh;
  z-index: 1000;
`;

const asterix = css`
  font-size: var(--size-16);
  font-family: var(--font-family-sans);
  color: #000000;
  margin-top: 1vh;
  line-height: 1.25;
`;

const ctaSearch = css`
  visibility: hidden;
  position: absolute;
  background-color: rgb(26 26 26 / 91%);
  width: min-content;
  margin-bottom: 18vh;
  width: 35vw;
  z-index: 1001;
  padding: 1.48vh 1.3vw;
  border-radius: 15px;
  /* transition: visibility 0.2s ease-out 0s; */
  p {
    color: white;
    font-family: var(--font-family-sans);
    font-size: var(--size-18);
    line-height: 1.3;
  }
`;

const starSvg = css`
  width: 1.64vw;
  height: 2.708vh;
  margin-right: 0.1vw;
`;

const hoverCoach = css`
  position: fixed;
  z-index: 2000;
`;

const relative = css`
  position: relative;
`;

const egnosia = css`
  font-size: var(--size-16);
  font-weight: bold;
  color: var(--color-deep-sky-blue);
  font-family: var(--font-family-serif);
  margin-top: 0.3vh;
`;

export default {
  ctaSubscription,
  relative,
  hoverCoach,
  starSvg,
  subSession,
  egnosia,
  asterix,
  loader,
  SideBarFormation,
  sessionListScroll,
  title,
  fading,
  button,
  buttonDisabled,
  ButtonLive,
  logo,
  tagMain,
  contentType,
  themes,
  voteNbr,
  formateur,
  formateurName,
  sessionText,
  sessionList,
  SessionBlock,
  sessionDate,
  accessReplays,
  sessionAvailable,
  replayAvailable,
  sessionPlace,
  votes,
  connectedTxt,
  LiveCircle,
  ctaSearch,
};
