import {
  useEffect, useState,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useAuth from 'hooks/useAuth';
import SC from './style';
import useThemesFilters from 'hooks/useThemesFilters';
import { SvgHeart, SvgLogoSmallLoader } from 'components/Svgs';
import usePreferences from 'hooks/usePreferences';
import useDatas from 'hooks/useDatas';
import { filterListMoment } from 'utils/formatListByContentType';
import useLaunchOffer from 'hooks/useLaunchOffer';
import useHistory from 'hooks/useHistory';

export interface ThematicProps {
  themes?: any
  exploreAll?: any
  onClick?: (arg0: any) => void
}

const Thematic: React.FC<ThematicProps> = ({
  exploreAll,
}) => {
  const [hasFading, setHasFading] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(0);
  const { user } = useAuth();
  const { home } = useHistory();

  const { displayHeadband } = useLaunchOffer();

  const {
    themes, filters, handleFilters, contentCount, trainingCount,
  } = useThemesFilters();
  const { preferences } = usePreferences();
  const datas = useDatas();
  const isSelected = (theme) => filters?.filter((element) => element.id === theme.id).length;

  const today = Date.now();
  const getCount = (theme) => {
    let count = 0;
    if (exploreAll) {
      if (!datas[exploreAll]) {
        if (home === 'informations') {
          if (['actualite'].includes(exploreAll)) {
            if (theme?.id === 0) {
              return contentCount;
            }
            return theme?.contentCount;
          }
          if (exploreAll === 'listProgrammeMonth') {
            const filteredContents = datas.contentList?.filter((el) => (new Date(el.publicationDate).getMonth() === 2));
            if (theme.id === 0) {
              return filteredContents?.length;
            }
            filteredContents?.forEach((value) => {
              value.themes.forEach((t) => {
                if (theme.id === t.id) count += 1;
              });
            });
            return count;
          }
          if (theme.id === 0) {
            return datas.formattedContentsList?.[exploreAll]?.length;
          }
          datas.formattedContentsList?.[exploreAll]?.forEach((value) => {
            value.themes.forEach((t) => {
              if (theme.id === t.id) count += 1;
            });
          });
        } else if (home === 'formations' && exploreAll === 'newFormations') {
          const filteredTrainings = filterListMoment(datas.trainingList, filters, today);
          if (theme.id === 0) {
            return filteredTrainings?.length;
          }
          filteredTrainings?.forEach((value) => {
            value.themes.forEach((t) => {
              if (theme.id === t.id) count += 1;
            });
          });
        } else if (home === 'formations' && exploreAll === 'listProgrammeMonth') {
          const filteredTrainings = datas.trainingList?.filter((el) => (new Date(el.creationDate).getMonth() === 1));
          if (theme.id === 0) {
            return filteredTrainings?.length;
          }
          filteredTrainings?.forEach((value) => {
            value.themes.forEach((t) => {
              if (theme.id === t.id) count += 1;
            });
          });
        } else if (home === 'formations' && exploreAll !== 'newFormations') {
          if (theme.id === 0) {
            return datas.formattedTrainingsList?.[exploreAll]?.length;
          }
          datas.formattedTrainingsList?.[exploreAll]?.forEach((value) => {
            value.themes.forEach((t) => {
              if (theme.id === t.id) count += 1;
            });
          });
        } else if (home === 'mon-academy' && exploreAll === 'listProgrammeMonth') {
          const filteredTrainings = datas.trainingList?.filter((el) => (new Date(el.creationDate).getMonth() === 1));
          const filteredContents = datas.contentList?.filter((el) => (new Date(el.publicationDate).getMonth() === 2));
          if (theme.id === 0) {
            return filteredTrainings?.length + filteredContents?.length;
          }
          filteredTrainings?.forEach((value) => {
            value.themes.forEach((t) => {
              if (theme.id === t.id) count += 1;
            });
          });
          filteredContents?.forEach((value) => {
            value.themes.forEach((t) => {
              if (theme.id === t.id) count += 1;
            });
          });
        }
        return count;
      }
      if (theme.id === 0) {
        return datas[exploreAll]?.filter((el) => {
          if (home === 'informations') return el.contentType;
          if (home === 'formations') return el.displayType;
          return true;
        })?.length;
      }
      datas[exploreAll]?.forEach((value) => {
        if ((home === 'informations' && value.contentType) || (home === 'formations' && value.displayType) || home === 'mon-academy') {
          value.themes.forEach((t) => {
            if (theme.id === t.id) count += 1;
          });
        }
      });
      return count;
    }
    if (theme?.id === 0) {
      if (home === 'formations') return trainingCount;
      if (home === 'informations') return contentCount;
    } else {
      if (home === 'formations') return theme?.trainingCount;
      if (home === 'informations') return theme?.contentCount;
    }
    return '-';
  };

  useEffect(() => {
    const element = document.getElementById('thematic');
    const outputsize = () => {
      if (element.scrollHeight <= element.offsetHeight) setHasFading(false);
    };
    outputsize();

    new ResizeObserver(outputsize).observe(element);
  }, []);

  useEffect(() => {
    const element = document.getElementById('thematic');

    setScrollWidth(element?.offsetWidth - element?.clientWidth);
    if (element?.offsetHeight + element?.scrollTop >= element?.scrollHeight - 5) {
      setHasFading(false);
    } else setHasFading(true);
    const handleFading = () => {
      if (element?.offsetHeight + element?.scrollTop >= element?.scrollHeight - 5) {
        setHasFading(false);
      } else setHasFading(true);
    };

    element?.addEventListener('scroll', handleFading);

    return () => {
      element?.removeEventListener('scroll', handleFading);
    };
  }, [themes, exploreAll]);

  const hasHeadband = !user || displayHeadband;

  return (
    <SC.Thematic className="scrollbar-fixed" hasHeadband={hasHeadband} id="thematic">
      {!themes.length && <div css={SC.loader}><SvgLogoSmallLoader /></div>}

      <TransitionGroup component="ul" id="themes">
        {hasFading && (
        <CSSTransition
          key="fading"
          timeout={200}
          classNames="theme"
        >
          <SC.Fading hasHeadband={hasHeadband} scrollWidth={scrollWidth} />
        </CSSTransition>
        )}

        {themes.map((theme, index) => {
          const count = getCount(theme);
          if (!count) return null;
          const selected = isSelected(theme);
          const prefered = preferences
            .indexOf(theme.id) !== -1 || (!preferences.length && theme.id === 0);

          return (
            <CSSTransition
              key={theme.id}
              timeout={200}
              classNames="theme"
            >
              <SC.ListThemes
                key={theme.id}
                selected={selected}
                isCategory={theme.category}
                isAll={theme.id === 0}
                onClick={() => handleFilters(theme)}
                onKeyPress={() => handleFilters(theme)}
                role="menuitem"
                home={home}
              >
                <div css={SC.heart}>
                  {prefered && (
                  <SvgHeart selected={selected} home={home} />
                  )}
                </div>
                <div id={`wrap-name-${theme.id}`} css={SC.wrapName}>
                  <SC.SpanName
                    title={theme.displayName || theme.name}
                    id={`text-name-${theme.id}`}
                    selected={selected}
                    isCategory={theme.category}
                  >
                    {theme.displayName || theme.name}
                  </SC.SpanName>
                </div>
                {index > 0 && (
                <div style={{ display: 'none' }}>
                  <div css={SC.separation} />
                  <SC.ThemeCount selected={selected}>{count}</SC.ThemeCount>
                </div>
                )}
              </SC.ListThemes>
            </CSSTransition>
          );
        })}

      </TransitionGroup>
    </SC.Thematic>
  );
};
export default Thematic;
