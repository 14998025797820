/* eslint-disable max-len */
import Countdown from 'react-countdown';
import { css } from 'styled-components';
import { DivHeadbandGuest, DivHeadbandOffer } from './style';
import useLaunchOffer from 'hooks/useLaunchOffer';

const textOffer = css`
  font-style: italic;
  font-weight: normal;
`;

export const HeadbandGuest = () => (
  <DivHeadbandGuest>
    Bienvenue dans la Happyneuron Academy.
    <br />
    Un Espace personnalisé de Ressources, de Formations et de Partage d’expériences dédié aux orthophonistes !
  </DivHeadbandGuest>
);

const plurialize = (number, value) => {
  if (number > 1) return `${number} ${value}s`;
  if (number === 1) return `${number} ${value}`;
  return '';
};

export const HeadbandLaunchOffer = ({ ...props }) => {
  const {
    dateLimitOffer, endOffer, isOfferAvailable, displayHeadband,
  } = useLaunchOffer();
  const rendererOffer = () => (
    <span css={textOffer} />
  );
  const renderer = ({
    days, hours, minutes,
  }) => (
    <span>
      L’offre expire dans
      {' '}
      {plurialize(days, 'jour')}
      {days && hours ? ' ' : ''}
      {plurialize(hours, 'heure')}
      {(days || hours) && minutes ? ', ' : ''}
      {plurialize(minutes, 'minute')}
      .
    </span>
  );

  if (displayHeadband) {
    return (
      <DivHeadbandOffer {...props}>
        <div>
          <p>
            Du 11 au 17 décembre, profitez de -20 % de remise sur toutes les formations (hors FIF PL)  !
          </p>
          <p>
            Pour bénéficier de cette promotion, rendez-vous sur la boutique Happyneuron en cliquant ici.
          </p>
          <Countdown
            date={endOffer}
            renderer={rendererOffer}
          />
        </div>
      </DivHeadbandOffer>
    );
  }

  return (
    <DivHeadbandOffer {...props}>
      {!props.advantageAmount && (
      <div>
        <p>
          Offre spéciale : 3 bonnes raisons de s’abonner à l’Happyneuron Academy !
        </p>
        <Countdown
          date={dateLimitOffer}
          renderer={renderer}
        />
      </div>
      )}

      {props.advantageAmount && (
      <div>
        <p>
          Vous avez un bon d’achat de
          {' '}
          {props.advantageAmount}
          € à utiliser !
        </p>
        <span>
          Valable sur toutes les formations, pendant 1 an.
        </span>
      </div>
      )}
    </DivHeadbandOffer>
  );
};
