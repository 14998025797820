/* eslint-disable max-len */
import {
  FC, useContext, useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { ThemeContext } from 'styled-components';
import { Main } from './style';
import useAuth from 'hooks/useAuth';
import Academy from './Academy';
import Trainings from './Trainings';
import Informations from './Informations';
import { HeadbandGuest, HeadbandLaunchOffer } from 'components/Headband';
import Thematic from 'containers/Thematic';
import useDatas from 'hooks/useDatas';
import useGiveRating from 'hooks/useGiveRating';
import Modal from 'components/Modal';
import GiveRating from 'components/GiveRating';
import LaunchOffer from 'components/LaunchOffer';
import useLaunchOffer from 'hooks/useLaunchOffer';
import useHistory from 'hooks/useHistory';
import useVideo from 'hooks/useVideo';
import Exchanges from 'containers/Exchanges';
import ExchangesProvider from 'providers/Exchanges';
import { queryListChannels } from 'containers/Exchanges/actions/exchanges';
import getProfileQuery from 'containers/Exchanges/actions/getProfileQuery';
import useExchanges from 'containers/Exchanges/hooks/useExchanges';

export interface HomeProps {
  exploreAll?: any
  handleExploreAll?: any
  contentData?: any
  trainingData?: any
  pathname: string
  query: any
}

const Home: FC<HomeProps> = ({
  exploreAll, handleExploreAll, contentData, trainingData, query,
}) => {
  const { user, isSubscribed } = useAuth();
  const { mobile } = useContext(ThemeContext);
  const { video, setFixedVideo, fixedVideo } = useVideo();
  const { prevUrl, home, pathname } = useHistory();
  const router = useRouter();
  const { push } = router;
  const { dispatch } = useExchanges();
  // console.log('HOMEE');
  const {
    contentList, trainingList, selectedForYou, baughtTraining, showcaseList, topTrainings, topContents, premiumList, events,
  } = useDatas();

  const { giveRating } = useGiveRating();

  const {
    displayOffer, setDisplayOffer, displayHeadband, isOfferAvailable,
  } = useLaunchOffer();

  const hasThemes = ['informations', 'formations'].includes(home);

  let headbandHeight = !user ? 'var(--headband-guest)' : displayHeadband ? 'var(--headband-trial)' : '0vh';

  if (!user && displayHeadband) headbandHeight = 'calc(var(--headband-guest) + var(--headband-trial))';

  useEffect(() => {
    if (user) getProfileQuery(dispatch);
  }, [user]);

  const handleCloseModal = () => {
    const url = prevUrl.asPath.includes(pathname)
      ? { query: { home: [query.home[0]] } } : prevUrl;
    push({
      ...url,
    }, undefined, { shallow: true });
    // console.log({ video, fixedVideo, contentData });
    if (video.url && !fixedVideo.url) {
      setFixedVideo({
        ...video,
        url: contentData?.videoLink,
        id: contentData.id,
        show: contentData?.tags?.[0]?.name,
      });
    }
  };

  const ComponentProps = {
    exploreAll,
    handleExploreAll,
    selectedForYou,
    baughtTraining,
    showcaseList,
    topTrainings,
    topContents,
    premiumList,
    events,
    contentData,
    trainingData,
    trainingList,
    headbandHeight,
    handleCloseModal,
  };
  return (
    <>
      {!user && <HeadbandGuest />}
      {displayHeadband ? (
        <HeadbandLaunchOffer
          {...user?.profile}
          isSubscribed={isSubscribed}
          isOffer={isOfferAvailable}
          onClick={() => {
            window.open('https://www.boutique-happyneuron.com/19-formations');
            // return push('/formations', undefined, { shallow: true });
            return (!user?.profile?.advantageAmount ? setDisplayOffer(true) : push('/formations', undefined, { shallow: true }));
          }}
        />
      ) : null}

      <Main
        id="main"
        className="scrollbar-fixed"
        headbandHeight={headbandHeight}
      >
        {!mobile && hasThemes && <Thematic exploreAll={exploreAll} />}

        {home === 'mon-academy' && <Academy {...ComponentProps} data={contentList} />}
        {home === 'informations' && <Informations {...ComponentProps} data={contentList} />}
        {home === 'formations' && <Trainings {...ComponentProps} data={trainingList} />}
        {home === 'echanges' && <Exchanges />}

        {giveRating && (
        <Modal>
          <GiveRating />
        </Modal>
        )}

        {displayOffer && (
        <Modal width="66vw">
          <LaunchOffer handleClose={() => setDisplayOffer(false)} />
        </Modal>
        )}
      </Main>
    </>
  );
};

export default Home;
