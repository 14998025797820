import styled, { css } from 'styled-components';

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const close = css`
  width: ${({ theme: { mobile } }) => (mobile ? '6.4vw' : '2.4vw')};
  height: 2.4vw;
  position: fixed;
  right: 2.3vw;
  top: ${({ theme: { mobile } }) => (mobile ? '1vh' : '2.87vh')};
  cursor: pointer;
  z-index: 100;
`;

const logo = css`
  position: absolute;
  width: 10.78vw;
  height: 4.7vh;
  margin: 5vh 0 1.85vh 5vw;
`;

const overflow = css`
  overflow: auto;
  width: 100vw;
  margin: 0 auto;
  background: var(--color-white-01);
`;

export default {
  close,
  Content,
  logo,
  overflow,
};
