import { css } from 'styled-components';

const products = css`
  width: 100%;
  height: calc(100% - var(--footer-height));
  overflow-y: auto;
  overflow-x: hidden;
`;

const divLogo = css`
  display: flex;
  margin: auto auto auto var(--layout-margin);
  height: 3.0555555555555554vh;
  width: 3.125vw;
  cursor: pointer;
`;

const header = css`
  position: relative;
  width: 100vw;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: var(--color-white-01);
  /* border-bottom: 1px solid var(--color-black); */
  height: var(--header-height);
`;

const divInfos = css`
position: relative; 
  display: flex;
  height: 41.76vh;
  background: linear-gradient(to right, var(--color-greenish-cyan), rgba(2, 2, 193, 0.67) 70%, rgba(88, 51, 245, 0));
`;

const backWrap = css`
  margin-left: 1.88vw;
  margin-top: 3.056vh;
  z-index: 70;
`;

const blockInfoTxt = css`
  margin-left: 7.27vw;
  margin-top: 3.056vh;
  z-index: 100;
`;

const productTitle = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-36);
  font-weight: bold;
  line-height: 0.86;
  text-align: left;
  color: var(--color-black-02);
  margin-bottom: 3.24vh;
`;
const productDesc = css`
font-family: var(--font-family-serif);
  font-size: var(--size-20);
  line-height: 1.55;
  text-align: left;
  color: var(--color-black-02);
  margin-bottom: 3vh;
  width: 43%;
`;

const bgImage = css`
    position: absolute;
    height: 100%;
    width: 56.25vw;
    margin-left: auto;
    right: 0;
`;

const buttonDemo = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* width: 11.229vw; */
  white-space: nowrap;
  height: 5.66vh;
  background: var(--color-black) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000070;
  margin: 0 auto 2.5vh auto;
  cursor: pointer;

  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-18);
  color: white;
  border: none;
  padding: 0 2vw;


  &:hover {
    color: white;
  }
`;

const catalog = css`
  margin-top: 4.94vh;
  /* margin-left: 8vw; */
  margin-left: 1.88vw;
  /* margin-right: 1vw; */
`;

export default {
  products,
  bgImage,
  productDesc,
  productTitle,
  buttonDemo,
  backWrap,
  divLogo,
  header,
  divInfos,
  catalog,
  blockInfoTxt,
};
