import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';

const DivReactPlayer = styled(ReactPlayer)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoFixed = styled.div<any>`
  ${({ fixed }) => fixed && css`
    position: absolute;
    width: 20vw;
    bottom: 10vh;
    right: 2vw;
    z-index: 1000;
    min-width: 302px;
  `}
}
`;
const close = css`
  position: relative;
  cursor: pointer;
  height: 3vh;
  margin-left: auto;
  z-index: 100;
  display: flex;
  align-items: center;
`;

const doubleArrow = css`
  position: relative;
  cursor: pointer;
  height: 3vh;
  margin-right: auto;
  z-index: 100;
  display: flex;
  align-items: center;
  margin-left: 0.1vw;
`;

const buttonVideo = css`
  position: relative;
  display: flex;
  width: 1.8vw;
  min-width: 18px;
`;

const buttonVideoR = css`
  position: relative;
  display: flex;
  width: 1.8vw;
  min-width: 18px;
  transform: rotate(180deg);
`;

export default {
  DivReactPlayer,
  VideoFixed,
  buttonVideo,
  buttonVideoR,
  close,
  doubleArrow,
};
