import { useState, useEffect } from 'react';

const useScrollableSteps = (targetId, steps) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const elem = document.getElementById(targetId) as any;
    if (!elem) return;
    elem.scrollLeft = 0;
    const handleCurrentStep = (array) => {
      if (elem.scrollLeft + elem.clientWidth >= elem.scrollWidth) {
        setCurrentStep(array.length - 1);
        return;
      }
      for (let index = 0; index < array.length; index += 1) {
        if (elem.scrollLeft <= array[index]) {
          setCurrentStep(index);
          break;
        }
      }
    };

    elem.addEventListener('scroll', () => handleCurrentStep(steps));

    return () => elem.removeEventListener('scroll', handleCurrentStep);
  }, [steps, targetId]);

  const handleScroll = (newStep) => {
    if (newStep < 0) return;
    const elem = document.getElementById(targetId);
    if (!elem) return;
    elem.scrollLeft = steps?.[newStep];
  };

  return { currentStep, handleScroll };
};

export default useScrollableSteps;
