import styled, { css } from 'styled-components';

const TrainingDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme: { mobile } }) => (mobile ? '100%' : 'var(--content-width)')};
`;

const image = css`
  position: relative;
  width: 100%;
  height: 25.46vh;
  min-height: 200px;
`;

const presentation = css`
  margin-top: 2vh;
`;

const fiche = css`
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  height: 11.5vh;
  min-height: 90px;
  align-items: center;
  padding: 0 2.6vw;
  margin-top: 1.85vh;
`;

const ficheTxt = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-21);
  color: var(--color-black-02);
`;

const ficheBtn = css`
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-24);
  color: var(--color-black);
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin-left: 0.5vw;
`;

const program = css`
  width: 100%;
  padding-left: 1vw;
`;

const ListProgram = styled.div<any>`
  border-left: ${({ noBorder }) => (!noBorder ? 'var(--diamond-line) solid var(--color-deep-sky-blue)' : 'var(--diamond-line) solid transparent')};
  padding-left: 1.66vw;
  position: relative;
`;

const title = css`
  font-size: var(--size-28);
  font-family: var(--font-family-sans);
  font-weight: 600;
  color: var(--color-deep-sky-blue);
  line-height: 1.11;
  position: relative;
  top: -2px;
`;

const description = css`
  font-size: var(--size-20);
  font-family: var(--font-family-sans);
  color: var(--color-black-02);
  line-height: 1.55;
  padding-bottom: 1.85vh;
  /* min-height: 3vh; */
`;

const subDescription = css`
  color: var(--color-black-02);
  font-size: var(--size-16);
  line-height: var(--size-36);
  /* padding-bottom: 1.85vh; */
  min-height: 1vh;
`;

const subTitle = css`
  font-size: var(--size-18);
  @media screen and (min-width: 1224px) and (max-width: 1642px), (min-height: 768px) and (max-height: 1079px) {
    font-size: var(--size-20);
  }
  font-family: var(--font-family-sans);
  color: var(--color-deep-sky-blue);
  padding-bottom: 0.463vh;
  line-height: 1.1;
`;

const dot = css`
  list-style-position: outside;
  padding-bottom: 2vh;
  margin-left: 1vw;
  padding-left: 0.2vw;
  line-height: 2.037vh;
`;

const listDot = css`
  list-style-image: url("/dot-list-sky-blue.svg");
  ${dot};
`;

const losange = css`
  position: absolute;
  background-color: var(--color-deep-sky-blue);
  width: var(--diamond-height);
  height: var(--diamond-height);
  transform: rotate(45deg);
  left: var(--diamond-left);
  top: var(--diamond-top);
  z-index: 500;
`;
const programTitle = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-30);
  color: var(--color-black-02);
  margin-bottom: 3.6vh;
  margin-top: 5vh;
`;

const daysLeft = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5.78125vw;
  height: 5.78125vw;
  background-color: var(--color-deep-sky-blue);
  border-radius: 50%;
  font-size: var(--size-28);
  color: #FFFFFF;
  position: absolute;
  right: 1.4vw;
  bottom: -1.4vw;
  padding-top: 0.74vh;
`;
const daysLeftNbr = css`
    font-family: var(--font-family-sans);
    font-weight: 600;
  font-size: var(--size-42);
  line-height: 1;
`;
const daysLeftTxt = css`
    font-family: var(--font-family-sans);
    font-weight: 600;
  font-size: var(--size-15);
  line-height: 1.1;
  text-align: center;
`;

const daysLeftTxtEnd = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-17);
  line-height: 1.1;
  text-align: center;
  margin: auto;
  padding-bottom: 0.74vh;
`;

const sugestTxt = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-20);
  color: var(--color-black-02);
  margin-top: 4vh;
  margin-bottom: 4vh;
`;

const image2 = css`
    position: absolute;
    width: 6.615vw;
    height: 8.6vh;
    bottom: 1vh;
    left: 1vh;
`;

const bgEgnosia = css`
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.86));
    width: 100%;
    height: 100%;
    position: absolute;
`;

const egnosiaDesc = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-18);
  font-style: italic;
  line-height: 1.33;
  text-align: left;
  color: #262626;
  margin-top: 2vh;

  a {

  color: var(--color-deep-sky-blue);
  }

`;

const noAccessVideo = css`
  color: var(--color-black);
  background: #000000a6;
  width: 100%;
  height: 23vh;
  text-align: center;
  padding: 0 7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    background: #ffffffe6;
    /* border-radius: 7px; */
    font-size: var(--size-20);
    line-height: 1.4;
    padding: 1vh;
  }
`;

export default {
  TrainingDetail,
  noAccessVideo,
  image,
  egnosiaDesc,
  bgEgnosia,
  daysLeftTxtEnd,
  image2,
  sugestTxt,
  presentation,
  fiche,
  ficheTxt,
  ficheBtn,
  program,
  ListProgram,
  title,
  description,
  subDescription,
  subTitle,
  listDot,
  losange,
  programTitle,
  daysLeft,
  daysLeftNbr,
  daysLeftTxt,
};
