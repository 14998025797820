import styled, { css } from 'styled-components';

const events = css`
  display: flex;
  flex-direction: column;
  margin-top: calc(0.7407407407407407vh + 0.848vh);
  margin-left: var(--list-item-margin);
  position: relative;
  width: 100%;
`;

const flexEnd = css`
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 1.5vh;
`;

const Dot = styled.div<any>`
  width: 1.5vh;
  height: 1.5vh;
  background: ${({ selected }) => (selected ? 'black' : 'white')};
  border: 1px solid var(--color-black-02);
  border-radius: 50%;
  margin-left: 7px;
  cursor: pointer;
`;

const slideshow = css`
  display: flex;
  overflow: auto;
  max-height: 146px;
  height: 13.5185vh;
  width: 28.5vw;
  background-color: var(--color-black-02);
  scroll-behavior: smooth;
`;

const event = css`
  display: flex;
  min-width: 28.5vw;
  width: 28.5vw;
  position: relative;
`;

const DivImage = styled.div<any>`
  position: relative;
  width: var(--event-height);
  height: var(--event-height);
  max-height: 146px;
  max-width: 146px;
  margin: auto;
`;

const image = css`
  width: 40%;
  margin: auto;
`;

const marginImg = css`
  padding: 0.5vw !important;
`;

const vosEvents = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-21);
  color: var(--color-black);
  line-height: 1.29;
  margin-top: var(--height-xxs);
`;

const title = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-21);
  line-height: 1.19;
  color: var(--color-greenish-cyan);
  margin-bottom: 0.7407407407407407vh;
`;

const desc = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-14);
  line-height: 1.21;
  color: var(--color-white);
`;

const content = css`
  /* position: absolute; */
  padding-top: 1.4814814814814814vh;
  padding-left: 0.8333333333333334vw;
  width: 60%;
`;

const invisible = css`
  visibility: hidden;
`;

export default {
  slideshow,
  event,
  DivImage,
  image,
  marginImg,
  invisible,
  vosEvents,
  title,
  desc,
  content,
  events,
  Dot,
  flexEnd,
};
