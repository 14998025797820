import { css } from 'styled-components';

const premium = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #FFFFFF;
  margin-top: 2vh;
`;

const fading = css`
  position: absolute;
  top: -21.944444444444443vh;
  height: 21.944444444444443vh;
  width: 100%;
  z-index: 100;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
`;

const reserved = css`
  font-size: var(--size-14);
  font-family: var(--font-family-sans);
  color: white;
  line-height: 1em;
  text-align: center;
`;

const readNext = css`
  font-size: var(--size-30);
  font-family: var(--font-family-sans);
  color: #707070;
  line-height: 1.2;
  text-align: center;
  b {
    color: var(--color-gold);
    font-family: var(--font-family-sans);
    font-weight: bold;
  }
`;

const advantages = css`
  margin-top: 4vh;
  width: 100%;
  display: flex;
  justify-content: space-between; 
`;

const premiumBlockFree = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23vw;
  height: 59vh;
  background: #FFFFFF;
  box-shadow: 0px 3px 73px #00000029;
  border-radius: 45px;
  padding-top: 1.7vh;
`;

const premiumBlock = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23vw;
  height: 59vh;
  background: var(--color-gold);
  box-shadow: 0px 3px 73px #00000029;
  border-radius: 45px;
  padding-top: 1.7vh;
`;

const premiumItem = css`
  display: flex;
  /* height: 5.3vh; */
  align-items: center;
  padding: 0.7vh 0 0.7vh 4.47vw;
  background-color: rgb(255 255 255 / 14%);
  margin-bottom: 0.2vh;
`;

const premiumItemFree = css`
  display: flex;
  /* height: 5.3vh; */
  align-items: center;
  padding: 0.7vh 0 0.7vh 4.47vw;
  background-color: rgb(38 38 38 / 5%);
  margin-bottom: 0.2vh;
`;

const premiumUl = css`
  width: 100%;
  margin-top: var(--height-xxs);
`;

const itemText = css`
  width: 12.5vw;
  margin-left: 1.6vw;
  font-family: var(--font-family-sans);
  font-weight: 600;
  color: white;
  line-height: 1.2;
  font-size: var(--size-14);
`;

const itemTextFree = css`
  width: 12.5vw;
  margin-left: 1.6vw;
  font-family: var(--font-family-sans);
  font-weight: 600;
  color: var(--color-light-royal-blue);
  line-height: 1.2;
  font-size: var(--size-14);
`;

const itemTextFreeDisab = css`
  width: 12.5vw;
  margin-left: 1.6vw;
  font-family: var(--font-family-sans);
  font-weight: 600;
  color: #A7A7A7;
  line-height: 1.2;
  font-size: var(--size-14);
`;

const cadenas = css`
  width: 5.9vw;
  height: 5.9vw;
  background-color: var(--color-gold);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 4.16vh;
`;

const textAdvantage = css`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  background-color: #d9bb53;
  height: 4vh;
  align-items: center;
  border-radius: 10px 10px 0 0;
  text-transform: uppercase;
  color: white;
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-21);
`;

const freeAccessTitle = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-26);
  color: var(--color-black-02);
  text-align: center;
  height: 6vh;
`;

const premiumAccessTitle = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-26);
  color: white;
  width: 20vw;
  text-align: center;
  line-height: 1;
  height: 6vh;
  text-transform: uppercase;
`;

const buttonSubscrib = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12vw;
  height: 5.4vh;
  background: var(--color-black);
  margin:  var(--height-s) auto 0.7vh auto;

  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-18);
  color: white;
  box-shadow: 0px 3px 13px #000000BA;

  &:hover {
    /* transform: scale(1.02); */
    color: white;
  }
`;

const buttonSignup = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12vw;
  height: 5.4vh;
  background: var(--color-light-royal-blue);
  margin:  var(--height-s) auto var(--height-xxs) auto;

  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-18);
  color: white;
  box-shadow: 0px 3px 13px #000000BA;
  text-align: center;
  line-height: 1;

  &:hover {
    /* transform: scale(1.02); */
    color: white;
  }
`;

const checkIcon = css`
  position: relative;
  width: 1.56vw;
  height: 1.56vw;
`;

const cadenasIcon = css`
  position: relative;
  width: 2.76vw;
  height: 6.5vh;
`;

const savoirPlus = css`
  cursor: pointer;

  a {
    color: initial;
  }
`;

const image = css`
  position: relative;
  height: 3.7vh;
  width: 3.8vw;
`;

export default {
  image,
  buttonSubscrib,
  buttonSignup,
  cadenasIcon,
  premium,
  fading,
  reserved,
  savoirPlus,
  readNext,
  advantages,
  premiumBlockFree,
  premiumBlock,
  premiumItem,
  premiumItemFree,
  premiumUl,
  itemText,
  itemTextFree,
  itemTextFreeDisab,
  cadenas,
  textAdvantage,
  freeAccessTitle,
  premiumAccessTitle,
  checkIcon,
};
