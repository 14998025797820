/* eslint-disable max-len */
import { Fragment } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { DivFlex } from 'styles/common';
import SC from './style';
import SvgIdea from '../../../public/idea.svg';
import { SvgTrash } from 'components/Svgs';
import getGoodFormattedPrice from 'utils/getGoodFormattedPrice';
import useLaunchOffer from 'hooks/useLaunchOffer';
import getGoodPaymentPrice from 'utils/getGoodPaymentPrice';
import useDatas from 'hooks/useDatas';

interface CartProps {
  items: any
  totals: any
  removeItem: any
  handlePaid: any
  user: any
}

const Cart: React.FC<CartProps> = ({
  items, removeItem, handlePaid, user,
}) => {
  const { isOfferAvailable } = useLaunchOffer();
  const { trainingList } = useDatas();
  const newItems = items?.map((item) => ({
    ...item,
    ...trainingList.find((el) => el.id === item.id),
  })) || items;
  return (
    <div css={SC.tabsCart}>
      <div css={SC.wrapCart}>
        <DivFlex column height="100%">
          {newItems?.length ? (
            <div css={SC.itemList}>
              <div css={SC.crowlableList}>
                {newItems.map((cart) => (
                  <Fragment key={cart.id}>
                    <div css={SC.item}>
                      <div className="delete-item-card" role="presentation" onClick={(e) => removeItem(e, cart.id)} css={SC.logoTrash}>
                        <SvgTrash />
                      </div>
                      <p css={SC.itemTitle}>{cart.displayType}</p>
                      <div css={SC.itemContent}>
                        <Link
                          href={`${window.location.origin}/formations/${cart.id}`}
                          shallow
                          css={SC.itemDesc}
                        >
                          {cart.title}
                        </Link>
                        <p css={SC.itemTotal}>
                          {getGoodFormattedPrice({ ...cart, user, isOfferAvailable })}
                        </p>
                      </div>
                    </div>
                    <div css={SC.separator} />
                  </Fragment>
                ))}
              </div>
              <div css={SC.total}>
                <p css={SC.totalLabel}>Total :</p>
                <p css={SC.totalPrice}>
                  {newItems.reduce((acc, item) => acc + getGoodPaymentPrice({ price: item.price, user, isOfferAvailable }), 0)}
                  {' '}
                  €
                </p>
              </div>
              <div tabIndex={0} onKeyPress={handlePaid} role="button" onClick={handlePaid} css={SC.paid}>
                <p css={SC.paidTxt}>
                  Passer la commande (
                  {newItems?.length === 1 ? '1\xa0article)' : `${newItems?.length}\xa0articles)`}
                </p>
              </div>
            </div>
          ) : (
            <p css={SC.emptyCart}>
              Votre panier est vide.
              <br />
              <br />
              Parcourez votre espace &quot;Se former&quot; et trouvez votre prochaine formation !
              Une fois votre choix fait, vous la retrouverez ici et pourrez procéder au paiement.
            </p>
          )}

        </DivFlex>
      </div>
      <div />
    </div>
  );
};

export default Cart;
