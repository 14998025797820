import Image from 'next/image';
import Link from 'next/link';
import SC from './style';

interface LogoProps {
  margin?: any
}

const Logo: React.FC<LogoProps> = ({ margin }) => (
  <Link href="/mon-academy" shallow passHref legacyBehavior>
    <SC.AnchorLogo margin={margin}>
      <Image src="/LogoHNA-noir.svg" alt="happyneuron academy logo" fill sizes="100vw" />
    </SC.AnchorLogo>
  </Link>
);

export default Logo;
