// context part
import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import useDatas from 'hooks/useDatas';

interface GiveRatingProps {
  children: ReactNode
}

export type GiveRatingContextType = {
  nextGiveRating?: any
  giveRating?: any
}

const initialContext = {
  giveRating: null,
  nextGiveRating: () => {},
};

export const GiveRatingContext = createContext<GiveRatingContextType>(initialContext);

const GiveRatingProvider = ({ children }: GiveRatingProps) => {
  const [giveRating, setGiveRating] = useState(null);
  const { baughtTraining, fetchTrainings } = useDatas();

  useEffect(() => {
    if (!baughtTraining || baughtTraining?.error) return undefined;

    const rating = baughtTraining?.find((el) => el.programVendor?.hasFinished && !el.programFeedback?.rating);
    if (rating) {
      setGiveRating(rating);
    }
  }, [baughtTraining]);

  const nextGiveRating = async () => {
    setGiveRating(null);
    fetchTrainings();
  };

  return (
    <GiveRatingContext.Provider value={{ giveRating, nextGiveRating }}>
      {children}
    </GiveRatingContext.Provider>
  );
};

export default GiveRatingProvider;
