import Link from 'next/link';
import SC from './style';
import Discover from 'components/Discover';
import { getPermanentUrl } from 'utils/url';

const Memoir = ({ content, user, handleTracked }) => (
  <>
    <>
      <div css={SC.underMemoir} />
      <p css={SC.description}>{content.description}</p>
      <div css={SC.memoirInfos}>
        <p>
          <span>AUTEUR.E.S : </span>
          <span>{content.author}</span>
        </p>
        <p>
          <span>DIRECTRICE(S)/DIRECTEUR(S) : </span>
          <span>{content.director}</span>
        </p>
        <p>
          <span>ANNÉE : </span>
          <span>{content.publicationYear}</span>
        </p>
        <p>
          <span>UNIVERSITÉ : </span>
          <span>{content.university}</span>
        </p>
        <p>
          <span>NB DE PAGES (ANNEXES INCLUSES) : </span>
          <span>{content.totalPages}</span>
        </p>
      </div>
    </>

    {/* {!user && <WhereComeFrom />} */}

    <p css={SC.resumeTitle}>Résumé</p>

    <div className="froala-editor-custom fr-view" css={SC.innerHtml} dangerouslySetInnerHTML={{ __html: content.html }} />

    <div role="presentation" onClick={() => handleTracked('memoirDownloaded', true)} css={SC.premium}>
      {user
        ? <a href={getPermanentUrl(content.pdf)} target="_blank" css={SC.buttonRedirect} rel="noreferrer">Lire le mémoire</a>
        : <Link href="/connect/login" css={SC.buttonRedirect} rel="noreferrer">Lire le mémoire</Link>}
    </div>

    {!user && !content.premium && <Discover />}
  </>
);

export default Memoir;
