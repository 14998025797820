import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import ButtonBack from 'components/ButtonBack';
import Catalogue from 'components/Catalogue';
import Events from 'components/Events';
import {
  CenterMain, ContentHome, ButtonBackPosition, loader, Background,
} from './style';
import useThemesFilters from 'hooks/useThemesFilters';
import { ThemesFiltersContextType } from 'providers/ThemesFilters';
import Error from 'pages/_error';
import useAuth from 'hooks/useAuth';
import { SvgLogoSmallLoader } from 'components/Svgs';
import { formatListByContentType } from 'utils/formatListByContentType';
import { DivFlex } from 'styles/common';
import useLaunchOffer from 'hooks/useLaunchOffer';
import getIdsFromUrl from 'utils/getIdsFromUrl';

const Content = dynamic(() => import('containers/Content'), {
  ssr: false,
});

export interface InformationsProps {
  exploreAll?: any
  events?: any
  data?: any
  handleExploreAll?: any
  contentData?: any
  selectedForYou: any
  showcaseList: any
  headbandHeight: any
  handleCloseModal: any
}

const Informations: React.FC<InformationsProps> = ({
  exploreAll, data,
  handleExploreAll, selectedForYou, events, showcaseList,
  contentData, headbandHeight, handleCloseModal,
}) => {
  const router = useRouter();
  const { query } = router;
  const { filters }: ThemesFiltersContextType = useThemesFilters();
  const { mobile } = useContext(ThemeContext);
  const { user } = useAuth();
  const { displayHeadband } = useLaunchOffer();
  const today = Date.now();
  const weekinMs = 604800000;

  const sliceSelection = 3;
  const id = getIdsFromUrl(query?.home?.[1]);
  const showcaseListFiltered = showcaseList && !showcaseList?.error && showcaseList.filter((el) => el.contentType);

  const orderList = ['ARTICLE', 'DEBOECK', 'POST', 'INTERVIEW', 'LITERATURE', 'MEMOIR'];
  const isDisplayed = (name) => !exploreAll || exploreAll === name;

  const filterList = !filters || (filters?.length === 1 && filters[0].id === 0)
    ? data
    : data
      ?.filter((el) => el.themes.filter((t) => filters?.find((e) => e.id === t.id)).length);

  const listActualite = filterList?.filter((el) => el.contentType !== 'MEMOIR'
   && (new Date(el.publicationDate).getTime() > today - weekinMs));

  // const listProgrammeMonth = filterList?.filter((el) => (new Date(el.publicationDate).getMonth() === new Date(today).getMonth()));

  const formattedList = formatListByContentType(filterList);

  if (data?.error) return <Error statusCode={data.error} />;

  const hasHeadband = !user || displayHeadband;

  const sortedFormatList = formattedList && Object.entries(formattedList);
  sortedFormatList?.sort(([a], [c]) => a.localeCompare(c));

  return (
    <ContentHome exploreAll={exploreAll} hasHeadband={hasHeadband}>

      {id ? (
        <Content
          data={contentData}
          loading={contentData?.id !== id}
          handleClose={handleCloseModal}
        />
      ) : null}

      {exploreAll && (
        <ButtonBackPosition hasHeadband={hasHeadband}>
          <ButtonBack
            category="content"
            onClick={() => {
              router.query.all = [];
              router.push(router, undefined, { shallow: true });
              handleExploreAll(null);
            }}
          />
        </ButtonBackPosition>
      )}

      <CenterMain
        headbandHeight={headbandHeight}
        isExtended={exploreAll}
        hasThematic={!mobile}
        hasHeadband={hasHeadband}
      >
        {!exploreAll && <Background bgHome="informations" />}
        <DivFlex>
          {selectedForYou && isDisplayed('selection')
          && (
            <Catalogue
              user={user}
              name="selection"
              unClickable
              title="Sélectionnés pour vous"
              list={selectedForYou?.filter((el) => el.contentType).slice(0, sliceSelection)}
              category="content"
            />
          )}
          {!exploreAll && events && !mobile && <Events events={events} />}
        </DivFlex>

        {listActualite?.length && isDisplayed('actualite') ? (
          <Catalogue
            user={user}
            name="actualite"
            onClick={() => handleExploreAll('actualite')}
            title="Quoi de neuf ?"
            list={listActualite}
            isExtended={exploreAll === 'actualite'}
            category="content"
          />
        ) : null}

        {/* {listProgrammeMonth?.length && isDisplayed('listProgrammeMonth') ? (
          <Catalogue
            user={user}
            name="listProgrammeMonth"
            onClick={() => handleExploreAll('listProgrammeMonth')}
            title="À la une ce mois-ci"
            list={listProgrammeMonth}
            isExtended={exploreAll === 'listProgrammeMonth'}
            category="content"
          />
        ) : null} */}

        {showcaseListFiltered?.length && isDisplayed('showcaseList') ? (
          <Catalogue
            user={user}
            name="showcaseList"
            onClick={() => handleExploreAll('showcaseList')}
            title="Zoom sur..."
            list={showcaseListFiltered}
            isExtended={exploreAll === 'showcaseList'}
            category="content"
          />
        ) : null}

        {formattedList?.ARTICLE && isDisplayed('ARTICLE')
                && (
                  <Catalogue
                    key="ARTICLE"
                    user={user}
                    name="ARTICLE"
                    onClick={() => handleExploreAll('ARTICLE')}
                    title="ARTICLE"
                    list={formattedList.ARTICLE}
                    isExtended={exploreAll === 'ARTICLE'}
                    category="content"
                  />
                )}

        {sortedFormatList?.map(([key]) => !orderList.includes(key) && isDisplayed(key) && (
          <Catalogue
            key={key}
            user={user}
            name={key}
            onClick={() => handleExploreAll(key)}
            title={key}
            list={formattedList[key]}
            isExtended={exploreAll === key}
            category="content"
          />
        ))}

        {orderList.slice(1, orderList.length).map(
          (value) => formattedList?.[value] && isDisplayed(value)
                && (
                  <Catalogue
                    key={value}
                    user={user}
                    name={value}
                    onClick={() => handleExploreAll(value)}
                    title={value}
                    list={formattedList[value]}
                    isExtended={exploreAll === value}
                    category="content"
                  />
                ),
        )}

        {!data?.length && (
          <div css={loader}>
            <SvgLogoSmallLoader />
          </div>
        )}
      </CenterMain>
      {/* {mobile && <Footer />} */}
    </ContentHome>
  );
};

export default Informations;
