/* eslint-disable import/prefer-default-export */
export const postLabels = {
  ARTICLE: 'Article',
  LITERATURE: 'Littérature scientifique',
  MEMOIR: 'Mémoire primé',
  POST: 'Billet',
  INTERVIEW: 'Interview',
  PODCAST: 'Podcast',
  VIDEO: 'Émission vidéo',
  DEBOECK: 'DeBoeck',
};
