import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CardCoach from 'components/CardCoach';
import Discover from 'components/Discover';
import PremiumAccess from 'components/PremiumAccess';
import WhereComeFrom from 'components/WhereComeFrom';
import { DivFlex, relative } from 'styles/common';
import { isUserSubscribed } from 'utils/auth';
import formattingDate from 'utils/formattingDate';
import { getPermanentUrl } from 'utils/url';
import SC from './style';
import Advantages from 'components/Advantages';
import SC1 from 'components/PremiumAccess/style';
import Video from 'components/Video';
import Catalogue from 'components/Catalogue';
import { SvgReduceVideo } from 'components/Svgs';
import useVideo from 'hooks/useVideo';
import AuthorsWithCardHover from 'utils/AuthorsWithCardHover';
import { openSubscribtion } from '../../utils/link';

const Article = ({
  content, user, redirectAbonnement, setCardCoach, cardCoach, itemCoach, formattedContentsList,
}) => {
  const router = useRouter();
  const { setFixedVideo, fixedVideo } = useVideo();
  const visibleVideo = (isUserSubscribed(user)
  || (isUserSubscribed(user) && content.premium)
  || (user && !isUserSubscribed(user) && !content.premium));

  const visibleCalameo = isUserSubscribed(user)
  || (user && !isUserSubscribed(user) && !content.premium);

  const handlePodcastRedirect = () => {
    if (user) openSubscribtion();
    else router.push('/connect/login', undefined, { shallow: true });
  };

  const handleFixedVideo = () => {
    setFixedVideo({
      url: content?.videoLink,
      id: content?.id,
      show: content?.tags[0].name,
      asPath: router.asPath,
      currentTime: 0,
    });
  };

  const podcastsSorted = formattedContentsList?.[content.tags[0]?.name];
  return (
    <>
      <div onMouseLeave={() => setCardCoach(null)} role="textbox" tabIndex={0} css={SC.infos}>
        {['PODCAST', 'VIDEO'].includes(content.contentType) && <span css={SC.animateur}>Animé par&#160;</span>}
        <span itemProp="author" css={SC.author}>

          <AuthorsWithCardHover content={content} setCardCoach={setCardCoach} />
        </span>
        {(content.authors[0]?.fullName || content.author) && cardCoach && (
        <div css={SC.hoverCoach}>
          <CardCoach item={itemCoach} />
        </div>
        )}
        {(content.authors[0]?.fullName || content.author) && ' - '}
        <time css={SC.upperTime} dateTime={content.publicationDate} itemProp="datePublished">{formattingDate(content.publicationDate)}</time>
      </div>

      {content?.guests && (
      <div>
        <span css={SC.animateur}>Invités : </span>
        <span css={[SC.author]}>{content.guests}</span>
      </div>
      )}

      <p css={SC.description}>{content.description}</p>

      {content.contentType === 'LITERATURE' && (
      <p css={SC.sources}>
        Publication d'origine :
        {' '}
        {content.publicationSource}
      </p>
      )}

      {!user && <WhereComeFrom />}

      <>

        {['PODCAST', 'VIDEO'].includes(content.contentType) && content.videoLink && visibleVideo && (
        <div css={relative}>
          <Video videoLink={content.videoLink} />
          <div
            onClick={handleFixedVideo}
            role="link"
            onKeyPress={handleFixedVideo}
            tabIndex={0}
            title="Lecteur réduit"
            css={SC.reduceVideo}
          >
            <SvgReduceVideo />
          </div>
        </div>
        )}

        {(!['PODCAST', 'VIDEO', 'DEBOECK'].includes(content.contentType) || (['PODCAST', 'VIDEO'].includes(content.contentType) && !visibleVideo)) && content?.image && (
        <figure css={SC.image}>
          {(['PODCAST', 'VIDEO'].includes(content.contentType) && !visibleVideo) && (
          <div
            css={SC.podcastImage}
            role="link"
            tabIndex={0}
            onClick={handlePodcastRedirect}
            onKeyPress={handlePodcastRedirect}
          >
            {!content.premium && (!user || !isUserSubscribed(user))
              ? (
                <p>
                  {content.contentType === 'PODCAST' ? 'Ce podcast' : 'Cette émission vidéo'}
                  {' '}
                  est
                  {' '}
                  {content.contentType === 'PODCAST' ? 'réservé' : 'réservée'}
                  {' '}
                  aux orthophonistes. Pour
                  {' '}
                  {content.contentType === 'PODCAST' ? 'écouter' : 'visionner'}
                  {' '}
                  {content.contentType === 'PODCAST' ? 'ce podcast' : 'cette émission vidéo'}
                  , connectez-vous à l'Happyneuron Academy
                </p>
              )
              : (
                <p>
                  {content.contentType === 'PODCAST' ? 'Ce podcast' : 'Cette émission vidéo'}
                  {' '}
                  est
                  {' '}
                  {content.contentType === 'PODCAST' ? 'réservé' : 'réservée'}
                  {' '}
                  aux abonnés Happyneuron, pour en profiter, abonnez-vous !
                </p>
              )}

          </div>
          )}
          <Image
            itemProp="image"
            src={getPermanentUrl(content?.image, '-972x279')}
            alt="thumbnail"
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
          />
        </figure>
        )}

        {['DEBOECK'].includes(content.contentType) && visibleCalameo && <iframe css={SC.calameoLink} title="deboeck" src={content.calameoLink} allowFullScreen />}

        {['DEBOECK'].includes(content.contentType) && !visibleCalameo && (
        <div css={SC.deboeckHolder} role="presentation" onClick={handlePodcastRedirect}>
          <Image src="/deboeck-holder.png" alt="deboeck" fill sizes="100vw" />
          {!user ? (
            <p>
              Ce contenu est réservé aux orthophonistes, pour y accéder, connectez-vous à l'Happyneuron Academy !
            </p>
          ) : (
            <p>
              Ce contenu est réservé aux abonnés Happyneuron, pour y accéder, abonnez-vous !
            </p>
          )}
        </div>
        )}

        <div css={SC.underImage} />

        {['PODCAST', 'VIDEO'].includes(content.contentType) && content.videoLink && podcastsSorted?.length
          ? (
            <>
              <br />
              <Catalogue
                key={content.tags[0]?.name}
                user={user}
                name="Liste des épisodes"
                title="Liste des épisodes"
                list={podcastsSorted}
                category="content"
                unClickable
                currentPodcast={fixedVideo.id}
              />
            </>
          ) : null}
      </>

      <div className="froala-editor-custom fr-view" css={SC.innerHtml} dangerouslySetInnerHTML={{ __html: content.html }} />

      {!user && !content.premium && (
      <div css={SC.premium}>
        <div css={SC.fading} />
        <Link href="/connect/login" css={SC.buttonRedirect} rel="noreferrer">
          Accéder à la suite de l&apos;article
        </Link>
        <p css={SC.connectedTxt}>Une connexion à l&apos;Happyneuron Academy est nécessaire</p>
      </div>
      )}
      {!user && !content.premium && <Discover />}

      {content.premium && !user && (
      <PremiumAccess user={user} />
      )}

      {content.premium && user && !isUserSubscribed(user) && (
      <DivFlex column align="center" position="relative">

        {['PODCAST', 'VIDEO'].includes(content.contentType) && content.premium && !isUserSubscribed(user) ? null
          : (
            <>
              <div css={SC1.fading} />
              <div css={SC1.cadenas}>
                <div css={SC1.cadenasIcon}>
                  <Image src="/cadenas-close.svg" alt="cadenas" fill sizes="100vw" />
                </div>
              </div>
              <p css={SC1.readNext}>Cet article est réservé aux abonnés Happyneuron </p>
              <p css={SC1.readNext}>
                Pour lire les 80% restants,
                {' '}
                <b>abonnez vous !</b>
              </p>
            </>
          )}

        <br />
        <p css={SC.advTxt}>En vous abonnant Happyneuron, vous bénéficiez immédiatement des avantages suivants :</p>
        <Advantages isHNPROMember={user?.profile?.isHNPROMember} />
        <button
          css={SC.accessButton}
          type="button"
          onClick={redirectAbonnement}
        >
          <span>Je m'abonne à Happyneuron</span>
        </button>

        <a
          href="https://www.happyneuronpro.com/orthophonie/essai-hnpro-orthophonie/"
          target="_blank"
          rel="noreferrer"
        >
          <p css={SC.needHelp}>
            Vous avez besoin d'aide ? Contactez-nous
          </p>
        </a>
      </DivFlex>
      )}

    </>
  );
};

export default Article;
