const getHumanSeconds = (d) => {
  if (!d) return '';
  const date = new Date(d);
  const seconds = date?.getSeconds();

  if (seconds === 0) return '00';
  if (seconds < 10) return `0${seconds}`;
  return seconds;
};

export default getHumanSeconds;
