import styled from 'styled-components';

const AnchorLogo = styled.a<any>`
  display: flex;
  margin: ${({ margin }) => (margin || 'auto auto auto var(--layout-margin)')};
  height: 5vh;
  width: 8vw;
  cursor: pointer;
  position: relative;
`;

export default {
  AnchorLogo,
};
