import { PopupButton } from '@typeform/embed-react';
import { useRouter } from 'next/router';
import useAuth from 'hooks/useAuth';
import { DivFlex } from 'styles/common';
import hnaRequest from 'utils/hnaRequest';
import SC from './style';

interface ProfileProps {
  handleSubscription: any
  setOpenPreferences: any
  setOpenModal: any
  setOpenDropdown: any
  user: any
  subscribed: any
  logout: any
}

const Profile: React.FC<ProfileProps> = ({
  user, subscribed, handleSubscription, setOpenPreferences, setOpenDropdown, logout, setOpenModal,
}) => {
  const { setUser } = useAuth();
  const router = useRouter();
  const handleClickProfile = async () => {
    router.push('/profil', undefined, { shallow: true });
    const refreshingProfile = await hnaRequest('get', 'user/refresh-profile', undefined);
    setUser(refreshingProfile.user);
  };

  const handleClickInvoices = async () => {
    const refreshingProfile = await hnaRequest('get', 'user/refresh-profile', undefined);
    setUser(refreshingProfile.user);
    router.push('/commandes', undefined, { shallow: true });
  };
  return (
    <div css={SC.tabs}>
      <div css={SC.paperProfile}>
        <DivFlex column justify="space-around" height="100%">
          <span css={SC.name}>{user?.firstName}</span>
          <span css={SC.name}>{user?.lastName}</span>
          {!subscribed ? (
            <button
              type="button"
              css={SC.buttonSus}
              onClick={handleSubscription}
            >
              <p css={SC.textSus}>Je m&apos;abonne</p>
            </button>
          ) : (
            <div css={SC.unSuscribedDiv}>
              <p css={SC.unSuscribedTxt}>Abonnement</p>
              <p css={SC.unSuscribedPrmm}>PREMIUM</p>
            </div>
          )}

          <SC.Text onClick={handleClickProfile} key="1">Mon compte</SC.Text>
          <SC.Text
            key="3"
            onClick={() => {
              setOpenDropdown(null);
              setOpenPreferences(true);
            }}
          >
            Mes préférences
          </SC.Text>
          {/* TODO: refresh-profile when click on command */}
          <SC.Text onClick={handleClickInvoices} key="4">Mes commandes</SC.Text>
          <PopupButton
            key="5"
            id="IX7XRRII"
            css={SC.buttonForm}
            tracking={{ email: user?.email, firstname: user?.firstName, name: user?.lastName }}
          >
            Aide et commentaires
          </PopupButton>
          <div css={SC.profileBottom}>
            <p css={SC.bottomTxt} />
            <button
              type="button"
              onClick={() => router.push('/cgu', undefined, { shallow: true })}
              css={SC.bottomTxt}
            >
              Conditions d&apos;utilisation
            </button>
            <button
              type="button"
              onClick={() => setOpenModal('confidentialite')}
              css={SC.bottomTxt}
            >
              Confidentialité
            </button>
          </div>
        </DivFlex>
      </div>
      <div role="link" tabIndex={0} onClick={logout} onKeyPress={logout} css={SC.bottomLogout}>Déconnexion</div>
      <div />
    </div>
  );
};

export default Profile;
