import Link from 'next/link';
import Image from 'next/image';
import { DivFlex } from 'styles/common';
import SC from './style';
import Advantages from 'components/Advantages';
import SC1 from 'components/PremiumAccess/style';
import CardCoach from 'components/CardCoach';
import formattingDate from 'utils/formattingDate';
import { getPermanentUrl } from 'utils/url';

import InterviewImage from '../../../public/illustration-interview.svg';
import { isUserSubscribed } from 'utils/auth';
import Discover from 'components/Discover';
import PremiumAccess from 'components/PremiumAccess';
import AuthorsWithCardHover from 'utils/AuthorsWithCardHover';

const Interview = ({
  content, user, redirectAbonnement, setCardCoach, cardCoach, itemCoach,
}) => (
  <>
    <div css={SC.interviewWrap}>
      <div
        role="textbox"
        tabIndex={0}
        css={SC.infos}
        onMouseLeave={() => setCardCoach(null)}

      >
        <span itemProp="author" css={SC.author}>
          <AuthorsWithCardHover content={content} setCardCoach={setCardCoach} />
        </span>
        {cardCoach && (
        <div css={SC.hoverCoach}>
          <CardCoach item={itemCoach} />
        </div>
        )}
        {(content.authors[0]?.fullName || content.author) && ' - '}
        <time dateTime={content.publicationDate} itemProp="datePublished">{formattingDate(content.publicationDate)}</time>
      </div>

      <figure css={content?.image ? SC.imageInterview : SC.imageInterviewDefault}>
        <Image
          itemProp="image"
          placeholder="blur"
          blurDataURL="LQC+PGuyl}P_T^TT:Pnk$]gGNMmv"
          src={content?.image ? getPermanentUrl(content?.image) : InterviewImage}
          alt="thumbnail"
          fill
          sizes="100vw"
          style={{
            objectFit: 'cover',
          }}
        />
      </figure>
    </div>

    <p css={SC.description}>{content.description}</p>
    <div className="froala-editor-custom fr-view" css={SC.innerHtml} dangerouslySetInnerHTML={{ __html: content.html }} />

    {!user && !content.premium && (
    <div css={SC.premium}>
      <div css={SC.fading} />
      <Link href="/connect/login" css={SC.buttonRedirect} rel="noreferrer">
        Accéder à la suite de l&apos;article
      </Link>
      <p css={SC.connectedTxt}>Une connexion à l&apos;Happyneuron Academy est nécessaire</p>
    </div>
    )}
    {!user && !content.premium && <Discover />}

    {content.premium && !user && (
      <PremiumAccess user={user} />
    )}

    {content.premium && user && !isUserSubscribed(user) && (
    <DivFlex column align="center" position="relative">
      <div css={SC1.fading} />
      <div css={SC1.cadenas}>
        <div css={SC1.cadenasIcon}>
          <Image src="/cadenas-close.svg" alt="cadenas" fill sizes="100vw" />
        </div>
      </div>
      <p css={SC1.readNext}>Cet article est réservé aux abonnés Happyneuron </p>
      <p css={SC1.readNext}>
        Pour lire les 80% restants,
        {' '}
        <b>abonnez vous !</b>
      </p>
      <br />
      <p css={SC.advTxt}>En vous abonnant à Happyneuron, vous bénéficiez immédiatement des avantages suivants :</p>
      <Advantages isHNPROMember={user?.profile?.isHNPROMember} />
      <button
        css={SC.accessButton}
        type="button"
        onClick={redirectAbonnement}
      >
        <span>Je m'abonne à Happyneuron</span>
      </button>

      <a href="https://www.happyneuronpro.com/orthophonie/essai-hnpro-orthophonie/" target="_blank" rel="noreferrer">
        <p css={SC.needHelp}>
          Vous avez besoin d'aide ? Contactez-nous
        </p>
      </a>
    </DivFlex>
    )}

  </>
);
export default Interview;
