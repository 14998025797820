import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import {
  DivFlex,
} from 'styles/common';
import SC from './style';
import { postLabels } from 'utils/categories';
import Catalogue from 'components/Catalogue';
import useAuth from 'hooks/useAuth';
import Memoir from './Memoir';
import Article from './Article';
import Interview from './Interview';
import useDatas from 'hooks/useDatas';
import SvgProducstTool from '../../../public/products-tool.svg';
import formatTitleAsUrl from 'utils/formatTitleAsUrl';
import { openSubscribtion } from '../../utils/link';

export interface ContentDetailProps {
  content: any
  user: any
  suggestions: any
  handleTracked: (name: string, value: any) => void
}

const ListThemes = ({ themes }) => themes?.map(({ name }, index) => (
  <Fragment key={name}>
    <li css={SC.theme}>
      {name}
    </li>
    {index !== themes.length - 1 && '-'}
  </Fragment>
));

const ContentDetail: React.FC<ContentDetailProps> = ({
  content, user, suggestions, handleTracked,
}) => {
  const router = useRouter();
  const [cardCoach, setCardCoach] = useState(null);

  const { coaches } = useAuth();

  const { formattedContentsList } = useDatas();

  const itemCoach = coaches?.find((el) => el.id === cardCoach);
  const suggestionsTitle = () => {
    if (content.contentType === 'MEMOIR') return 'Ce mémoire ';
    if (content.contentType === 'INTERVIEW') return 'Cet interview ';
    return 'Cet article ';
  };

  const redirectAbonnement = (e) => {
    openSubscribtion();
  };

  return (
    <SC.ContentDetail itemScope itemType="https://schema.org/Article" center={user}>
      <div css={SC.fadingTop} />
      <div css={SC.fadingBottom} />
      <DivFlex align="baseline" margin="0 0 var(--height-s) 0">
        <div css={SC.contentType}>
          <span>{postLabels[content.contentType]}</span>
        </div>
        <ul css={SC.listThemes}>
          {ListThemes(content)}
        </ul>
      </DivFlex>

      {['PODCAST', 'VIDEO'].includes(content.contentType) && (
        <p css={SC.podcastTitle}>{content?.tags?.[0]?.name}</p>
      )}

      <h1 itemProp="headline" css={SC.title}>{content?.contentType === 'MEMOIR' ? content.memoirFullTitle : content.title}</h1>

      {['MEMOIR'].includes(content.contentType) && <Memoir content={content} user={user} handleTracked={handleTracked} />}

      {['INTERVIEW'].includes(content.contentType) && (
        <Interview
          cardCoach={cardCoach}
          itemCoach={itemCoach}
          setCardCoach={setCardCoach}
          redirectAbonnement={redirectAbonnement}
          content={content}
          user={user}
        />
      )}

      {['POST', 'ARTICLE', 'LITERATURE', 'PODCAST', 'VIDEO', 'DEBOECK'].includes(content.contentType) && (
        <Article
          cardCoach={cardCoach}
          setCardCoach={setCardCoach}
          redirectAbonnement={redirectAbonnement}
          content={content}
          user={user}
          itemCoach={itemCoach}
          formattedContentsList={formattedContentsList}
        />
      )}

      {content?.products?.length ? (
        <div css={SC.products}>
          <div css={SC.svgProduct}>
            <Image src={SvgProducstTool} alt="product" fill sizes="100vw" />
          </div>
          <p css={SC.outils}>Outils associés :</p>
          <p css={SC.product}>
            {content?.products?.map((c, i) => (
              <Link shallow key={c.id} href={`products/${formatTitleAsUrl(c)}`}>
                {i === content.products.length - 1 ? c.title : `${c.title}, `}
              </Link>
            ))}
          </p>
        </div>
      ) : null}

      <p css={SC.sugestTxt}>
        {suggestionsTitle()}
        {' '}
        vous intéresse ?
        <br />
        Allez plus loin et découvrez nos suggestions sur ce thème.
      </p>

      {suggestions?.length ? (
        <Catalogue
          user={user}
          name="suggestions"
          unClickable
          title="Suggestions"
          list={suggestions.slice(0, 3)}
        />
      ) : null}
    </SC.ContentDetail>
  );
};

export default ContentDetail;
