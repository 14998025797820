import styled, { css } from 'styled-components';

const Training = styled.article`
  display: flex;
  flex-wrap: ${({ theme: { mobile } }) => (mobile ? 'wrap' : 'unset')};
  overflow: auto;
  padding: 9.07vh 0vh 0vw 0;
  width: 100%;
`;

const close = css`
  width: ${({ theme: { mobile } }) => (mobile ? '6.4vw' : '2.4vw')};
  height: 2.4vw;
  position: fixed;
  right: 2.3vw;
  top: ${({ theme: { mobile } }) => (mobile ? '1vh' : '2.87vh')};
  cursor: pointer;
  z-index: 100;
`;

const overflow = css`
  overflow: auto;
  width: ${({ theme: { mobile } }) => (mobile ? '100%' : '66vw')};
  padding: ${({ theme: { mobile } }) => (mobile ? '0 4vw' : '0')};
`;

export default {
  close,
  Training,
  overflow,
};
