import { useEffect, useRef, useState } from 'react';
import ContentDetail from 'components/ContentDetail';
import { SvgClose, SvgLogoSmallLoader } from 'components/Svgs';
import { loader, Modal } from 'styles/common';
import SC from './style';
import useAuth from 'hooks/useAuth';
import Error from 'pages/_error';
import useHandleKeyPress from 'hooks/useHandleKeyPress';
import hnaRequest from 'utils/hnaRequest';
import useAnalytics from 'hooks/useAnalytics';

export interface PostProps {
  data?: any
  loading?: any
  handleClose: any
}

const Content: React.FC<PostProps> = ({
  data, handleClose, loading,
}) => {
  const { user } = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  useHandleKeyPress('Escape', handleClose); // escapeKey

  const {
    onMouseMove, onScrollMove, handleTracked, handleUnMount,
  } = useAnalytics({ data });

  useEffect(() => {
    const getSuggestions = async () => {
      const suggest = await hnaRequest('get', `content/suggestions-get-list-by-id/${data.id}`, undefined);
      setSuggestions([
        ...suggest.articles.sort(() => 0.5 - Math.random()).slice(0, 2),
        ...suggest.trainings.sort(() => 0.5 - Math.random()).slice(0, 1),
      ]);
    };
    if (data?.id) getSuggestions();
  }, [data?.id]);

  useEffect(() => () => {
    handleUnMount();
  }, []);

  if (data?.error) return <Error statusCode={data.error} />;
  return (
    <Modal>
      {loading && <div css={loader}><SvgLogoSmallLoader /></div>}

      {!loading && data && (
        <SC.Content>
          <div tabIndex={0} role="link" css={SC.close} onClick={handleClose} onKeyPress={handleClose}>
            <SvgClose />
          </div>
          <div onScroll={onScrollMove} onMouseMove={onMouseMove} css={SC.overflow} className="scrollbar" id="scroll-video">
            <ContentDetail user={user} content={data} suggestions={suggestions} handleTracked={handleTracked} />
          </div>

        </SC.Content>
      )}
    </Modal>
  );
};

export default Content;
