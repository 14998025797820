import Image from 'next/image';
import SC from './style';

export interface WhereComeFromProps {
  type?: string
}

const WhereComeFrom: React.FC<WhereComeFromProps> = ({ type }) => (
  <div css={SC.comeFrom}>
    <div css={SC.fromTitle}>
      <Image
        height="26"
        width="26"
        src="/infos2.svg"
        alt="thumbnail"
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      <p css={SC.fromTitleTxt}>
        D&apos;où vient
        {' '}
        {type === 'formation' ? 'cette formation' : 'cet article'}
        {' '}
        ?
      </p>
    </div>
    <p css={SC.fromTxt}>
      {type === 'formation' ? 'Cette formation' : 'Cet article'}
      {' '}
      provient de la
      <span css={SC.fontBold}> Happyneuron Academy</span>
      , un espace personnalisé de Ressources, de Formations et de Partage d&apos;expérience dédié aux orthophonistes !
    </p>
  </div>
);

export default WhereComeFrom;
