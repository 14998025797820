import { css } from 'styled-components';

const logo = css`
  position: relative;
  width: 10.78125vw;
  min-height: 4.72vh;
  margin-bottom: 3.35vh;
  margin-top: 3.35vh;
`;

const title = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-26);
  font-weight: bold;
  line-height: 1.15;
  text-align: center;
  color: var(--color-black-02);
  margin-bottom: 2.96vh;
`;
const description = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-16);
  line-height: 1.5;
  text-align: center;
  color: var(--color-black-02);
  margin-bottom: 3.98vh;
  width: 85%;
`;
const answer = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-18);
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: var(--color-black-02);
  width: 85%;
  margin-bottom: 3.24vh;
`;
const rates = css`
  display: flex;
  margin-bottom: 3.96vh;
  svg {
    margin-right: 0.45vw;
  }

  * {
    cursor: pointer;
  }
`;

const reco = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-18);
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: var(--color-black-02);
  margin-bottom: 2.40vh;
`;

const checkboxs = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  label {
    margin-bottom: 1.48vh;
  }
`;

const accessButton = css`
  min-height: 6.57vh;
  width: 15.625vw;
  background: var(--color-black);
  margin-top: 4.35vh;
  display: flex;
  margin-bottom: 3.35vh;

  span {
    margin: auto;
    color: white;
    font-family: var(--font-family-sans);
    font-weight: 600;
    font-size: var(--size-21);
  }
`;

const starSvg = css`
  width: 3.073vw;
  height: 5.09vh;
  margin-right: 0.45vw;
`;

export default {
  logo,
  title,
  accessButton,
  checkboxs,
  description,
  answer,
  rates,
  starSvg,
  reco,
};
