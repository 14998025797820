import { useEffect, useState } from 'react';

const useFading = ({ elementId }) => {
  const [hasFading, setHasFading] = useState(false);
  const [hasFadingTop, setHasFadingTop] = useState(false);

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element.offsetHeight + element.scrollTop >= element.scrollHeight - 5) {
      setHasFading(false);
    } else {
      setHasFading(true);
    }
    const handleFading = () => {
      if (element.offsetHeight + element.scrollTop >= element.scrollHeight - 5) {
        setHasFading(false);
      } else {
        setHasFading(true);
      }
      if (element.scrollTop === 0) setHasFadingTop(false);
      else setHasFadingTop(true);
    };
    element.addEventListener('scroll', handleFading);
    return () => element.removeEventListener('scroll', handleFading);
  }, [elementId]);
  return { hasFading, hasFadingTop };
};

export default useFading;
