/* eslint-disable max-len */

export const SvgMailExchange = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21.897 17.346">
    <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(1.045 0.75)">
      <path id="Tracé_10709" data-name="Tracé 10709" d="M4.981,6H20.827a1.987,1.987,0,0,1,1.981,1.981V19.866a1.987,1.987,0,0,1-1.981,1.981H4.981A1.987,1.987,0,0,1,3,19.866V7.981A1.987,1.987,0,0,1,4.981,6Z" transform="translate(-3 -6)" fill="none" stroke="#5833ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path id="Tracé_10710" data-name="Tracé 10710" d="M22.808,9l-9.9,6.933L3,9" transform="translate(-3 -7.019)" fill="none" stroke="#5833ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  </svg>
);

export const SvgSendMessage = ({ disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 51 32">
    <g data-name="Groupe 8837" transform="translate(-1264 -966)">
      <rect data-name="Rectangle 10468" width="100%" height="100%" rx="5" transform="translate(1264 966)" style={{ fill: disabled ? 'rgb(172 169 183)' : '#5833ff' }} />
      <path data-name="Icon ionic-ios-send" d="M24.917 4.55 4.764 13.334a.462.462 0 0 0 .016.84l5.451 3.08a.879.879 0 0 0 1-.1l10.755-9.266c.071-.06.242-.176.307-.11s-.038.236-.1.307l-9.3 10.475a.876.876 0 0 0-.088 1.049l3.563 5.715a.464.464 0 0 0 .834-.011l8.336-20.154a.462.462 0 0 0-.621-.609z" transform="translate(1274.497 968.516)" style={{ fill: '#fff' }} />
    </g>
  </svg>
);

export const SvgEchangeDisabled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 413.272 367.189">
    <g data-name="Groupe 8867">
      <g data-name="Groupe 8864">
        <path data-name="Tracé 9978" d="M50.957 55.411c-70.338 54.607-83.476 151.81 62.341 244.332s322.013-30.837 297.71-192.752S121.3.806 50.957 55.411z" transform="rotate(180 206.635 174.675)" style={{ fill: '#927aff' }} />
        <g data-name="Groupe 8860" transform="translate(-108.104)">
          <circle data-name="Ellipse 4942" cx="37.234" cy="37.234" transform="translate(273.83)" style={{ fill: '#6c63ff' }} r="37.234" />
          <path data-name="Tracé 10168" d="M907.336 260.565h-30.851a2.13 2.13 0 0 1-2.128-2.128V239.82a2.13 2.13 0 0 1 2.128-2.128h30.851a2.13 2.13 0 0 1 2.128 2.128v18.617a2.13 2.13 0 0 1-2.128 2.128zm-30.851-20.745v18.617h30.852V239.82z" transform="translate(-580.847 -207.107)" style={{ fill: '#fff' }} />
          <path data-name="Tracé 10169" d="M910.087 221.47h-19.149v-8.511c0-6.164 4.027-10.638 9.574-10.638s9.574 4.474 9.574 10.638zm-17.021-2.128h14.894v-6.383c0-5.011-3.062-8.511-7.447-8.511s-7.447 3.5-7.447 8.511z" transform="translate(-589.448 -188.756)" style={{ fill: '#fff' }} />
          <circle data-name="Ellipse 4943" cx="2.128" cy="2.128" transform="translate(308.937 39.361)" style={{ fill: '#fff' }} r="2.128" />
        </g>
      </g>
      <g data-name="Groupe 8861" transform="translate(.009 87.36)">
        <path data-name="Tracé 10205" d="M687.166 218.224a2.233 2.233 0 0 0 1.618-3.871l-.153-.608.061-.146a6 6 0 0 1 11.062.041c1.809 4.358 4.113 8.723 4.68 13.33a17.744 17.744 0 0 1-.312 6.1 71.129 71.129 0 0 0 6.47-29.542 68.639 68.639 0 0 0-.426-7.659q-.353-3.127-.979-6.21a71.953 71.953 0 0 0-14.269-30.5 19.149 19.149 0 0 1-7.964-8.263 14.607 14.607 0 0 1-1.328-3.991c.388.051 1.462-5.854 1.169-6.216.54-.82 1.507-1.227 2.1-2.028 2.935-3.979 6.978-3.284 9.089 2.123 4.509 2.276 4.553 6.05 1.786 9.68-1.76 2.309-2 5.434-3.547 7.907.159.2.324.4.483.6a72.414 72.414 0 0 1 7.559 11.975 30.106 30.106 0 0 1 1.8-13.983c1.72-4.15 4.945-7.645 7.784-11.233a6.179 6.179 0 0 1 11.006 3.034l.017.159q-.632.357-1.239.758a3.029 3.029 0 0 0 1.221 5.512l.062.01a30.13 30.13 0 0 1-.794 4.506c3.643 14.088-4.222 19.219-15.451 19.449-.248.127-.489.254-.737.375a73.918 73.918 0 0 1 3.979 18.724 70.1 70.1 0 0 1-.051 11.32l.019-.133a18.525 18.525 0 0 1 6.324-10.7c4.867-4 11.743-5.47 16.993-8.684a3.477 3.477 0 0 1 5.325 3.382l-.021.14a20.344 20.344 0 0 0-2.282 1.1q-.633.357-1.239.758a3.029 3.029 0 0 0 1.221 5.512l.062.009.127.019a30.164 30.164 0 0 1-5.549 8.688c-2.278 12.3-12.061 13.465-22.525 9.883h-.006a73.9 73.9 0 0 1-4.964 14.491h-17.736c-.064-.2-.121-.4-.178-.6a20.15 20.15 0 0 0 4.907-.292c-1.316-1.614-2.631-3.242-3.947-4.856a1.1 1.1 0 0 1-.083-.1c-.667-.826-1.341-1.646-2.008-2.472a29.512 29.512 0 0 1 .865-7.518z" transform="translate(-464.247 -133.948)" style={{ fill: '#fff' }} />
        <path data-name="Tracé 10206" d="M805.522 198.724c-3.3-12.021 23.3-22.33 30.28-12.649.971-1.376 4.56-2.212 6.206-1.79a12.841 12.841 0 0 1 4.382 2.5 17.308 17.308 0 0 1 4.923 4.957c1.148 2.043 1.326 4.83-.219 6.6-1.224 1.41-4.838 2.322-6.679 2.694a88.827 88.827 0 0 1-3.293.625 6.3 6.3 0 0 1-1.1-4.163 5.11 5.11 0 0 0-2.229 3.749c-.025.312 10.378 38.589 10.378 38.589a22.694 22.694 0 0 1-3.926 1.765 49.37 49.37 0 0 1-6.122-13.341 42.754 42.754 0 0 0 .118 9.5 52.3 52.3 0 0 0 .9 5.261c-12.362 2.668-30.956 1.79-39.045-9.559v-.141a1.931 1.931 0 0 0-3.731-.673c-.2.5-.377.934-.538 1.279-2.363-8.427.685-18.18 9.695-35.203z" transform="translate(-525.668 -159.831)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10207" d="M770.948 679.207v.394a1.675 1.675 0 0 1 0-.394z" transform="translate(-512.13 -438.771)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10208" d="M798.558 523.895a6.707 6.707 0 0 1 .958-.186q-.557.091-.958.186z" transform="translate(-527.634 -351.492)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10209" d="M797.919 524.2c.085-.024.18-.048.28-.071-.093.028-.184.046-.28.071z" transform="translate(-527.275 -351.729)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10210" d="M870.8 627.731h-45.311a3.6 3.6 0 0 1-.97-4.438c1.238-2.839-1.188-5.433-1.188-5.433a7.707 7.707 0 0 1-1.553-7.1c1.2-4.193-3.5-16.028-3.5-16.028s-2.536 15.282-5.644 33h-43.099a8.011 8.011 0 0 1 0-8.111c2.511-4.258 0-5.075 0-5.075s-2.511-4.765 0-8.225 0-17.587 0-17.587c.095.625.81-.511 1.912-2.892 1.677-3.623 4.24-10.138 6.829-17.7 1.851-5.4 3.717-11.338 5.277-17.145q.613-2.288 1.162-4.535c.716-2.938 1.329-5.792 1.794-8.472 1.011-5.773 1.344-10.732.568-13.957-.644-2.684-2.052-4.164-4.482-3.91 1.3-.216 3.21-.42 5.561-.613.424-.034.863-.068 1.317-.106 17.535-1.325 55.155-2.086 55.155-2.086l11.523 20.635 14.593 26.139s-.395 35.062.056 63.639z" transform="translate(-510.717 -347.903)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10211" d="m824.456 306.815-26.548-5.632-5.271 10.61-17.751 10.277s11.442 46.785 11.377 53.365c-.166 16.706-26.9 54.154-6.706 51.274s24.291-14.948 30.773-13.04c5.845 1.721 12.983 9.376 17.761 3.9s6.462.847 9.483 1.066c1.436.1.571-6.914 4.184.218s22.771-2.468 16.278-8.962c-10.814-10.814-13.012-90.533-13.012-90.533l-12.039-3.7z" transform="translate(-512.737 -226.59)" style={{ fill: '#21ffcf' }} />
        <path data-name="Tracé 10212" d="M763.553 653.467a6.373 6.373 0 0 0-.656-8.864 6.106 6.106 0 0 0-.819-.593l4.439-21.785-10.262-6.142-2.854 30.5a6.355 6.355 0 0 0 1.465 7.619 6.082 6.082 0 0 0 8.579-.608q.055-.062.108-.127z" transform="translate(-501.906 -403.341)" style={{ fill: '#a0616a' }} />
        <path data-name="Tracé 10213" d="M794.868 381.839c-.064-.7-.958-1.018-2.2-1.457a13.578 13.578 0 0 1-3.509-1.662c-2.741-1.98-1.662-4.051-.17-6.912.292-.556.594-1.136.878-1.738.844-1.783-.5-9.138-4-21.865-1.079-3.918-1.075-6.685-1.821-8.521s-2.23-2.741-6.284-2.93a18.536 18.536 0 0 0-13.344 4.743c-.1.087-.2.182-.3.273q-.278.261-.545.534a18.522 18.522 0 0 0-5.383 12.734l-.019.814-.836 38.885-2.351 34.123-3.24 47 2.4.117 9.577.477 1.162.057 2.756-11.561 3.316-13.912 1.219-5.111 3.782-15.854 1.764-7.408 1.079-4.524.439-1.847 2.143-8.979c.049-.189 4.978-19.636 10.66-22.887 2.123-1.207 2.887-1.911 2.827-2.589z" transform="translate(-501.359 -246.542)" style={{ fill: '#21ffcf' }} />
        <path data-name="Tracé 10214" d="M987.292 652.213a6.373 6.373 0 0 1-.3-8.883 6.11 6.11 0 0 1 .75-.677l-6.752-21.183 9.543-7.208 6.113 30.017a6.355 6.355 0 0 1-.638 7.732 6.082 6.082 0 0 1-8.6.317z" transform="translate(-630.031 -402.318)" style={{ fill: '#a0616a' }} />
        <path data-name="Tracé 10215" d="M943.72 453.124s-7.444-26.186-5.175-29.021-4.434-3.388-1.545-8.668-1.1-6.162-1.1-6.162l-2.164-12.139-3.868-29.815-.117-.887-1.125-8.674A18.576 18.576 0 0 0 921.3 345.2c-.1-.078-.207-.153-.311-.229q-.714-.514-1.46-.953a18.541 18.541 0 0 0-12.329-2.338c-8.022 1.249-5.525 4.241-6.826 12.255-2.114 13.031-2.663 20.486-1.632 22.171.347.567.712 1.11 1.06 1.631 1.791 2.689 3.086 4.629.572 6.9a13.707 13.707 0 0 1-3.31 2.025c-1.181.572-2.037.983-2.025 1.686.013.681.849 1.3 3.085 2.272 2.71 1.186 5.62 5.67 8 10.25a113.118 113.118 0 0 1 5.053 11.362l20.49 57.627 3.342 9.4 1.153-.182 9.467-1.5 2.374-.376z" transform="translate(-581.788 -249.192)" style={{ fill: '#21ffcf' }} />
        <circle data-name="Ellipse 4953" cx="21.659" cy="21.659" r="21.659" transform="translate(278.86 30.876)" style={{ fill: '#a0616a' }} />
        <path data-name="Tracé 10216" d="M778.576 202.574c-3.3-12.021 23.3-22.33 30.28-12.649.971-1.376 4.56-2.212 6.206-1.79a12.841 12.841 0 0 1 4.382 2.5 17.308 17.308 0 0 1 4.923 4.957c1.148 2.043 1.326 4.83-.22 6.6-1.224 1.41-4.838 2.322-6.679 2.694a88.436 88.436 0 0 1-3.293.625 6.3 6.3 0 0 1-1.1-4.163 5.111 5.111 0 0 0-2.229 3.749c-.025.312 10.378 38.589 10.378 38.589a22.7 22.7 0 0 1-3.926 1.765 49.375 49.375 0 0 1-6.122-13.341 42.757 42.757 0 0 0 .118 9.5 52.266 52.266 0 0 0 .9 5.261c-12.362 2.668-30.956 1.79-39.045-9.559v-.141a1.931 1.931 0 0 0-3.731-.673c-.2.5-.377.934-.538 1.279-2.362-8.427.686-18.177 9.696-35.203z" transform="translate(-510.543 -161.992)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10218" d="M478.809 289.223a2.233 2.233 0 0 1-1.618-3.871l.153-.608-.061-.146a6 6 0 0 0-11.062.041c-1.809 4.358-4.113 8.723-4.68 13.33a17.742 17.742 0 0 0 .311 6.1 71.131 71.131 0 0 1-6.47-29.542 68.655 68.655 0 0 1 .426-7.659q.353-3.127.979-6.21a71.952 71.952 0 0 1 14.269-30.5 19.149 19.149 0 0 0 7.964-8.263 14.607 14.607 0 0 0 1.328-3.992c-.388.051-1.462-5.854-1.169-6.216-.54-.82-1.507-1.227-2.1-2.027-2.935-3.979-6.978-3.284-9.089 2.123-4.509 2.276-4.553 6.05-1.786 9.68 1.76 2.309 2 5.434 3.547 7.907-.159.2-.324.4-.483.6a72.424 72.424 0 0 0-7.559 11.975 30.1 30.1 0 0 0-1.8-13.983c-1.72-4.15-4.945-7.645-7.784-11.233a6.179 6.179 0 0 0-11.006 3.034l-.017.159q.633.357 1.239.758a3.029 3.029 0 0 1-1.221 5.512l-.062.01a30.131 30.131 0 0 0 .794 4.506c-3.643 14.088 4.222 19.219 15.451 19.449.248.127.489.254.737.375a73.914 73.914 0 0 0-3.979 18.724 70.11 70.11 0 0 0 .051 11.32l-.019-.133a18.525 18.525 0 0 0-6.324-10.7c-4.867-4-11.743-5.47-16.993-8.684a3.477 3.477 0 0 0-5.325 3.382l.021.14a20.35 20.35 0 0 1 2.282 1.1q.633.357 1.239.758a3.029 3.029 0 0 1-1.221 5.512l-.062.01-.127.019a30.169 30.169 0 0 0 5.549 8.688c2.278 12.3 12.061 13.465 22.525 9.883h.006a73.9 73.9 0 0 0 4.964 14.491h17.733c.064-.2.121-.4.178-.6a20.149 20.149 0 0 1-4.907-.292c1.316-1.614 2.631-3.242 3.947-4.856a1.1 1.1 0 0 0 .083-.1c.667-.826 1.341-1.646 2.008-2.472a29.512 29.512 0 0 0-.865-7.518z" transform="translate(-318.192 -173.799)" style={{ fill: '#fff' }} />
        <path data-name="Tracé 10219" d="M340.4 190.979c-.4.938-12.812.837-13.381 1.673-4.382 6.448 11.415 33.881 7.032 40.338l-.61.728a28.846 28.846 0 0 0 4.106-2.317c.117-2.643.234-5.294.36-7.936a21.812 21.812 0 0 1 3.144 5.528 35.8 35.8 0 0 1 6.23-4.675 27.426 27.426 0 0 1 6.06-4.989z" transform="translate(-262.53 -164.734)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10220" d="M267.885 309.3a10.278 10.278 0 0 0-15.571 6.08l-18.858 49.137-32.415-18.4a10.036 10.036 0 1 0-6.261 9.982c6.826 7.286 36.421 37.157 48.673 25.486 10.3-9.81 25.16-47.832 28.738-60.962a10.279 10.279 0 0 0-4.306-11.323z" transform="translate(-181 -230.21)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10221" d="m117.341 349.883 5.951 26.9 43.517 3.936 4-23.421z" transform="translate(-65.867 -196.455)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10222" d="M327.8 572.992c-.02.386-.044.767-.072 1.153-.417 5.573-.894 12-1.411 19.046a9692.45 9692.45 0 0 0-2.644 37.225H232.4l17.7-53.208s7.148-3.935 3.509-10.546 3.521-1.343 3.588-10.784 6.483-19.486 6.483-19.486l1.053-14.3.6.012 47.5.791.867.016 1.459 12.272a54.254 54.254 0 0 1 12.641 37.809z" transform="translate(-209.854 -350.586)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10223" d="m322.585 280.288 21.66-1.116 10.061 15.9s18.929 3.165 17.617 25.471-23.031 41.879-23.031 41.879l.928-3.183-1.612 4.607.207 2.587 2.2 2.74.225 2.827-1.949 4.483a2.562 2.562 0 0 1-2.429 3.9l-31.3-3.137c-.713-1.96-1.284-4.305-4.141-.4l-2.948-.286-12.256-1.187-.721-2.628a10.18 10.18 0 0 1 1.656-8.781 13.428 13.428 0 0 1 .777-10.082l.059-.117 1.3-1.085S284.654 331.3 301 315.184l6.561-11.153 7.341-8.956z" transform="translate(-243.951 -214.236)" style={{ fill: '#175df9' }} />
        <circle data-name="Ellipse 4954" cx="20.54" cy="20.54" r="20.54" transform="translate(63.485 15.588)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10224" d="M351.377 207.005a9.892 9.892 0 0 1-3.312-5.436 21.253 21.253 0 0 0 .293 7.761 131.585 131.585 0 0 1-3.211 2.35 21.814 21.814 0 0 0-3.147-5.528c-.125 2.643-.243 5.294-.36 7.936a28.847 28.847 0 0 1-4.106 2.317l.611-.728c4.382-6.456-6.958-18.34-2.576-24.788 1.229-1.806-4.324-16.082-8.555-16.726-3.763-.569-3.6.518-7.251 1.313a18.245 18.245 0 0 0-1.748-4.524 14.949 14.949 0 0 1-.142 4.767c-1.3.05-2.316-.776-3.7-.987-8.806-1.346-7.911-10.872-5.461-13.891s10.621-3.914 14.384-4.892c.343-.334 1.121-.125 1.464-.443 7.212-6.6 16.858-7.693 25.538-4.768 9.257 3.12 15.028 9.158 17.99 18.466l.85 7.449c.762 2.4-.2 13.54-.85 15.967-2.532 9.436-7.937 4.726-16.711 14.385z" transform="translate(-251.752 -141.288)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10225" d="m446.126 409.393-.809-62.978a10.166 10.166 0 0 0-10.165-10.006A10.166 10.166 0 0 0 425 347.115l2.845 65.393.054 39.808a8.871 8.871 0 1 0 10.892 3.317z" transform="translate(-316.552 -246.363)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10226" d="M596.919 642s-1.25 22.493-1.825 35.321h-43.055c-.886-11.674-1.184-20.251-1.184-20.251l-2.355 20.25h-43.52l-2.7-37.505 13.038-32.32s70.659-4.9 77.213 1.316S596.919 642 596.919 642z" transform="translate(-361.336 -397.49)" style={{ fill: '#2f2e41' }} />
        <circle data-name="Ellipse 4955" cx="23.518" cy="23.518" r="23.518" transform="translate(173.992 8.263)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10227" d="M624.656 155.137c-2.416 1.1-3.981 9.079-5.879 7.224s-1.772-4.268-4.332-4.952a8.676 8.676 0 0 0-6.177 1.08 20.53 20.53 0 0 0-.981.559 8.369 8.369 0 0 0-.643-.394 7.473 7.473 0 0 0-5.5-.619 7.331 7.331 0 0 1 3.623 2.671c-.631.423-1.27.847-1.924 1.223-5.462 3.152-13.234-3.7-18.983-6.277 3.6 6.758 1.828 9.557-1.3 16.546l-.108.4c.178-1.752-5.243-3.057-4.928-.591a29.689 29.689 0 0 0 2.587 7.188c-6.045-6.989-10.176-15.543-9.3-24.366.19-1.854.133-3.809 1.089-5.418s2.952-2.724 4.717-2.113a15.593 15.593 0 0 1 23.474-14.269 59.589 59.589 0 0 1 6.21 4.813c2.069 1.616 4.453 3.024 7.077 3.157 1.664.08 3.475-.326 4.9.536 1.656 1 1.94 3.094 2.188 5.109a7.464 7.464 0 0 0-4.022 1.674 7.3 7.3 0 0 1 4.4.943.453.453 0 0 1 .072.041c.873 3.327.784 4.065 3.74 5.835z" transform="translate(-399.734 -130.998)" style={{ fill: '#2f2e41' }} />
        <path data-name="Tracé 10228" d="M508.668 426.453c20.028-23.443 13.263-53.259 5.314-83.359l1.513-44.465a13.6 13.6 0 0 1 6.155-10.925l20.445-13.357 7.958-11.506 21.935 2.194 12.164 12.865 13.464 10.279a22.305 22.305 0 0 1 8.244 22.545l-12.885 58.27s5.389 30.208 2.1 33.544-11.761 0-5.042 3.747c8.063 4.494 15.4 3.665 11.315 7.746s1.084 9.754 1.084 9.754z" transform="translate(-364.922 -205.07)" style={{ fill: '#5833ff' }} />
        <path data-name="Tracé 10229" d="m508.706 678.137 3.339-23.787-13.322-3.8-2.635 25.275a8.041 8.041 0 1 0 12.619 2.315z" transform="translate(-356.397 -422.684)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10230" d="M530.432 298.842s-37.921 12.342-38.826 21.373-.6 14.559-4.607 50.7l.211 97.342s25.424 5.443 22.817-.377-1.7-8.558-1.7-8.558 5.451-2.151 4.086-8.985 5.478-40.7 5.478-40.7l4.319-60.735z" transform="translate(-352.76 -225.276)" style={{ fill: '#5833ff' }} />
        <path data-name="Tracé 10231" d="m690.308 676.176-1.777-23.955 13.543-2.924.977 25.393a8.041 8.041 0 1 1-12.743 1.486z" transform="translate(-465.877 -421.983)" style={{ fill: '#ffb6b6' }} />
        <path data-name="Tracé 10232" d="M661.99 293.078s37.034 14.795 37.346 23.865-.349 14.567 1.283 50.888l-6.573 97.12s-25.725 3.77-22.743-1.867 2.253-8.429 2.253-8.429-5.3-2.5-3.49-9.232-2.806-40.969-2.806-40.969l-.34-60.887z" transform="translate(-450.98 -222.041)" style={{ fill: '#5833ff' }} />
      </g>
    </g>
  </svg>

);

export const SvgWomenEchange = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 218.629 480">
    <g data-name="Groupe 8862" transform="translate(-61.343 -762.585)">
      <path data-name="Tracé 10206" d="M814.572 212.975c-6.148-22.416 43.441-41.639 56.463-23.587 1.811-2.566 8.5-4.125 11.573-3.338s5.653 2.755 8.172 4.661c3.464 2.661 7.054 5.416 9.18 9.242 2.141 3.81 2.472 9.006-.409 12.313-2.283 2.629-9.022 4.33-12.455 5.023-2.393.488-4.409.882-6.141 1.165a11.741 11.741 0 0 1-2.047-7.763 9.528 9.528 0 0 0-4.157 6.991c-.047.583 19.351 71.957 19.351 71.957a42.313 42.313 0 0 1-7.322 3.291 92.057 92.057 0 0 1-11.415-24.878 79.713 79.713 0 0 0 .22 17.714 97.506 97.506 0 0 0 1.669 9.809c-23.051 4.976-57.723 3.338-72.807-17.824q0-.131-.008-.263a3.6 3.6 0 0 0-6.956-1.256c-.366.934-.7 1.742-1 2.385-4.395-15.717 1.289-33.899 18.089-65.642z" transform="translate(-698.284 580.342)" style={{ fill: '#2f2e41' }} />
      <path data-name="Tracé 10210" d="M959.33 723.207h-84.494a6.719 6.719 0 0 1-1.809-8.275c2.308-5.294-2.215-10.131-2.215-10.131a14.37 14.37 0 0 1-2.9-13.245c2.239-7.819-6.534-29.888-6.534-29.888s-4.729 28.5-10.525 61.539H770.5a14.937 14.937 0 0 1 0-15.125c4.683-7.941 0-9.463 0-9.463s-4.683-8.885 0-15.337 0-32.795 0-32.795c.176 1.165 1.51-.953 3.565-5.393 3.127-6.755 7.906-18.9 12.734-33.007 3.452-10.066 6.932-21.141 9.84-31.969h.007q1.144-4.267 2.167-8.456c1.334-5.478 2.478-10.8 3.346-15.8 1.885-10.765 2.506-20.012 1.059-26.026-1.2-5-3.826-7.765-8.358-7.292 2.428-.4 5.986-.784 10.369-1.143.791-.064 1.609-.127 2.457-.2 32.7-2.471 102.847-3.889 102.847-3.889l21.487 38.478 27.212 48.741s-.744 65.389.098 118.676z" transform="translate(-693.439 519.379)" style={{ fill: '#262626' }} />
      <path data-name="Tracé 10211" d="m869.8 311.684-49.5-10.5-9.829 19.785-33.1 19.164s21.329 87.237 21.21 99.507c-.309 31.151-50.152 100.981-12.5 95.61s45.3-27.874 57.381-24.316c10.9 3.209 24.209 17.484 33.119 7.274s12.049 1.58 17.683 1.987c2.678.194 1.065-12.892 7.8.407s42.461-4.6 30.353-16.711c-20.165-20.165-24.263-168.816-24.263-168.816l-22.454-6.906z" transform="translate(-694.093 558.702)" style={{ fill: '#21ffcf' }} />
      <path data-name="Tracé 10212" d="M772.919 685.792a11.883 11.883 0 0 0-1.224-16.529 11.38 11.38 0 0 0-1.526-1.105l8.277-40.622-19.135-11.452-5.322 56.873a11.85 11.85 0 0 0 2.731 14.207 11.341 11.341 0 0 0 16-1.134q.1-.117.199-.238z" transform="translate(-690.581 501.406)" style={{ fill: '#a0616a' }} />
      <path data-name="Tracé 10213" d="M832.154 420.844c-.12-1.306-1.786-1.9-4.094-2.718a25.323 25.323 0 0 1-6.543-3.1c-5.111-3.692-3.1-7.553-.318-12.889a87.178 87.178 0 0 0 1.638-3.24c1.574-3.325-.932-17.04-7.461-40.772-2.012-7.306-2-12.466-3.4-15.889s-4.158-5.111-11.718-5.464a34.564 34.564 0 0 0-24.882 8.845c-.183.162-.374.339-.558.508q-.519.487-1.017 1a34.538 34.538 0 0 0-10.038 23.746l-.035 1.518-1.56 72.508-4.384 63.628-6.042 87.649 4.475.219 17.858.89 2.167.106 5.139-21.558 6.183-25.941 2.273-9.53 7.063-29.56 3.289-13.814 2.012-8.435.818-3.445 4-16.744c.092-.353 9.283-36.614 19.878-42.678 3.944-2.263 5.37-3.576 5.257-4.84z" transform="translate(-690.404 552.232)" style={{ fill: '#21ffcf' }} />
      <path data-name="Tracé 10214" d="M992.741 685.029a11.883 11.883 0 0 1-.559-16.565 11.39 11.39 0 0 1 1.4-1.263L980.99 627.7l17.794-13.441 11.4 55.972a11.85 11.85 0 0 1-1.19 14.418 11.341 11.341 0 0 1-16.027.591q-.114-.102-.226-.211z" transform="translate(-732.113 501.739)" style={{ fill: '#a0616a' }} />
      <path data-name="Tracé 10215" d="M985.812 549.687s-13.882-48.828-9.649-54.115-8.268-6.318-2.881-16.163-2.048-11.491-2.048-11.491l-4.034-22.634-7.212-55.6-.219-1.653-2.1-16.175a34.638 34.638 0 0 0-13.658-23.41 23.965 23.965 0 0 0-.58-.426q-1.332-.959-2.722-1.777a34.574 34.574 0 0 0-22.993-4.367c-14.959 2.33-10.3 7.908-12.729 22.852-3.942 24.3-4.965 38.2-3.043 41.342.647 1.058 1.328 2.07 1.977 3.041 3.34 5.014 5.755 8.631 1.067 12.858a25.557 25.557 0 0 1-6.172 3.776c-2.2 1.067-3.8 1.832-3.776 3.143.025 1.27 1.582 2.418 5.752 4.237 5.053 2.211 10.48 10.572 14.926 19.113a210.908 210.908 0 0 1 9.422 21.187l38.207 107.457 6.232 17.524 2.15-.34 17.654-2.8 4.427-.7z" transform="translate(-716.476 551.376)" style={{ fill: '#21ffcf' }} />
      <circle data-name="Ellipse 4953" cx="40.388" cy="40.388" r="40.388" transform="translate(114.434 778.368)" style={{ fill: '#a0616a' }} />
      <path data-name="Tracé 10216" d="M787.626 216.825c-6.148-22.416 43.441-41.638 56.463-23.587 1.811-2.566 8.5-4.125 11.573-3.338s5.653 2.755 8.172 4.661c3.464 2.661 7.054 5.416 9.179 9.243 2.141 3.81 2.472 9.006-.409 12.313-2.283 2.629-9.022 4.33-12.455 5.023-2.393.488-4.409.882-6.141 1.165a11.741 11.741 0 0 1-2.047-7.762 9.529 9.529 0 0 0-4.157 6.991c-.047.583 19.351 71.957 19.351 71.957a42.322 42.322 0 0 1-7.322 3.291 92.073 92.073 0 0 1-11.414-24.882 79.733 79.733 0 0 0 .22 17.714 97.476 97.476 0 0 0 1.669 9.809c-23.051 4.976-57.723 3.338-72.807-17.824 0-.088-.005-.175-.008-.263a3.6 3.6 0 0 0-6.956-1.256c-.366.934-.7 1.742-1 2.385-4.395-15.711 1.289-33.898 18.089-65.64z" transform="translate(-693.382 579.639)" style={{ fill: '#2f2e41' }} />
    </g>
  </svg>

);

export const SvgWomenBulle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 394.001 275.055">
    <path data-name="Union 23" d="M184.315 155H24.426a16.5 16.5 0 0 1-16.5-16.5v-209a16.5 16.5 0 0 1 16.5-16.5h361a16.5 16.5 0 0 1 16.5 16.5v209a16.5 16.5 0 0 1-16.5 16.5H210.267l-25.953 33.054z" transform="translate(-7.925 87)" style={{ fill: '#1a1a1a', opacity: '.9' }} />
  </svg>
);

export const SvgPolygone = ({ hide }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 10" style={{ transform: hide ? 'rotate(270deg)' : 'initial' }}>
    <path data-name="Polygone 14" d="M5.5 0 11 10H0z" transform="rotate(180 5.5 5)" style={{ fill: '#000' }} />
  </svg>
);

export const SvgCadenas = ({ selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14.876 13.223">
    <path data-name="Icon awesome-lock-open" d="M10.938 0a3.951 3.951 0 0 0-3.913 3.964v1.821H1.24A1.24 1.24 0 0 0 0 7.025v4.959a1.24 1.24 0 0 0 1.24 1.24h9.091a1.24 1.24 0 0 0 1.24-1.24V7.025a1.24 1.24 0 0 0-1.24-1.24h-1.24V3.949a1.86 1.86 0 1 1 3.719-.023v2.066a.618.618 0 0 0 .62.62h.826a.618.618 0 0 0 .62-.62V3.926A3.93 3.93 0 0 0 10.938 0z" style={{ fill: selected ? '#fff' : '#000' }} />
  </svg>
);

export const SvgCadenasClose = ({ selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11.57 13.223">
    <path id="Icon_awesome-lock" data-name="Icon awesome-lock" d="M10.33,5.785H9.71V3.925a3.925,3.925,0,0,0-7.851,0V5.785H1.24A1.24,1.24,0,0,0,0,7.025v4.958a1.24,1.24,0,0,0,1.24,1.24H10.33a1.24,1.24,0,0,0,1.24-1.24V7.025A1.24,1.24,0,0,0,10.33,5.785Zm-2.686,0H3.925V3.925a1.859,1.859,0,0,1,3.719,0Z" style={{ fill: selected ? '#fff' : '#000' }} />
  </svg>
);

export const SvgLike = ({ fill = false }) => (
  <svg data-name="Composant 80 – 5" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38.723 35.803">
    <defs>
      <clipPath id="vpj7zq0oma">
        <path data-name="Rectangle 10455" style={{ fill: 'red', stroke: '#cbcbcb' }} d="M0 0h38.723v35.803H0z" />
      </clipPath>
    </defs>
    <g data-name="Groupe 9271">
      <g data-name="Groupe 8811" style={{ clipPath: 'url(#vpj7zq0oma)' }}>
        <path data-name="Tracé 10143" d="M27.91 15.024a2.743 2.743 0 0 0-.28-1.193c-1.169-3.678-6.642-3.409-14.426-3.588-1.3-.03-.557-1.529-.1-4.818C13.4 3.285 11.986 0 9.609 0 5.688 0 9.46 3.016 5.993 10.474 4.14 14.459 0 12.227 0 16.23v9.111c0 1.558.157 3.056 2.4 3.3 2.178.239 1.688 1.753 4.831 1.753h15.733a2.873 2.873 0 0 0 2.905-2.834 2.763 2.763 0 0 0-.609-1.715 2.816 2.816 0 0 0 1.5-2.465 2.764 2.764 0 0 0-.606-1.713 2.773 2.773 0 0 0 .666-4.451 2.791 2.791 0 0 0 1.09-2.194" transform="translate(5.406 2.703)" style={{ fill: fill ? '#ffdb5e' : 'none', stroke: fill ? '#ffdb5e' : '#969696', strokeWidth: '2px' }} />
        <path data-name="Tracé 10699" d="M30.261 28.642h7.5a2.934 2.934 0 0 0 2.5-1.388.734.734 0 0 0-.267-1.019.774.774 0 0 0-1.045.261 1.392 1.392 0 0 1-1.186.659H30.08a1.346 1.346 0 1 1 0-2.691h5.131a.744.744 0 1 0 0-1.488h-5.132a2.873 2.873 0 0 0-2.905 2.833 2.79 2.79 0 0 0 1.05 2.162 2.761 2.761 0 0 0 .187 4.184 2.778 2.778 0 0 0 .365 4.318 2.771 2.771 0 0 0-.742 1.874 2.873 2.873 0 0 0 2.905 2.833h4.78a2.935 2.935 0 0 0 2.5-1.387.734.734 0 0 0-.266-1.019.775.775 0 0 0-1.045.259 1.4 1.4 0 0 1-1.189.66h-4.78a1.346 1.346 0 1 1 0-2.691h5.671a2.934 2.934 0 0 0 2.5-1.388.733.733 0 0 0-.266-1.019.769.769 0 0 0-1.045.259 1.378 1.378 0 0 1-1.189.661h-6.157a1.347 1.347 0 1 1 0-2.692h7.053a2.935 2.935 0 0 0 2.5-1.387.733.733 0 0 0-.266-1.019.774.774 0 0 0-1.045.259 1.378 1.378 0 0 1-1.188.66h-7.246a1.347 1.347 0 1 1 0-2.692" transform="translate(-7.049 -8.083)" style={{ fill: fill ? '#ee9547' : '#969696' }} />
      </g>
    </g>
  </svg>
);

export const SvgWarning = () => (
  <svg data-name="Composant 82 – 2" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 30 26">
    <g data-name="Groupe 8806">
      <g data-name="Polygone 18" style={{ fill: 'none' }}>
        <path d="M13.268 3a2 2 0 0 1 3.465 0L28.27 23a2 2 0 0 1-1.732 3H3.463a2 2 0 0 1-1.733-3z" style={{ stroke: 'none' }} />
        <path d="M15 4.002 3.463 24h23.074L15 4.002m0-2c.674 0 1.348.334 1.732 1L28.27 23.002c.769 1.333-.193 2.999-1.733 2.999H3.463c-1.54 0-2.502-1.666-1.733-3L13.268 3.004c.384-.667 1.058-1 1.732-1z" style={{ fill: '#969696', stroke: 'none' }} />
      </g>
      <text
        data-name="!"
        transform="translate(12 21)"
        style={{
          fill: '#969696', fontSize: '17px', fontFamily: 'SourceSansPro-Bold,Source Sans Pro', fontWeight: 700,
        }}
      >
        <tspan x="0" y="0">!</tspan>
      </text>
    </g>
  </svg>
);

export const SvgShare = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.542 29.496">
    <g data-name="Groupe 9273">
      <path data-name="Icon material-content-copy" d="M21.452 1.5H5.636A2.644 2.644 0 0 0 3 4.136v18.452h2.636V4.136h15.816zm3.954 5.272h-14.5a2.644 2.644 0 0 0-2.634 2.636V27.86a2.644 2.644 0 0 0 2.636 2.64h14.5a2.644 2.644 0 0 0 2.636-2.636V9.408a2.644 2.644 0 0 0-2.638-2.636zm0 21.088h-14.5V9.408h14.5z" transform="translate(-2.75 -1.25)" style={{ fill: '#969696', stroke: '#fff', strokeWidth: '.5px' }} />
      <path data-name="Icon metro-link" d="M7.477 8.905a.55.55 0 0 1-.39-.162 2.591 2.591 0 0 1 0-3.66l2.036-2.035a2.588 2.588 0 1 1 3.66 3.66l-.931.931a.552.552 0 0 1-.78-.78L12 5.927a1.485 1.485 0 0 0-2.1-2.1L7.866 5.864a1.487 1.487 0 0 0 0 2.1.552.552 0 0 1-.39.942zM5.522 12.9a2.588 2.588 0 0 1-1.83-4.418l.931-.931a.552.552 0 1 1 .78.78l-.931.931a1.485 1.485 0 0 0 2.1 2.1l2.037-2.04a1.487 1.487 0 0 0 0-2.1.552.552 0 1 1 .78-.78 2.591 2.591 0 0 1 0 3.66l-2.037 2.036a2.571 2.571 0 0 1-1.83.758z" transform="translate(6.88 10.152)" style={{ fill: '#878787' }} />
    </g>
  </svg>

);

export const SvgEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32.007 32.007">
    <g data-name="Composant 84 – 1">
      <path data-name="Icon metro-pencil" d="M28.6 1.928a4.82 4.82 0 0 1 3.855 7.712l-1.928 1.928-6.748-6.748 1.928-1.928a4.8 4.8 0 0 1 2.893-.964zM4.5 24.1l-1.929 8.677 8.676-1.928 17.834-17.835-6.748-6.748zm19.628-11.219-13.5 13.5-1.658-1.665 13.5-13.5 1.662 1.662z" transform="translate(-1.912 -1.428)" style={{ fill: '#969696', stroke: '#fff' }} />
    </g>
  </svg>
);

export const SvgEmoji = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20.607 20.607">
    <g id="Groupe_9274" data-name="Groupe 9274" transform="translate(-721.125 -578.924)">
      <path id="Ellipse_4933" data-name="Ellipse 4933" d="M10.3,2a8.3,8.3,0,1,0,8.3,8.3A8.313,8.313,0,0,0,10.3,2m0-2A10.3,10.3,0,1,1,0,10.3,10.3,10.3,0,0,1,10.3,0Z" transform="translate(721.125 578.924)" fill="#969696" />
      <path id="Tracé_10136" data-name="Tracé 10136" d="M804.787,314.028a1.334,1.334,0,1,0,1.334,1.334,1.334,1.334,0,0,0-1.334-1.334" transform="translate(-76.492 272.531)" fill="#969696" />
      <path id="Tracé_10137" data-name="Tracé 10137" d="M804.787,314.028a1.334,1.334,0,1,0,1.334,1.334,1.334,1.334,0,0,0-1.334-1.334" transform="translate(-70.226 272.531)" fill="#969696" />
      <path id="Soustraction_14" data-name="Soustraction 14" d="M5.72,3.13A6.767,6.767,0,0,1,0,0H11.44A6.766,6.766,0,0,1,5.72,3.13Z" transform="translate(725.709 591.842)" fill="#969696" />
    </g>
  </svg>

);

export const SvgReply = () => (
  <svg data-name="Composant 81 – 4" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 45.496 43.496">
    <defs>
      <clipPath id="olmk2t8gwa">
        <path data-name="Rectangle 10452" transform="translate(-3 -4)" style={{ fill: '#969696' }} d="M0 0h45.496v43.496H0z" />
      </clipPath>
    </defs>
    <g data-name="Groupe 8804">
      <g data-name="Groupe 8803" style={{ clipPath: 'url(#olmk2t8gwa)' }} transform="translate(3 4)">
        <path data-name="Tracé 10131" d="m23.969 406.171-3.055-3.943a.521.521 0 0 0-.411-.2H3.57a2.568 2.568 0 0 1-2.514-2.614v-12.273a2.568 2.568 0 0 1 2.514-2.614h9.907v-1.1H3.57A3.647 3.647 0 0 0 0 387.141v12.268a3.647 3.647 0 0 0 3.57 3.712h16.68l3.836 4.951a.521.521 0 0 0 .411.2.509.509 0 0 0 .176-.031.548.548 0 0 0 .352-.518v-14.217h-1.056z" transform="translate(0 -372.781)" style={{ stroke: '#969696', fill: '#969696' }} />
        <path data-name="Tracé 10132" d="M563.247 0h-17.885a3.647 3.647 0 0 0-3.57 3.712V24.3a.548.548 0 0 0 .352.518.51.51 0 0 0 .176.031.521.521 0 0 0 .411-.2l3.836-4.951h16.68a3.647 3.647 0 0 0 3.57-3.712V3.715A3.647 3.647 0 0 0 563.247 0m2.514 15.98a2.568 2.568 0 0 1-2.514 2.614h-16.933a.52.52 0 0 0-.411.2l-3.055 3.943V3.715a2.568 2.568 0 0 1 2.514-2.615h17.885a2.568 2.568 0 0 1 2.514 2.614z" transform="translate(-527.322 -.003)" style={{ stroke: '#969696', fill: '#969696' }} />
        <path data-name="Tracé 10133" d="M804.678 314.028a1.274 1.274 0 1 0 1.222 1.272 1.25 1.25 0 0 0-1.225-1.273" transform="translate(-781.994 -305.308)" style={{ fill: '#969696' }} />
        <path data-name="Tracé 10134" d="M971.674 314.028a1.274 1.274 0 1 0 1.226 1.272 1.25 1.25 0 0 0-1.225-1.273" transform="translate(-944.53 -305.308)" style={{ fill: '#969696' }} />
        <path data-name="Tracé 10135" d="M1138.672 314.028a1.274 1.274 0 1 0 1.225 1.273 1.25 1.25 0 0 0-1.225-1.273" transform="translate(-1107.068 -305.308)" style={{ fill: '#969696' }} />
      </g>
    </g>
  </svg>

);

export const SvgTrash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.981 28.645">
    <g data-name="Composant 83 – 1">
      <g data-name="Icon feather-trash-2">
        <path
          data-name="Tracé 3124"
          d="M4.5 9h23.981"
          transform="translate(-3.5 -2.521)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
        <path
          data-name="Tracé 3125"
          d="M26 8.329v18.652a2.654 2.654 0 0 1-2.643 2.665H10.143A2.654 2.654 0 0 1 7.5 26.981V8.329m3.965 0V5.665A2.654 2.654 0 0 1 14.108 3h5.286a2.654 2.654 0 0 1 2.643 2.665v2.664"
          transform="translate(-3.761 -2)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
        <path
          data-name="Tracé 3126"
          d="M15 16.5v8.218"
          transform="translate(-4.674 -3.654)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
        <path
          data-name="Tracé 3127"
          d="M21 16.5v8.218"
          transform="translate(-5.345 -3.654)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
      </g>
    </g>
  </svg>
);

export const SvgBold = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12.513 13.734">
    <path id="Tracé_10693" data-name="Tracé 10693" d="M6.428,6.638a1.265,1.265,0,0,0,.992-.381A1.25,1.25,0,0,0,7.763,5.4a1.3,1.3,0,0,0-.314-.916,1.327,1.327,0,0,0-1.021-.343H4.272v2.5Zm.038,5.055a2.237,2.237,0,0,0,1.287-.334,1.134,1.134,0,0,0,.486-1,1.109,1.109,0,0,0-.486-.992,2.283,2.283,0,0,0-1.287-.325H4.272v2.652ZM0,3.662A2.762,2.762,0,0,1,.61,1.668,2.55,2.55,0,0,1,2.5,1.049H7.057a8.066,8.066,0,0,1,2.327.286,4.189,4.189,0,0,1,1.5.763,2.69,2.69,0,0,1,.8,1.107,3.683,3.683,0,0,1,.238,1.316,3.562,3.562,0,0,1-.429,1.822,2.336,2.336,0,0,1-1.516,1.02V7.4a2.812,2.812,0,0,1,1.9,1.144,3.737,3.737,0,0,1,.639,2.194,4.022,4.022,0,0,1-.314,1.583,3.471,3.471,0,0,1-.944,1.287,4.569,4.569,0,0,1-1.6.858,7.46,7.46,0,0,1-2.289.315H2.5a2.548,2.548,0,0,1-1.888-.62A2.76,2.76,0,0,1,0,12.169Z" transform="translate(0 -1.049)" fill="#878787" fillRule="evenodd" />
  </svg>
);

export const SvgItalic = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 7.269 13.671">
    <path id="Tracé_10696" data-name="Tracé 10696" d="M202.2,13.419c-.042.222-.085.448-.128.673a.576.576,0,0,0,.566.682h3.985a.571.571,0,0,0,.565-.467c.044-.229.085-.463.123-.693a.573.573,0,0,0-.541-.667l-.728-.034L207.8,3.06c0-.064,0-.105.064-.105l.78-.04a.568.568,0,0,0,.533-.457c.046-.22.091-.446.135-.669a.575.575,0,0,0-.565-.685H204.8a.571.571,0,0,0-.565.468c-.042.221-.085.448-.127.671a.575.575,0,0,0,.537.681l.624.031-1.725,9.853c-.011.065-.042.1-.105.105l-.7.038a.569.569,0,0,0-.534.468" transform="translate(-202.058 -1.104)" fill="#878787" fillRule="evenodd" />
  </svg>

);

export const SvgUnderline = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14.497 16.489">
    <path id="Tracé_10695" data-name="Tracé 10695" d="M141.872,12.236A6.841,6.841,0,0,0,143.4,7.443l.042-5.621.459-.028a.574.574,0,0,0,.539-.565c0-.215.008-.432.011-.644a.552.552,0,0,0-.165-.413A.555.555,0,0,0,143.88,0h-4.068a.575.575,0,0,0-.575.566c0,.207-.007.417-.01.626a.576.576,0,0,0,.559.584l.885.025c.042.021.082.042.083.083.041,1.694.042,4.192.042,5.886a5.037,5.037,0,0,1-.7,3.04c-.9,1.044-4.866,1.044-5.765,0a5.042,5.042,0,0,1-.7-3.04c0-1.694,0-4.192.041-5.886,0-.042.041-.062.083-.083l.886-.025a.575.575,0,0,0,.558-.584c0-.209-.006-.42-.01-.626A.576.576,0,0,0,134.606,0h-4.068a.556.556,0,0,0-.41.172.555.555,0,0,0-.165.413c0,.213.008.429.01.644a.575.575,0,0,0,.539.566l.459.028.042,5.621a6.853,6.853,0,0,0,1.532,4.793c1.9,1.938,7.424,1.938,9.326,0m-11.319,4.253h13.332a.576.576,0,0,0,.575-.575V15.3a.576.576,0,0,0-.575-.574H130.553a.576.576,0,0,0-.575.574v.61a.576.576,0,0,0,.575.575" transform="translate(-129.963)" fill="#878787" fillRule="evenodd" />
  </svg>

);

export const SvgOrdered = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19.003 17.064">
    <path id="Tracé_10698" data-name="Tracé 10698" d="M84.737,16.2a.428.428,0,0,0,.426-.427v-1.42a.428.428,0,0,0-.426-.426H71.906a.428.428,0,0,0-.426.426v1.42a.428.428,0,0,0,.426.427ZM68.544,0V4.015h-.759V.665L66.742,1.7l-.452-.49L67.586,0Zm.716,9.921v.6H66.227V9.707a1.149,1.149,0,0,1,.228-.842A2.451,2.451,0,0,1,67.538,8.5a2.609,2.609,0,0,0,.839-.23c.087-.07.13-.25.13-.54s-.043-.454-.13-.528a1.094,1.094,0,0,0-.622-.112,1.126,1.126,0,0,0-.638.113c-.089.075-.132.258-.133.548l0,.17h-.743V7.757A1.3,1.3,0,0,1,66.55,6.74a1.985,1.985,0,0,1,1.209-.261,1.918,1.918,0,0,1,1.189.265A1.283,1.283,0,0,1,69.26,7.75a1.343,1.343,0,0,1-.239.936,2.3,2.3,0,0,1-1.1.4A2.855,2.855,0,0,0,67.1,9.3q-.114.091-.114.513v.109Zm-1.824,5.355v-.591h.13a2.214,2.214,0,0,0,.779-.077q.139-.075.139-.429c0-.263-.043-.424-.125-.485A1.376,1.376,0,0,0,67.7,13.6a1.148,1.148,0,0,0-.612.1c-.084.066-.126.232-.126.492l0,.117h-.734v-.149a1.158,1.158,0,0,1,.3-.938A2.01,2.01,0,0,1,67.7,12.99a2.319,2.319,0,0,1,1.21.222.929.929,0,0,1,.33.827q0,.83-.665.934v.02a.764.764,0,0,1,.725.877,1.144,1.144,0,0,1-.321.952,3.283,3.283,0,0,1-2.488,0,1.09,1.09,0,0,1-.327-.923l0-.224h.744c.005.089.008.14.008.159,0,.275.047.448.14.515a1.436,1.436,0,0,0,.7.1,1.2,1.2,0,0,0,.652-.111c.091-.075.136-.254.136-.535a.548.548,0,0,0-.124-.426.9.9,0,0,0-.519-.1h-.464ZM85.164,9.242V7.822a.428.428,0,0,0-.426-.426H71.906a.429.429,0,0,0-.427.426v1.42a.428.428,0,0,0,.426.427H84.737a.428.428,0,0,0,.426-.427m0-6.533V1.288a.428.428,0,0,0-.426-.427H71.906a.429.429,0,0,0-.427.427v1.42a.428.428,0,0,0,.426.426H84.737a.428.428,0,0,0,.426-.426" transform="translate(-66.161)" fill="#878787" fillRule="evenodd" />
  </svg>
);

export const SvgUnordered = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19.438 16.881">
    <path id="Tracé_10697" data-name="Tracé 10697" d="M19.011,16.246a.428.428,0,0,0,.427-.427V14.4a.428.428,0,0,0-.427-.426H6.18a.428.428,0,0,0-.427.426v1.42a.429.429,0,0,0,.427.427Zm-17.1.77A1.907,1.907,0,1,0,0,15.11a1.907,1.907,0,0,0,1.907,1.907m0-6.534A1.907,1.907,0,1,0,0,8.576a1.906,1.906,0,0,0,1.907,1.907m0-6.533A1.907,1.907,0,1,0,0,2.042,1.907,1.907,0,0,0,1.907,3.949M19.438,9.286V7.865a.428.428,0,0,0-.427-.426H6.18a.428.428,0,0,0-.427.426v1.42a.429.429,0,0,0,.427.427H19.011a.428.428,0,0,0,.427-.427m0-6.533V1.332A.428.428,0,0,0,19.011.9H6.18a.429.429,0,0,0-.427.427v1.42a.428.428,0,0,0,.427.426H19.011a.428.428,0,0,0,.427-.426" transform="translate(0 -0.135)" fill="#878787" fillRule="evenodd" />
  </svg>
);

export const SvgClose = () => (
  <svg data-name="Composant 71 – 4" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 40">
    <g data-name="Groupe 8714">
      <path data-name="Tracé 9994" d="m-12233.722 21520-22.282 22.281" transform="translate(12269 -21512)" style={{ fill: 'none', stroke: '#707070', strokeWidth: '3px' }} />
      <path data-name="Tracé 9995" d="m-12233.722 21520-22.282 22.281" transform="rotate(-90 -16866.364 4640.64)" style={{ fill: 'none', stroke: '#707070', strokeWidth: '3px' }} />
    </g>
  </svg>

);

export const SvgLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.484" height="16.487" viewBox="0 0 16.484 16.487">
    <path id="Icon_metro-link" data-name="Icon metro-link" d="M9.994,12.574a.855.855,0,0,1-.606-.251,4.028,4.028,0,0,1,0-5.689l3.166-3.166a4.023,4.023,0,1,1,5.689,5.689L16.8,10.6a.857.857,0,1,1-1.212-1.212L17.03,7.945A2.308,2.308,0,0,0,13.766,4.68L10.6,7.846a2.311,2.311,0,0,0,0,3.264.857.857,0,0,1-.606,1.464Zm-3.038,6.2a4.023,4.023,0,0,1-2.845-6.868l1.447-1.447a.857.857,0,0,1,1.212,1.212L5.325,13.122a2.308,2.308,0,0,0,3.264,3.264l3.166-3.166a2.311,2.311,0,0,0,0-3.264.857.857,0,1,1,1.212-1.212,4.028,4.028,0,0,1,0,5.689L9.8,17.6a4,4,0,0,1-2.845,1.178Z" transform="translate(-2.936 -2.29)" fill="#878787" />
  </svg>
);
