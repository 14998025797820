// context part
import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import Preferences from 'containers/Preferences';
import useAuth from 'hooks/useAuth';
import useDatas from 'hooks/useDatas';
import hnaRequest from 'utils/hnaRequest';

interface PreferencesProps {
  children: ReactNode
  preferencesList: any
}

export type PreferencesContextType = {
  setOpen: any
  open: any
  handlePreferences: any
  preferences: any
  themes: any
}

const initialContext = {
  preferences: null,
  open: false,
  setOpen: () => {},
  handlePreferences: () => {},
  themes: [],
};

export const PreferencesContext = createContext<PreferencesContextType>(initialContext);

const PreferencesProvider = ({ children, preferencesList }: PreferencesProps) => {
  const [preferences, setPreferences] = useState(
    preferencesList?.preferences?.length
      ? preferencesList?.preferences
      : [],
  );

  const { user } = useAuth();
  const [themes, setThemes] = useState(preferencesList?.themes || []);
  const [open, setOpen] = useState(false);
  const { fetchAcademy } = useDatas();

  const { firstConnection } = useAuth();

  useEffect(() => {
    const getPreferences = async () => {
      const prefs = await hnaRequest('get', 'user/preference/list', undefined);
      setThemes(prefs.themes);
      setPreferences(prefs.preferences || []);
    };
    if (user) getPreferences();
  }, [firstConnection]);

  useEffect(() => {
    if (!open) return undefined;
    const getPreferences = async () => {
      const prefs = await hnaRequest('get', 'user/preference/list', undefined);
      fetchAcademy();
      setPreferences(prefs.preferences);
    };
    if (user) getPreferences();
  }, [open]);

  const handlePreferences = (id) => {
    if (!id) return setPreferences([]);
    if (preferences?.find((el) => el === id)) {
      setPreferences(preferences.filter((el) => el !== id));
    } else if (preferences) {
      setPreferences([...preferences, id]);
    } else setPreferences([id]);
    return null;
  };

  return (
    <PreferencesContext.Provider value={{
      setOpen, open, handlePreferences, preferences, themes,
    }}
    >
      {firstConnection
        ? (
          <Preferences
            setOpen={setOpen}
            handlePreferences={handlePreferences}
            preferences={preferences}
            themes={themes}
          />
        ) : children}
      {!firstConnection && open
        && (
        <Preferences
          setOpen={setOpen}
          handlePreferences={handlePreferences}
          preferences={preferences}
          themes={themes}
        />
        )}
    </PreferencesContext.Provider>
  );
};

export default PreferencesProvider;
