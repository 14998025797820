/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const ButtonBack = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1A1A1A 0% 0% no-repeat padding-box;
  border-radius: 31px;
  border: none;
  outline: none;
  width: 7.5vw;
  height: 5.648vh;
  cursor: pointer;
  z-index: 5;
`;

const backText = css`
  font-size: var(--size-16);
  color: var(--color-white);
  margin-left: 0.46875vw;
  display: inline-flex;
  align-items: center;
  line-height: 1.29;
`;

export default {
  ButtonBack,
  backText,
};
