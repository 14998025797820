import { useEffect, useState } from 'react';
import TrainingDetail from 'components/TrainingDetail';
import SideBarFormation from 'components/SideBarFormation';
import { SvgClose, SvgLogoSmallLoader } from 'components/Svgs';
import Error from 'pages/_error';
import { loader, Modal } from 'styles/common';
import SC from './style';
import useHandleKeyPress from 'hooks/useHandleKeyPress';
import hnaRequest from 'utils/hnaRequest';
import EgnosiaPopUp from 'components/EgnosiaPopUp';

export interface TrainingProps {
  handleClose: any
  data?: any
  loading?: any
}

const Training: React.FC<TrainingProps> = ({ handleClose, data, loading }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [redirectEgnosia, setRedirectEgnosia] = useState('');

  useHandleKeyPress('Escape', handleClose); // escapeKey

  useEffect(() => {
    const getSuggestions = async () => {
      const suggest = await hnaRequest('get', `training/suggestions-get-list-by-id/${data.id}`, undefined);
      setSuggestions([
        ...suggest.articles.sort(() => 0.5 - Math.random()).slice(0, 1),
        ...suggest.trainings.sort(() => 0.5 - Math.random()).slice(0, 2),
      ]);
    };
    if (data?.id) getSuggestions();
  }, [data?.id]);

  if (data?.error) return <Error statusCode={data.error} />;

  return (
    <Modal>

      {redirectEgnosia && <EgnosiaPopUp url={redirectEgnosia} onClose={() => setRedirectEgnosia('')} />}

      {loading && <div css={loader}><SvgLogoSmallLoader /></div>}

      {!loading && data && (
        <SC.Training className="scrollbar" itemScope itemType="https://schema.org/Article">
          <div tabIndex={0} role="link" css={SC.close} onClick={handleClose} onKeyPress={handleClose}>
            <SvgClose />
          </div>

          <SideBarFormation data={data} setRedirectEgnosia={setRedirectEgnosia} />
          <div className="scrollbar" css={SC.overflow}>
            <TrainingDetail data={data} suggestions={suggestions} />
          </div>
        </SC.Training>
      )}
    </Modal>
  );
};

export default Training;
