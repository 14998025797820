import React, { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { PopupButton } from '@typeform/embed-react';
import SC from './style';
import { SvgHalfStar, SvgLogoSmallLoader, SvgStar } from 'components/Svgs';
import useAuth from 'hooks/useAuth';
import useCart from 'hooks/useCart';
import hnaRequest from 'utils/hnaRequest';
import InfosBulleTraining from 'components/InfosBulleTraining';
import CardCoach from 'components/CardCoach';
import useDatas from 'hooks/useDatas';
import { openSubscribtion } from '../../utils/link';

export interface SideBarFormationProps {
  data?: any;
  setRedirectEgnosia: any;
}

const SideBarFormation: React.FC<SideBarFormationProps> = ({
  data,
  setRedirectEgnosia,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardCoach, setCardCoach] = useState(null);
  const {
    user,
    coaches,
    isSubscribed
  } = useAuth();

  const router = useRouter();
  const {
    handleList,
    setNewItemAdded,
    list,
  } = useCart();

  const { baughtTraining } = useDatas();

  // console.log({ list });
  const { push } = useRouter();
  const handleClickCart = async (trainingId) => {
    setLoading(true);
    const postList = await hnaRequest(
      'post',
      'purchase/cart-add',
      {
        trainingId,
        vendorId: localStorage.getItem('vendorId') || null
      },
    );
    handleList(postList);
    setNewItemAdded(true);
    push({
      query: {
        home: ['formations'],
      },
    }, undefined, { shallow: true });
    setLoading(false);
  };

  function PopupTypeform({ children }) {
    let typeformId = data.vendor;
    if (data.presale) {
      typeformId = data.presale;
    }
    return (
      <PopupButton
        id={typeformId}
        tracking={{ email: user?.email, firstname: user?.firstName, name: user?.lastName }}
      >
        {children}
      </PopupButton>
    );
  }

  const alreadyInCart = list?.items.find((el) => el.id === data.id);

  const hasBuyed = data?.programId;

  /**
   When External => display a proxy typeform modal which leads to final page
   When presale => means the training has not started and we display information waiting page with typeform modal
   */
  const ConditionalTypeFormPopup = data.vendorType === 'EXTERNAL' || data.presale ? PopupTypeform : React.Fragment;


  const handleClick = async () => {
    // XAPI

    if (data.vendorType === 'EXTERNAL' || data.presale) {
      return;
    }

    if (data.vendorType === 'HNA' && data.vendorSubtype === 'XAPI') {
      const url = `https://${process.env.EBP_URL}/games/shell/launch?programId=${data.vendor?.hnpProgramId}&ticket=${user?.happyNeuronPro?.ticket}`;
      window.open(url);
    }
    // EGNOSIA
    if (data.vendorType === 'EGNOSIA') {
      const salePrice = baughtTraining?.find(({ id }) => id === data.id)?.programSalePrice || null;
      const url = `https://${process.env.EGNOSIA_URL}/happyneuron?nom=${user.lastName}&prenom=${user.firstName}&id_formation=${data?.vendor?.egnosiaId}&token=${process.env.EGNOSIA_TOKEN}&salePrice=${salePrice}&id=${user?.happyNeuronProId}`;
      setRedirectEgnosia(url);
    }
  };

  const isAccessibleForSubOnly = data?.pricing === 'FREE_FOR_SUSCRIBERS';

  return (
    <SC.SideBarFormation className="scrollbar">
      {!user && (
        <div css={SC.logo}>
          <Image src="/Logo.svg" alt="connect-logo" fill sizes="100vw"/>
        </div>
      )}
      <p css={SC.contentType}>
        {data.displayType}
      </p>
      <p css={SC.tagMain}>
        {data.themes?.[0]?.name}
      </p>

      <h1 itemProp="headline" css={SC.title}>{data.title}</h1>
      <p css={SC.themes}>
        {data.themes?.map((el) => (
          <span key={el.id}>
#
            {el.name}
            {' '}
  </span>
        ))}
      </p>

      {data?.coaches?.length ? (
        <>
          <p css={SC.formateur}>
            {data?.coaches?.length > 1 ? 'Formateurs' : 'Formateur'}
            :
          </p>
          {data.coaches?.map((coach) => {
            const item = coaches?.find((el) => el.id === coach.id);
            return (
              <div
                css={SC.relative}
                key={coach.id}
                onMouseEnter={() => setCardCoach(item)}
                onMouseLeave={() => setCardCoach(null)}
                role="textbox"
                tabIndex={0}
              >
                <p css={SC.formateurName}>{coach.fullName}</p>
                {cardCoach?.id === coach.id && (
                  <div css={SC.hoverCoach}>
                    <CardCoach item={cardCoach}/>
                  </div>
                )}
              </div>
            );
          })}

        </>
      ) : null}

      {data?.vendorType === 'EGNOSIA'
        && <p css={SC.egnosia}>E-gnosia Formations</p>}

      {data?.rating
        && (
          <>
            <div css={SC.votes}>
              {[1, 2, 3, 4, 5].map((el) => {
                const num = Math.trunc((data?.rating?.totalRating / data?.rating?.totalTimes) * 2) / 2;
                if (num === el - 0.5) {
                  return (
                    <div key={el} css={SC.starSvg}>
                      <SvgHalfStar/>
                    </div>
                  );
                }
                return (
                  <div key={el} css={SC.starSvg}>
                    <SvgStar
                      hovered={num >= el}
                    />
                  </div>
                );
              })}
            </div>
            <p css={SC.voteNbr}>
              {(data?.rating?.totalRating / data?.rating?.totalTimes).toFixed(1)}
              {' '}
              sur
              {' '}
              {data?.rating?.totalTimes}
              {' '}
              {data?.rating?.totalTimes < 2 ? 'vote' : 'votes'}
            </p>
          </>
        )}

      <InfosBulleTraining data={data}/>

      <br/>

      {hasBuyed ? (
        <ConditionalTypeFormPopup>
          <SC.SessionBlock
            available
            type="button"
            onClick={handleClick}
          >
            <p css={SC.accessReplays}>Démarrer ma formation</p>
          </SC.SessionBlock>
        </ConditionalTypeFormPopup>
      ) : null }


      {!hasBuyed && isAccessibleForSubOnly && !isSubscribed && (
        <button
          type="button"
          role="link"
          onClick={() => openSubscribtion()}
          css={SC.button}
        >
          Je m'abonne
        </button>
      )}

      {(!hasBuyed && !isAccessibleForSubOnly && !alreadyInCart) ? (
        <button
          disabled={!user}
          type="button"
          role="link"
          onClick={() => handleClickCart(data.id)}
          css={user ? SC.button : SC.buttonDisabled}
        >
          {loading ? <div css={SC.loader}><SvgLogoSmallLoader/></div> : 'Ajouter au panier'}
        </button>
      ) : null}

      {alreadyInCart && <p css={SC.connectedTxt}>Cette formation est déjà dans votre panier</p>}

    </SC.SideBarFormation>
  );
};

export default SideBarFormation;
