import { css } from 'styled-components';

const comeFrom = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 110px;
  /* height: 12vh; */
  background: linear-gradient(94deg,var(--color-light-royal-blue) 2%,var(--color-deep-sky-blue) 43%,var(--color-greenish-cyan) 99%);
  padding: 1vh 3.02vw;
`;

const fromTitle = css`
  display: flex;
  margin: 0 0 var(--height-xxs) 0;
`;

const fromTitleTxt = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-21);
  color: white;
  margin-left: 0.57vw;

`;

const fromTxt = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-16);
  color: white;
  line-height: 1.3;
`;

const fontBold = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
`;

export default {
  comeFrom,
  fromTitle,
  fromTitleTxt,
  fromTxt,
  fontBold,
};
