import { css } from 'styled-components';

const item = css`
  width: 20.26vw;
  min-height: 45.5vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px #00000029;
  border-radius: 13px;
  padding: 1.11vh 1.198vw;
  margin-right: 2.96875vw;
  margin-bottom: 2.96875vw;
  position: relative;
`;

const name = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.354vw;
`;

const firstname = css`
  text-align: left;
  font-family: var(--font-family-sans);
  font-size: var(--size-28);
  color: #575757;
  line-height: 1.18;
  text-transform: capitalize;
`;

const lastname = css`
  text-align: left;
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-28);
  color: #575757;
  line-height: 1.18;
  text-transform: capitalize;
`;

const expertises = css`
  text-align: left;
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-20);
  letter-spacing: 0px;
  color: #575757;
  margin-top: 1.421vh;
  margin-bottom: 1.2vh;
  line-height: 1.25;
  `;

const expBlock = css`
  /* min-height: 8.5vh; */
`;

const expertisesList = css`
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 1.2vh;
`;

const skill = css`
  background: #5833FF;
  margin-right: 0.4828125vw;
  padding: 0 0.73vw;
  border-radius: 15px;
  margin-bottom: 0.577vh;

  p {
    font-size: var(--size-14);
    color: white;
    height: 2.314vh;
    display: inline-flex;
    align-items: center;
  }
`;

const image = css`
  position: relative;
  width: 5.2vw;
  height: 5.2vw;
  img {
    border-radius: 50%;
  }
`;

const since = css`
  text-align: left;
  font-family: var(--font-family-sans);
  font-size: var(--size-20);
  color: #575757;
  line-height: 1.1;
  margin-bottom: 0.5vh;
  b {
  font-family: var(--font-family-sans);
  font-weight: bold;
  }
`;

const description = css`
  text-align: left;
  font-family: var(--font-family-sans);
  font-size: var(--size-15);
  color: #575757;
  line-height: 1.27;
  overflow-y: auto;
  height: 18vh;
  margin-top: 1vh;
`;

const fading = css`
  height: 4vh;
  background: transparent linear-gradient(180deg,#FFFFFF 0%,#FFFFFFFC 26%,#FFFFFFD6 45%,#FFFFFF00 100%) 0% 0% no-repeat padding-box;
  -webkit-transform: matrix(-1,0,0,-1,0,0);
  -ms-transform: matrix(-1,0,0,-1,0,0);
  transform: matrix(-1,0,0,-1,0,0);
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: calc(20.26vw - (1.198vw * 2));
`;

export default {
  item,
  name,
  firstname,
  lastname,
  expertises,
  expBlock,
  expertisesList,
  skill,
  image,
  since,
  description,
  fading,
};
