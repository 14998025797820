import styled from 'styled-components';

export const Dot = styled.div<any>`
    width: 2vw;
    height: 2vw;
    position: absolute;
    background-color: var(--color-greenish-cyan);
    border-radius: 50%;
    top: -0.7vh;
    right: 0.1vw;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const Nbr = styled.div<any>`
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-16);
  color: var(--color-black);
`;
