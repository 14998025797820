import styled, { css } from 'styled-components';

const selectedBgColor = (home) => {
  if (home === 'formations') return 'var(--color-deep-sky-blue)';
  if (home === 'mon-academy') return 'linear-gradient(to right,var(--color-greenish-cyan),rgba(2,2,193,0.8) 50%,rgba(88,51,255,0.8))';
  return 'var(--color-greenish-cyan)';
};
const selectedColor = (home) => (home === 'informations' ? 'var(--color-black)' : 'var(--color-white)');

const Thematic = styled.ul<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14.22vw;
  max-height: 61.1vh;
  min-height: 12.7vh;
  height: auto;
  border-radius: 14px;
  box-shadow: 0 3px 37px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-left: var(--layout-margin);
  z-index: 5;
  overflow-y: auto;
  position: fixed;
  top: ${({ hasHeadband }) => (hasHeadband ? 'calc(15.3vh + var(--height-xs) + var(--header-height))' : 'calc(9vh + var(--height-xs) + var(--header-height))')};
`;

const ListThemes = styled.li<any>`
  display: inline-flex;
  align-items: center;
  width: 100%;
  list-style: none;
  height: 6.3vh;
  padding: 0 0 0 0.5vw;
  font-family: var(--font-family-sans);
  /* color: var(--color-black-02); */
  cursor: pointer;
 

  ${({ selected, home, isCategory }) => (selected
    ? css`
        background: ${selectedBgColor(home)};
        span, p {
          color: ${selectedColor(home)};
        }
       `
    : css`

        background-color: ${isCategory ? ' whitesmoke' : '#ffffff'};
  `)}

  &:hover {
    background: ${({ selected, home }) => (selected ? selectedBgColor(home) : 'var(--color-white-01)')};
    color: var(--color-black);
  }
`;

const validate = css`
  width: 3vh;
  height: 3vh;
  position: relative;
`;

const SpanName = styled.span<any>`
  font-size: var(--size-15-5);
  color: var(--color-black-02);
  font-weight: ${({ isCategory }) => (isCategory ? 'bold' : 'normal')};;
  /* line-height: 1.19; */
  /* padding-right: 0.6vw; */
`;

const wrapName = css`
  line-height: 0.9;
  width: 9.5vw;
  padding-right: 0.5vw;
  word-break: break-word;
`;

const Fading = styled.div<any>`
  position: fixed;
  width: ${({ scrollWidth }) => `calc(14.22vw - ${scrollWidth}px)`};
  height: 6.7vh;
  top: ${({ hasHeadband }) => (hasHeadband ? 'calc(9.2vh + 4.1666vh + var(--height-xs) + 59.1vh + var(--header-height) - 6.7vh + 4vh)' : 'calc(7vh + 4vh + var(--height-xs) + 59.1vh + var(--header-height) - 6.7vh)')};
  background: transparent linear-gradient(180deg,#FFFFFF00 0%,#FFFFFF 100%) 0% 0% no-repeat padding-box;
  border-radius: 0 0 14px 14px;
  pointer-events: none;
`;

const heart = css`
  width: 1vw;
  margin: auto 0.5vw auto 0;
  height: 100%;
`;

const separation = css`
  width: 1px;
  height: 60%;
  background-color: #E5E5E5;
`;

const ThemeCount = styled.p<any>`
  margin: auto;
  font-size: var(--size-18);
  color: ${({ selected }) => (selected ? '#FFF' : 'var(--color-gray-01)')};
`;

const loader = css`
  width: 4vw;
  margin: auto;
`;

export default {
  Thematic,
  wrapName,
  ListThemes,
  validate,
  SpanName,
  Fading,
  heart,
  separation,
  ThemeCount,
  loader,
};
