import {
  useEffect, useRef,
} from 'react';
import useAuth from 'hooks/useAuth';
import getHumanMinutes from 'utils/getHumanMinutes';
import analyticsRecord from 'utils/analyticsRecord';
import getHumanSeconds from 'utils/getHumanSeconds';
import getHumanHoursWithZero from 'utils/getHumanHoursWithZero';

const initialAnalytics = {
  currSeconds: 0,
  inactiveSeconds: 0,
  totalSeconds: 0,
  scrollMaxPercent: 0,
  contentType: '',
  time: null,
  id: null,
  wordsLen: 0,
  memoirDownloaded: false,
};

const useAnalytics = ({ data }) => {
  const trackingRef = useRef(initialAnalytics);
  const { user } = useAuth();
  const timer = useRef(null);

  const handleUnMount = () => {
    const tracked = {
      timeOnPage: trackingRef?.current?.totalSeconds,
      timeInactive: trackingRef?.current?.inactiveSeconds,
      scrollMaxPercent: trackingRef?.current?.scrollMaxPercent,
      cognitoId: user?.cognitoId || null,
      contentType: trackingRef?.current?.contentType,
      id: trackingRef?.current?.id,
      time: trackingRef?.current?.time,
      wordsLen: trackingRef?.current?.wordsLen,
      userId: user?.id || null,
      memoirDownloaded: trackingRef?.current?.memoirDownloaded,
    };
    // console.log({ tracked });
    analyticsRecord(tracked);
  };

  const startIdleTimer = () => {
    trackingRef.current.currSeconds += 1;
  };
  const startIdleTotalTimer = () => {
    trackingRef.current.totalSeconds += 1;
  };

  const resetTimer = (scrollMaxPercent = 0) => {
    const formatDate = (currentDatetime) => {
      const formattedDate = `${currentDatetime.getFullYear()}-${currentDatetime.getMonth() + 1}-${currentDatetime.getDate()} ${getHumanHoursWithZero(currentDatetime)}:${getHumanMinutes(currentDatetime)}:${getHumanSeconds(currentDatetime)}`;
      return formattedDate;
    };

    const wordsLen = data?.html?.replace(/<[^>]*>/g, '')?.split(' ').length;
    trackingRef.current = {
      ...trackingRef.current,
      inactiveSeconds: trackingRef.current.currSeconds + trackingRef.current.inactiveSeconds,
      currSeconds: 0,
      scrollMaxPercent: scrollMaxPercent > trackingRef.current.scrollMaxPercent ? scrollMaxPercent : trackingRef.current.scrollMaxPercent,
      contentType: data?.contentType,
      id: data?.id,
      time: formatDate(new Date()),
      wordsLen,
    };
    clearInterval(timer.current);
    timer.current = setInterval(startIdleTimer, 1000);
  };

  useEffect(() => {
    if (!data) return undefined;
    const totalTimer = setInterval(startIdleTotalTimer, 1000);
    resetTimer();
    return () => {
      resetTimer();
      clearInterval(totalTimer);
      clearInterval(timer.current);
    };
  }, [data]);

  const onMouseMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetTimer();
  };

  const onScrollMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const currentScroll = ((e.target.scrollTop + e.target.clientHeight) * 100) / e.target.scrollHeight;
    resetTimer(Math.trunc(currentScroll));
  };

  const handleTracked = (name, value) => {
    trackingRef.current[name] = value;
  };

  return {
    onScrollMove,
    onMouseMove,
    handleTracked,
    handleUnMount,
  };
};

export default useAnalytics;
