/* eslint-disable max-len */
import Link from 'next/link';
import Image from 'next/image';
import SC from './style';
import CheckSvg from '../../../public/check-violet.svg';
import { getSubscriptionLink } from '../../utils/link';

export interface PremiumAccessProps {
  user: any
}

const PremiumAccess: React.FC<PremiumAccessProps> = ({ user }) => (
  <div css={SC.premium}>
    <div css={SC.fading} />
    <div css={SC.cadenas}>
      <div css={SC.cadenasIcon}>
        <Image src="/cadenas-close.svg" alt="cadenas" fill sizes="100vw" />
      </div>
    </div>
    <p css={SC.readNext}>Cet article est réservé aux abonnés Happyneuron </p>
    <p css={SC.readNext}>
      Pour lire les 80% restants,
      {' '}
      <b>abonnez vous !</b>
    </p>
    <div css={SC.advantages}>
      <div css={SC.premiumBlockFree}>
        <p css={SC.freeAccessTitle}>ACCÈS GRATUIT</p>
        <div css={SC.image}>
          <Image src="/logo-HNA-small.svg" alt="logo" fill sizes="100vw" />
        </div>
        <ul css={SC.premiumUl}>
          <li css={SC.premiumItemFree}>
            <div css={SC.checkIcon}>
              <Image
                height={30}
                width={30}
                src={CheckSvg}
                alt="check"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <p css={SC.itemTextFree}>Accès illimité dans le temps à Happyneuron</p>
          </li>
          <li css={SC.premiumItemFree}>
            <div css={SC.checkIcon}>
              <Image src={CheckSvg} alt="check" fill sizes="100vw" />
            </div>
            <p css={SC.itemTextFree}>Possibilité de s&apos;inscrire à n&apos;importe quelle formation</p>
          </li>
          <li css={SC.premiumItemFree}>
            <div css={SC.checkIcon}>
              <Image src={CheckSvg} alt="check" fill sizes="100vw" />
            </div>
            <p css={SC.itemTextFree}>Accès aux articles ouverts à tous</p>
          </li>
          <li css={SC.premiumItemFree}>
            <div css={SC.checkIcon}>
              <Image src="/cross-disabled.svg" alt="cross disabled" fill sizes="100vw" />
            </div>
            <p css={SC.itemTextFreeDisab}>Accès aux contenus premium</p>
          </li>

        </ul>
        {!user && (
        <>

          <Link href="/connect/login" css={SC.buttonSignup}>JE M&apos;INSCRIS GRATUITEMENT</Link>
          <p css={SC.savoirPlus}>
            {' '}
            <a href="https://accueil.happyneuron.academy/" target="_blank" rel="noreferrer">En savoir plus</a>
          </p>
        </>
        )}
      </div>
      <div css={SC.premiumBlock}>

        <p css={SC.premiumAccessTitle}>EN DEVENANT Abonné</p>
        <div css={SC.image}>
          <Image src="/logo-HNA-small-white.png" alt="premium" fill sizes="100vw" />
        </div>
        <ul css={SC.premiumUl}>
          <li css={SC.premiumItem}>
            <div css={SC.checkIcon}>
              <Image
                height={30}
                width={30}
                src="/check-white.svg"
                alt="check white"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <p css={SC.itemText}>Accès illimité dans le temps à Happyneuron</p>
          </li>
          <li css={SC.premiumItem}>
            <div css={SC.checkIcon}>
              <Image
                height={30}
                width={30}
                src="/check-white.svg"
                alt="check white"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <p css={SC.itemText}>Possibilité de s&apos;inscrire à n&apos;importe quelle formation</p>
          </li>
          <li css={SC.premiumItem}>
            <div css={SC.checkIcon}>
              <Image
                height={30}
                width={30}
                src="/check-white.svg"
                alt="check white"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <p css={SC.itemText}>Accès aux articles ouverts à tous</p>
          </li>
          <li css={SC.premiumItem}>
            <div css={SC.checkIcon}>
              <Image
                height={30}
                width={30}
                src="/check-white.svg"
                alt="check white"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <p css={SC.itemText}>Accès aux contenus premium</p>
          </li>
        </ul>
        <Link href={getSubscriptionLink()} target="_blank" css={SC.buttonSubscrib}>
          Je m&apos;abonne
        </Link>
        <p css={SC.reserved}>
          Réservé uniquement
          <br />
          {' '}
          aux orthophonistes.
        </p>
      </div>
    </div>
  </div>

);

export default PremiumAccess;
