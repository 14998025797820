import Image from 'next/image';
import { useState } from 'react';
import { Checkbox } from 'antd';
import { SvgStar } from 'components/Svgs';
import SvgLogo from '../../../public/Logo.svg';
import SC from './style';
import useGiveRating from 'hooks/useGiveRating';
import hnaRequest from 'utils/hnaRequest';

interface GiveRatingProps {

}

const rates = [1, 2, 3, 4, 5];

const GiveRating: React.FC<GiveRatingProps> = () => {
  const [currentHover, setCurrentHover] = useState(5);
  const [currentSelected, setCurrentSelected] = useState(5);
  const [recommended, setRecommended] = useState(true);
  const { giveRating, nextGiveRating } = useGiveRating();

  const handleHover = (rate) => {
    setCurrentHover(rate);
  };

  const handleRating = async () => {
    await hnaRequest('post', 'training/give-rating', { id: giveRating?.id, rating: currentSelected, recommend: recommended });
    nextGiveRating();
  };

  return (
    <>
      <div css={SC.logo}>
        <Image src={SvgLogo} alt="subscription" fill sizes="100vw" />
      </div>
      <p css={SC.title}>
        Félicitations !
        <br />
        Vous avez terminé la formation
        <br />
        {giveRating?.title}
      </p>

      <p css={SC.description}>Avant d&apos;accéder à l&apos;Academy, dans une optique d&apos;amélioration continue en lien avec les engagements Qualiopi, merci de répondre à ces deux questions :</p>

      <p css={SC.answer}>
        Quel est votre niveau de satisfaction suite à votre formation
        {' '}
        {giveRating?.title}
        {' '}
        ?
      </p>

      <div css={SC.rates}>
        {rates.map((rate) => (
          <div key={rate} css={SC.starSvg}>
            <SvgStar
              onMouseEnter={() => handleHover(rate)}
              onMouseLeave={() => handleHover(null)}
              onClick={() => (rate === currentSelected ? setCurrentSelected(0) : setCurrentSelected(rate))}
              hovered={currentHover ? rate <= currentHover : rate <= currentSelected}
            />
          </div>
        ))}
      </div>
      <p css={SC.reco}>
        Recommanderiez-vous cette formation à vos consoeurs et confrères ?
      </p>
      <div css={SC.checkboxs}>
        <Checkbox checked={recommended} onChange={() => setRecommended(true)}>Oui, je recommande cette formation</Checkbox>
        <Checkbox checked={!recommended} onChange={() => setRecommended(false)}>Non, je ne la recommande pas</Checkbox>
      </div>
      <button css={SC.accessButton} type="button" onClick={handleRating}>
        <span>Accéder à l&apos;Academy</span>
      </button>
    </>
  );
};

export default GiveRating;
