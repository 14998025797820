import Image from 'next/image';
import SC from './style';
import ImgLogo from '../../../../public/connect-logo.png';
import ImgBg from '../../../../public/connect-left-background.png';

const AutoLoginLayout = ({ children, mobile }) => (
  <SC.DivAutoLoginLayout>
    {!mobile && (
    <SC.DivLeftSection>
      <SC.ImgLogo>
        <Image src={ImgLogo} alt="connect-logo" fill sizes="100vw" />
      </SC.ImgLogo>
      <SC.ImgBg>
        <Image
          src={ImgBg}
          alt="connect-left-bg"
          fill
          sizes="100vw"
          style={{
            objectPosition: 'left top',
          }}
        />
      </SC.ImgBg>
    </SC.DivLeftSection>
    )}

    <SC.DivRightSection mobile={mobile}>
      {children}
    </SC.DivRightSection>
  </SC.DivAutoLoginLayout>
);

export default AutoLoginLayout;
