import Image from 'next/image';
import Modal from 'components/Modal';
import SC from './style';
import Button from 'components/Button';
import { SvgClose } from 'components/Svgs';

interface EgnosiaPopUpProps {
  url: string
  onClose: any
}

const EgnosiaPopUp: React.FC<EgnosiaPopUpProps> = ({ url, onClose }) => (
  <Modal onClose={onClose}>
    <SC.DivEgnosia>
      <p css={SC.title}>Vous allez être redirigé sur E-gnosia. fr</p>

      <div css={SC.imgVersus}>
        <div css={SC.image}>
          <Image src="/Logo.svg" alt="HNA x EGNOSIA" fill sizes="100vw" />
        </div>
        <div css={SC.image1}>
          <SvgClose />
        </div>
        <div css={SC.image2}>
          <Image src="/logo-egnosia.png" alt="HNA x EGNOSIA" fill sizes="100vw" />
        </div>
      </div>

      <p css={SC.description}>
        Cette formation est proposée par
        {' '}
        <b>e-Gnosia</b>
        {' '}
        en partenariat avec
        {' '}
        <b>Happyneuron Academy</b>
        , la suite de votre parcours se déroulera donc sur
        {' '}
        <b>e-gnosia.fr</b>
        .
        <br />
        <br />
        À tout moment, vous pourrez retrouver votre formation
        {' '}
        <b>e-Gnosia</b>
        {' '}
        sur
        {' '}
        <b>Happyneuron Academy</b>
        {' '}
        dans la section "Mes formations" de l'onglet "Se former".
      </p>

      <Button value="Accéder à ma formation" onClick={() => window.open(url)} />
    </SC.DivEgnosia>
  </Modal>
);

export default EgnosiaPopUp;
