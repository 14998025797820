import Image from 'next/image';
import { useRouter } from 'next/router';
import SC from './style';
import usePreferences from 'hooks/usePreferences';
import useAuth from 'hooks/useAuth';
import { isUserSubscribed } from 'utils/auth';
import useCart from 'hooks/useCart';
import hnaRequest from 'utils/hnaRequest';
import { getPermanentUrl } from 'utils/url';
import Cart from 'components/Cart';
import Profile from 'components/Profile';
import { getSubscriptionLink, openSubscribtion } from '../../utils/link';

export interface DropdownMenuProps {
  openDropdown: any,
  setOpenDropdown: any,
  setCurrentStep: (step: number) => void
  setOpenModal: (name: string) => void

}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  openDropdown, setOpenDropdown,
  setCurrentStep, setOpenModal,
}) => {
  const { setOpen: setOpenPreferences } = usePreferences();
  const { logout, user } = useAuth();
  const {
    list, handleList, totals, setNewItemAdded,
  } = useCart();
  const router = useRouter();

  const subscribed = isUserSubscribed(user);

  const handleProfile = (e, name) => {
    e.preventDefault();
    if (openDropdown) return;
    setOpenDropdown(name);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpenDropdown(null);
    setNewItemAdded(false);
  };

  const removeItem = async (e, trainingId) => {
    e.preventDefault();
    const delList = await hnaRequest('del', 'purchase/cart-remove', { trainingId });
    handleList(delList.cart);
  };

  const handlePaid = async () => {
    // TODO change with token from HNP
    // await hnaRequest('post', 'purchase/cart-paid', { transactionId: 'token' });
    // handleList(null);
    // refetch();
    setCurrentStep(1);
    router.push('/paiement', undefined, { shallow: true });
    setOpenDropdown(null);
    setNewItemAdded(false);
  };

  const handleSubscription = async () => {
    // TODO change with token from HNP
    setCurrentStep(1);
    openSubscribtion();
    setOpenDropdown(null);
    setNewItemAdded(false);
  };

  // console.log(list?.items, newOffersList, numberTrainingsOffers);
  return (
    <>
      {openDropdown && (
      <div
        css={SC.background}
        onKeyPress={handleClose}
        onClick={handleClose}
        role="presentation"
        className="bg-close"
      />
      )}
      <SC.ButtonProfile
        onClick={(e) => handleProfile(e, 'profile')}
        onKeyPress={(e) => handleProfile(e, 'profile')}
        suscribed={subscribed}
      >

        {user?.image ? (
          <div css={SC.profileImage}>
            <Image
              css={SC.profileImage}
              src={getPermanentUrl(user.image, '-67x67')}
              alt="profile"
              fill
              sizes="100vw"
            />
          </div>
        ) : (
          <p css={SC.acrony}>
            {user?.firstName?.slice(0, 1)}
            {user?.lastName?.slice(0, 1)}
          </p>
        )}
        {openDropdown && (
        <>
          {openDropdown === 'profile' && (
          <Profile
            logout={logout}
            setOpenPreferences={setOpenPreferences}
            setOpenDropdown={setOpenDropdown}
            handleSubscription={handleSubscription}
            user={user}
            subscribed={subscribed}
            setOpenModal={setOpenModal}
          />
          )}

          {openDropdown === 'cart' && (
          <Cart
            items={list?.items}
            totals={totals}
            removeItem={removeItem}
            handlePaid={handlePaid}
            user={user}
          />
          )}
        </>
        )}

      </SC.ButtonProfile>
    </>
  );
};

export default DropdownMenu;
