import { GetServerSideProps } from 'next';
import Head from 'next/head';
import router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { withSSRContext } from 'aws-amplify';
import { CSSTransition } from 'react-transition-group';
import Header from 'containers/Header';
import Home from 'containers/Home';
import PreferencesProvider from 'providers/Preferences';
import ThemesFiltersProvider from 'providers/ThemesFilters';
import useHistory from 'hooks/useHistory';
import CartProvider from 'providers/Cart';
import DatasProvider from 'providers/Datas';
import useAuth from 'hooks/useAuth';
import Content from 'containers/Content';
import Training from 'containers/Training';
import fetchingDatas from 'utils/fetchingDatas';
import {
  getTitle, getTitleOG, getMeta, getOgImage,
} from 'utils/getMetas';
import useGetData from 'hooks/useGetData';
import DynamicRoutes from '../dynamic';
import GiveRatingProvider from 'providers/GiveRating';
import QuiSommesNous from 'containers/QuiSommesNous';
import Tuto from 'components/Tuto';
import Cga from 'components/Cga';
import LaunchOfferProvider from 'providers/LaunchOffer';
import LoginWithToken from 'containers/Login/LoginWithToken';
import AutoLoginLayout from 'components/Layout/Connect/AutoLogin';
import getIdsFromUrl from 'utils/getIdsFromUrl';
import useVideo from 'hooks/useVideo';
import Video from 'components/Video';
import Products from 'containers/Products';
import Vendor from 'components/Vendor';
import VideoProvider from 'providers/Video';
import getMetaDescription from 'utils/getMetaDescription';
import ModalPage from 'components/ModalPage';
import ExchangesProvider from 'providers/Exchanges';

const {
  HeaderPayment,
  InfosPerso,
  Commandes,
  Payment,
  Search,
} = DynamicRoutes;

const HomePage = ({
  contentList, preferencesList, trainingList, content, setOpenModal, baughtTraining,
  selectedForYou, showcaseList, topContents, topTrainings, premiumList, events,
  training, mobile, host,
}) => {
  const { user, isSubscribed, vendorId } = useAuth();
  const { push } = useRouter();
  const {
    query, pathname, home, prevUrl,
  } = useHistory();
  const { video, setFixedVideo, fixedVideo } = useVideo();

  const id = getIdsFromUrl(query?.home?.[1]);
  const ogUrl = `${host}${pathname}`;

  const [exploreAll, setExploreAll] = useState(query?.all || null);
  const [showSearch, setShowSearch] = useState(!!query?.search && user);
  const [currentStepPayment, setCurrentStepPayment] = useState(1);

  const isFormation = home === 'formations';

  const {
    contentData, trainingData, refetchContent, refetchTraining,
  } = useGetData({
    content, training, id, isFormation, baughtTraining,
  });

  const handleExploreAll = (catalogue) => {
    setExploreAll(catalogue);
  };

  const handleCloseId = () => {
    const url = prevUrl.asPath.includes(pathname)
      ? { query: { home: [router.query.home[0]] } } : prevUrl;
    push({
      ...url,
    }, undefined, { shallow: true });
    if (video.url && !fixedVideo.url) setFixedVideo({ ...video });
  };

  const metaDescription = getMetaDescription({
    isFormation, contentData, trainingData, id,
  });

  const title = getTitle({
    contentData, home, isFormation, refetchContent, refetchTraining, trainingData,
  });

  const handleCloseRedirect = () => {
    const url = prevUrl.asPath.includes(pathname)
      ? { query: { home: ['mon-academy'] } } : prevUrl;
    push({
      ...url,
    }, undefined, { shallow: true });
  };

  useEffect(() => {
    if (query?.all) setExploreAll(query.all);
    else setExploreAll(null);

    if (home === 'abonnement' && isSubscribed) {
      router.push('/mon-academy', undefined, { shallow: true });
    }
  }, [home]);

  useEffect(() => {
    if (!query.search) setShowSearch(false);
  }, [query?.search]);

  const shallowRoutes = ['qualite', 'profil', 'cgu', 'confidentialite',
    'mentions-legales', 'formateurs', 'paiement/confirmation', 'abonnement/confirmation',
    'paiement', 'abonnement', 'commandes', 'genese', 'tuto', 'cga', 'products'];

  const ogImage = getOgImage({
    contentData, home, id, isFormation, trainingData,
  });

  const Metas = getMeta({
    title: getTitleOG({
      contentData, home, isFormation, refetchContent, refetchTraining, trainingData,
    }),
    description: metaDescription,
    url: ogUrl,
    image: ogImage,
  });

  if (query?.token?.length) {
    return (
      <AutoLoginLayout mobile={mobile}>
        <LoginWithToken token={query.token} />
      </AutoLoginLayout>
    );
  }
  return (
    <>
      <ThemesFiltersProvider>
        <CartProvider>
          <DatasProvider
            contents={contentList}
            trainings={trainingList}
            baughtTraining={baughtTraining}
            selectedForYou={selectedForYou}
            showcaseList={showcaseList}
            topContents={topContents}
            topTrainings={topTrainings}
            premiumList={premiumList}
            events={events}
          >
            <VideoProvider>
              <PreferencesProvider preferencesList={preferencesList}>
                <LaunchOfferProvider>
                  <GiveRatingProvider>

                    {vendorId && <Vendor />}

                    <Video
                      autoplay
                      fixed
                    />

                    <CSSTransition
                      in={showSearch || Boolean(query.search)}
                      timeout={0}
                      classNames="unknow"
                      unmountOnExit
                    >
                      <Search handleClose={() => setShowSearch(false)} />
                    </CSSTransition>

                    {id && (typeof window === 'undefined' || prevUrl.asPath === pathname)
                     && !shallowRoutes.includes(home) ? (
                       <>
                         <Head>
                           <title>{title}</title>
                           <meta
                             name="description"
                             content={metaDescription}
                           />
                           {Metas}
                         </Head>
                         {!isFormation && (
                         <Content
                           data={contentData}
                           loading={refetchContent}
                           handleClose={handleCloseId}
                         />
                         )}
                         {isFormation && (
                         <Training
                           data={trainingData}
                           loading={refetchTraining}
                           handleClose={handleCloseId}
                         />
                         )}
                       </>
                      ) : !shallowRoutes.includes(home) && (
                      <>
                        <Head>
                          <title>{title}</title>
                          <meta
                            name="description"
                            content={metaDescription}
                          />
                          {Metas}
                        </Head>
                        <ExchangesProvider>
                          <Header
                            setCurrentStep={setCurrentStepPayment}
                            showSearch={showSearch}
                            handleOpenSearch={() => setShowSearch(true)}
                            setOpenModal={setOpenModal}
                          />
                          <Home
                            exploreAll={exploreAll}
                            handleExploreAll={handleExploreAll}
                            contentData={contentData}
                            trainingData={trainingData}
                            pathname={pathname}
                            query={query}
                          />
                        </ExchangesProvider>

                      </>
                      )}

                    {home === 'products' && <Products handleClose={handleCloseRedirect} />}
                    {home === 'genese' && <QuiSommesNous />}
                    {home === 'profil' && <InfosPerso handleClose={handleCloseRedirect} />}
                    {home === 'tuto' && (
                      <>
                        <Head>
                          <title>{title}</title>
                          <meta
                            name="description"
                            content={metaDescription}
                          />
                          {Metas}
                        </Head>
                        <Tuto
                          handleClose={handleCloseRedirect}
                          handleClickLogo={handleCloseRedirect}
                        />
                      </>
                    )}
                    {home === 'cga' && <Cga handleClose={handleCloseRedirect} handleClickLogo={handleCloseRedirect} />}

                    <ModalPage
                      openModal={home}
                      handleClickLogo={handleCloseRedirect}
                      handleClose={handleCloseRedirect}
                    />

                    {home === 'commandes' && <Commandes handleClose={handleCloseRedirect} />}
                    {['paiement/confirmation', 'abonnement/confirmation', 'paiement', 'abonnement'].includes(home) && (
                    <>
                      <Head>
                        <title>{title}</title>
                        <meta
                          name="description"
                          content={metaDescription}
                        />
                      </Head>
                      <HeaderPayment
                        currentStep={currentStepPayment}
                        setCurrentStep={setCurrentStepPayment}
                      />
                      <Payment
                        currentStep={currentStepPayment}
                        setCurrentStep={setCurrentStepPayment}
                        home={home}
                      />
                    </>
                    )}
                  </GiveRatingProvider>
                </LaunchOfferProvider>
              </PreferencesProvider>
            </VideoProvider>
          </DatasProvider>
        </CartProvider>
      </ThemesFiltersProvider>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { API } = withSSRContext({ req: ctx?.req });
  const home = ctx?.query?.home?.[0] || null;
  const id = getIdsFromUrl(ctx?.query?.home?.[1]);

  const isFormation = home === 'formations';
  const isContent = home === 'informations';
  const forceDisplay = ctx?.query?.isPreview === 'true';

  const host = ctx?.req?.headers?.host;

  // https://nextjs.org/docs/going-to-production
  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=30, stale-while-revalidate=59',
  );

  try {
    const props = await fetchingDatas({
      isContent,
      isFormation,
      id,
      home,
      forceDisplay,
      APISsr: API,
    });
    return { props: { ...props.props, querySSR: ctx?.query, host } };
  } catch (error) {
    console.error('home = ', { error });
    return { props: { querySSR: ctx?.query, host } };
  }
};

export default HomePage;
