import { PopupButton } from '@typeform/embed-react';
import { useState } from 'react';
import { notification } from 'antd';
import {
  SvgClose, SvgLogoSmallLoader, SvgMagicalHat,
} from 'components/Svgs';
import SC from './style';
import useAuth from 'hooks/useAuth';
import useFading from 'hooks/useFading';
import hnaRequest from 'utils/hnaRequest';
import Logo from 'components/Logo';

export interface PreferencesProps {
  setOpen: any
  handlePreferences: any
  preferences: any
  themes: any
}

const Preferences: React.FC<PreferencesProps> = ({
  setOpen, handlePreferences, preferences, themes,
}) => {
  const { firstConnection, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { hasFading, hasFadingTop } = useFading({ elementId: 'preferences' });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await hnaRequest('post', 'user/preference/edit', { preferences });
      const userInfos = await hnaRequest('get', process.env.USER_URL, undefined);
      setUser(userInfos?.user);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error({ error });
      notification.error({
        message: 'Veuillez nous excuser, une erreur est survenue.',
        description: 'Essayez de recharger votre page ou contactez le support si le problème persiste',
      });
      setLoading(false);
    }
  };

  return (
    <div css={SC.preferences}>
      <div css={SC.logo}>
        <Logo margin="0" />
      </div>
      {!firstConnection && (
      <div tabIndex={0} role="link" css={SC.close} onClick={handleClose} onKeyPress={handleClose}>
        <SvgClose />
      </div>
      )}
      <SC.Content>
        <p css={SC.title}>Mes préférences</p>

        <p css={SC.findIt}>
          Vous retrouverez ces thématiques dans votre profil. Vous pouvez modifier ces préférences quand vous le souhaitez.
        </p>
        <div css={SC.divInterest}>
          <p css={SC.interest}>Quelles thématiques vous intéressent ?</p>
          <SC.Button css={SC.flexCenter} selected={!preferences || preferences?.length === 0} type="button" onClick={() => handlePreferences(null)}>
            <span>Tout m'intéresse</span>
            <SvgMagicalHat selected={!preferences || preferences?.length === 0} />
          </SC.Button>
          <p css={SC.proposeThemes}>
            Vous vous intéressez à un sujet qui n'est pas dans cette liste ?
            <br />
            Proposez-nous votre thématique en remplissant ce
            {' '}
            <PopupButton id="mEnakwxx" css={SC.linkForm}>
              formulaire
            </PopupButton>
            .
          </p>
        </div>

        <div css={SC.fadingWrap}>
          <div css={SC.grid} id="preferences" className="scrollbar">
            {hasFadingTop && <div css={SC.fadingTop} />}
            {themes?.map((el) => (
              <SC.Button selected={preferences?.includes(el.id)} key={el.id} type="button" onClick={() => handlePreferences(el.id)}>
                <span>{el.name}</span>
              </SC.Button>
            ))}
            {hasFading && <div css={SC.fading} />}
          </div>
        </div>

        <div css={SC.buttons}>
          <button type="button" role="link" onClick={!loading && handleSubmit} css={SC.valided}>{loading ? <div css={SC.loader}><SvgLogoSmallLoader /></div> : 'Sauvegarder'}</button>
          {firstConnection && <button type="button" role="link" onClick={handleSubmit} css={SC.passed}>Passer cette étape</button>}
        </div>
      </SC.Content>

    </div>
  );
};

export default Preferences;
