import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import useVideo from 'hooks/useVideo';
import SC from './style';
import { SvgArrowRight, SvgCloseBig, SvgLaunchVideo } from 'components/Svgs';
import { DivFlex, pointer } from 'styles/common';

interface VideoProps {
  videoLink?: string
  fixed?: any
  autoplay?: boolean
}

const Video: React.FC<VideoProps> = ({
  videoLink, fixed, autoplay = false,
}) => {
  const {
    setVideo, setFixedVideo, fixedVideo, video, nextVideo, prevVideo,
  } = useVideo();
  const ref = useRef();
  const router = useRouter();
  const player = ref?.current || null;

  const link = videoLink || fixedVideo?.url;

  const handleOn = (e) => {
    if (e) {
      setVideo({
        url: link,
        asPath: router.asPath,
        currentTime: player?.getCurrentTime(),
      });
    } else {
      setVideo({
        url: false,
        asPath: null,
        currentTime: 0,
      });
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setVideo({
      url: null,
      asPath: null,
      currentTime: 0,
    });
    setFixedVideo({
      url: null,
      asPath: null,
      currentTime: 0,
    });
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    router.push(fixedVideo.asPath, undefined, { shallow: true });
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    if (!prevVideo?.url) return;
    setFixedVideo(prevVideo);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    if (!nextVideo?.url) return;
    setFixedVideo(nextVideo);
  };

  useEffect(() => {
    if (fixed) return;
    if (player?.getCurrentTime()) {
      setVideo((pS) => ({
        ...pS,
        currentTime: player?.getCurrentTime(),
      }));
    }
    const interval = setInterval(() => {
      if (player?.getCurrentTime()) {
        setVideo((pS) => ({
          ...pS,
          currentTime: player?.getCurrentTime(),
        }));
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [video.url]);

  if (!link) return null;

  return (
    <SC.VideoFixed fixed={fixed}>
      {fixed && (
        <DivFlex css={pointer} tabIndex={0} role="link" onClick={handleOpen} onKeyPress={handleOpen} bgColor="var(--color-black)">
          <div css={SC.doubleArrow}>
            <div css={SC.buttonVideo} title="Ouvrir la page du contenu">
              <SvgLaunchVideo />
            </div>
          </div>

          <div title="Épisode précédent" tabIndex={0} role="link" onClick={handlePrev} onKeyPress={handlePrev} css={[SC.buttonVideoR]}>
            <SvgArrowRight disabled={!prevVideo?.url} />
          </div>

          <div
            title="Épisode suivant"
            tabIndex={0}
            role="link"
            onClick={handleNext}
            onKeyPress={handleNext}
            css={[SC.buttonVideo]}
          >
            <SvgArrowRight disabled={!nextVideo?.url} />
          </div>

          <div title="Fermer le lecteur" tabIndex={0} role="link" css={SC.close} onClick={handleClose} onKeyPress={handleClose}>
            <div css={SC.buttonVideo}>
              <SvgCloseBig />
            </div>
          </div>
        </DivFlex>
      )}
      <SC.DivReactPlayer
        onReady={() => player?.seekTo(fixedVideo.currentTime || player.getCurrentTime() || 0, 'seconds')}
        ref={ref}
        url={link}
        onPlay={() => handleOn(true)}
        onPause={() => handleOn(false)}
        width="100%"
        height="0"
        config={{
          vimeo: {
            playerOptions: {
              id: link,
              controls: true,
              autoplay,
            },
          },
        }}

      />
    </SC.VideoFixed>
  );
};

export default Video;
