import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';

const Button = styled(AntButton)`
	align-self: center;
	background-color: var(--color-black);
	border-color: none;
	font-size: var(--size-26);
	font-weight: 500;
	min-height: 65px;
	max-height: 6.66vh;
	margin-top: 24px;
	margin-bottom: 5px;
	width: 70%;
`;

const Title = styled.h1`
	font-family: var(--font-family-serif);
	font-size: var(--size-48);
	margin-top: 4.5%;
	margin-bottom: 0;
  color: var(--color-gray);
`;

const SubTitle = styled.h2`
	font-family: var(--font-family-serif);
	font-size: var(--size-24);
	font-weight: normal;
	margin: 2vh auto;
	max-width: 65%;
	text-align: center;
	line-height: 1.3;
  	color: var(--color-gray);
	/* min-height: 10vh; */
`;

const ParagraphText = styled.p<any>`
	line-height: 1.3;
	margin-bottom: ${({ margin }) => margin || 0};
	text-align: ${({ center }) => center && 'center'};
	font-size: var(--size-15);

	a {
	color: var(--color-blue-violet);
	}

	a:hover {
	text-decoration: underline;
	color: var(--color-blue-violet);
	}
`;

const ParagraphForgotPwd = styled.p`
  display: inline-block;
  a:hover {
    text-decoration: underline;
  }
`;

const DivStepCircle = styled.div<any>`
	border: 2px solid #000;
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 18px;
	width: 18px;

	${({ on }) => on === 'true' && css`
		background-color: #000;
  `}
`;

const DivForgotPwd = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ImgHNA = styled.img`
	align-self: center;
	margin-top: 1vh;
	width: 20%;
`;

export default {
  Button,
  Title,
  SubTitle,
  ParagraphText,
  ParagraphForgotPwd,
  DivStepCircle,
  DivForgotPwd,
  ImgHNA,
};
