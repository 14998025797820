/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const DivHeadbandGuest = styled.div<any>`
  /* position: fixed; */
  width: 100%;
  height: ${({ theme: { mobile } }) => (mobile ? '18vh' : 'var(--headband-guest)')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(94deg, var(--color-light-royal-blue) 2%, var(--color-deep-sky-blue) 43%, var(--color-greenish-cyan) 99%);
  color: white;
  z-index: 7;
  font-size: var(--size-21);
  font-family: var(--font-family-sans);
  text-align: center;
  top: var(--header-height);
  padding-top: 1vh;
`;

export const DivHeadbandOffer = styled.div<any>`
  /* position: fixed; */
  width: 100vw;
  height: ${({ theme: { mobile } }) => (mobile ? '11vh' : 'var(--headband-trial)')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ advantageAmount, isOffer }) => (false
    ? 'linear-gradient(94deg, #7d6926 2%, #eed995 14%, #8b7837 47%, #dac582 84%, #847131 99%)'
    : true ? 'linear-gradient(94deg, #F91CD6 2%, #F91CD6 14%, #F91CD6 47%, #F91CD6 84%, #F91CD6 99%)'
      : 'linear-gradient(94deg, #7d6926 2%, #eed995 14%, #8b7837 47%, #dac582 84%, #847131 99%)')};
  z-index: 7;
  font-family: var(--font-family-sans);
  text-align: center;
  top: var(--header-height);
  padding-top: 2vh;
  padding-bottom: 0.5vh;
  color: white;
  cursor: pointer;

  div {
    line-height: 1.15;
  }

  p, span {
  font-size: var(--size-20);
  font-weight: bold;
  text-align: center;
  color: #fff;
  }
  span {

  font-weight: normal;
  }

  &:hover {
    text-decoration: underline;
  }
`;
