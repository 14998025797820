import styled, { css } from 'styled-components';

const LaunchOffer = styled.div`
  position: relative;
  width: 100%;
`;

const logo = css`
  position: relative;
  width: 16vw;
  min-height: 7vh;
  margin: 3.35vh auto 3.35vh auto;
`;

const close = css`
  width: 2.4vw;
  height: 2.4vw;
  position: absolute;
  right: 2.3vw;
  top: 2.87vh;
  cursor: pointer;
`;

const title = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-32);
  font-weight: bold;
  line-height: 1.15;
  text-align: center;
  color: var(--color-black-02);
  width: 85%;
  margin: 0 auto 3.35vh auto;
`;

const subTitle = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-24);
  line-height: 1.15;
  text-align: center;
  margin-bottom: 2.96vh;
`;

const bandOffer = css`
  line-height: 1.48;
  padding: 1.3vh 0;
  background-image: linear-gradient(124deg, var(--color-light-royal-blue) 0%, var(--color-deep-sky-blue) 41%, var(--color-greenish-cyan) 97%);
`;

const bandTxt1 = css`
  text-align: center;
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-26);
  color: white;
`;

const bandTxt2 = css`
  text-align: center;
  font-family: var(--font-family-serif);
  font-size: var(--size-23);
  color: white;
`;

const needHelp = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-18);
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: #575757;
  display: flex;
  justify-content: center;
  margin: auto auto 1.48vh auto;
  background: none;
  text-transform: uppercase;

`;
const finishTrial = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-16);
  font-style: italic;
  line-height: 1.3;
  text-align: center;
  color: var(--color-black-02);
  width: 48%;
  margin: auto auto 2vh auto;
`;

const buttonEnd = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-23);
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  color: var(--color-black);
  text-transform: uppercase;
`;
const buttonEndSub = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-13);
  line-height: 1.2;
  text-align: center;
  color: var(--color-black);
  margin-bottom: 4vh;
`;

const accessButtonTrial = css`
  min-height: 7vh;
  padding: 0 1.5vw;
  background: var(--color-black);
  display: flex;
  margin: 3.35vh auto 2.35vh auto;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: var(--size-13);
    color: white;
    line-height: 1.2;
  }

  span {
    /* margin: 2vh 2vw auto 2vw; */
    color: white;
    font-family: var(--font-family-sans);
    font-weight: 600;
    font-size: var(--size-26);
    text-transform: uppercase;
    line-height: 1.2;
  }
  #alone {
    margin: auto;
  }
`;

const subTxt = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-18);
  color: var(--color-black-02);
  text-align: center;
  line-height: 1.33;
  margin: 4.6vh auto;
  width: 70%;
`;

const countdownTxt = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-28);
  font-weight: bold;
  line-height: 1.39;
  text-align: center;
  color: var(--color-black-02);
  margin-top: 3.35vh;


  b {
    color: var(--color-gold);
  }
  
  span {
    font-size: var(--size-23);
  }
`;

export default {
  LaunchOffer,
  logo,
  subTxt,
  countdownTxt,
  close,
  bandTxt1,
  bandTxt2,
  bandOffer,
  title,
  subTitle,
  buttonEnd,
  buttonEndSub,
  finishTrial,
  needHelp,
  accessButtonTrial,
};
