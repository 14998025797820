import { css } from 'styled-components';

const premium = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #FFFFFF;
  margin-top: 2vh;
`;

const reserved = css`
  font-size: var(--size-21);
  font-family: var(--font-family-serif);
  font-weight: bold;
`;

const advantages = css`
  margin: 2vh 0;
  width: 100%;
`;

const advantageHead = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 80px; 
  height: 8.33vh; 
  padding-left: 3.03vw;
  color: white;
  background: transparent linear-gradient(93deg, #5833FF 0%, #175DF9 42%, #21FFCF 100%) 0% 0% no-repeat padding-box;
`;

const advantageTitle = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-20);
  margin-left: 0.5vw;
`;

const advantageTxt = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-16);
  line-height: 1.3;
`;

const listDots = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2vh 9vw;
  background: #EFEFEF;
  min-height: 250px;
  height: 25vh;
`;
const dot = css`
  list-style-position: outside;
  margin-bottom: 2vh;
  margin-left: 1vw;
  padding-left: 0.2vw;
  font-family: var(--font-family-sans);
  font-size: var(--size-20);
  color: var(--color-black);
  line-height: 1;

`;

const listDot1 = css`
  list-style-image: url("/dot-list-1.svg");
  ${dot};
`;
const listDot2 = css`
  list-style-image: url("/dot-list-2.svg");
  ${dot};
`;
const listDot3 = css`
  list-style-image: url("/dot-list-3.svg");
  ${dot};
`;

const listDot4 = css`
  list-style-image: url("/dot-list-4.svg");
  ${dot};
`;

const readNext = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-21);
  color: var(--color-black-02);
`;

const discoverHead = css`
  display: flex;
  margin-bottom: 6px;
`;

const freeAccessTxt = css`
  text-align: center;
  font-size: var(--size-21);
  font-family: var(--font-family-sans);
  font-weight: bold;
  color: var(--color-black);
`;

const reservedOrtho = css`
  text-align: center;
  font-size: var(--size-18);
  font-family: var(--font-family-sans);
  color: var(--color-black);
  cursor: pointer;
`;

export default {
  premium,
  reserved,
  advantages,
  freeAccessTxt,
  advantageHead,
  reservedOrtho,
  listDots,
  listDot1,
  listDot2,
  listDot3,
  listDot4,
  readNext,
  advantageTxt,
  advantageTitle,
  discoverHead,
};
