const sortBaughtTraining = (list) => {
  if (list && !list?.error) {
    list?.sort((a, b) => {
      if (!a.programVendor?.hasStarted && !b.programVendor?.hasStarted) return 0;
      if (!a.programVendor?.hasStarted) return -1;
      if (!b.programVendor?.hasStarted) return 1;
      if (a.programVendor?.progress < b.programVendor?.progress) return -1;
      return 1;
    });
  }
  return list;
};

export default sortBaughtTraining;
