import { useEffect } from 'react';

const useHandleKeyPress = (key, handleEscape) => {
  const escFunction = (event) => {
    if (event.key === key) handleEscape(event);
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction, false);
  });
};

export default useHandleKeyPress;
