import styled, { css } from 'styled-components';

const paperProfile = css`
  width: 100%;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px 10px 0 0;
  padding: 1.1vh 1.15vw 0 1.15vw;
  cursor: default;
`;

const tabs = css`
  position: absolute;
  width: ${({ theme: { mobile } }) => (mobile ? '36vw' : '12vw')};
  top: var(--header-height);
  right: var(--layout-margin);
  z-index: 50;
`;

const Text = styled.div<any>`
  text-align: left;
  font-family: var(--font-family-sans);
  color: var(--color-black-02);
  margin-top: 1px;
  font-size: var(--size-16);
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.1667vh;
  box-sizing: border-box;
  border-bottom: ${({ last }) => !last && '1px solid #BFBFBF'};
  cursor: pointer;


  &:hover {
    color: var(--color-black-02);
    font-family: var(--font-family-sans);
    font-weight: 600;
  }

`;

const name = css`
  color: var(--color-black);
    font-family: var(--font-family-sans);
    font-weight: 600;
  font-size: var(--size-18);
  text-align: left;
  line-height: 2.03vh;
`;

const buttonSus = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 9vw;
  height: 3.33vh;
  background-color: var(--color-gold);
  margin: 1.5vh 0 0.8vh 0;
`;

const textSus = css`
  color: white;
  font-size: var(--size-16);
`;

const unSuscribedDiv = css`
  display: flex;
  justify-content: space-between;
  margin: 1.5vh 0 0.8vh 0;
`;

const unSuscribedTxt = css`
  font-size: var(--size-16);
`;

const unSuscribedPrmm = css`
  font-size: var(--size-16);
  color: var(--color-gold);
  font-family: var(--font-family-sans);
  font-style: italic;
`;

const profileBottom = css`
  text-align: left;
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const bottomTxt = css`
  margin-bottom: 1.1vh;
  font-size: var(--size-14);
  color: var(--color-black-02);
  font-family: var(--font-family-sans);
  background-color: transparent;
  padding: 0;

  &:hover {
    color: var(--color-black-02);
    font-family: var(--font-family-sans);
    font-weight: 600;
  }
`;

const bottomLogout = css`
  background: transparent linear-gradient(261deg,#5833FF 0%,#175DF9 42%,#21FFCF 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  height: 4.16vh;
  display: inline-flex;
  width: 100%;
  padding-left: 1.15vw;
  color: white;
  align-items: center;
  font-size: var(--size-16);
  border: none;
`;

const buttonForm = css`
  text-align: left;
  font-family: var(--font-family-sans);
  color: var(--color-black-02);
  margin-top: 1px;
  font-size: var(--size-16);
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.1667vh;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  line-height: 1;


  &:hover {
    color: var(--color-black-02);
    font-family: var(--font-family-sans);
    font-weight: 600;
  }
`;

export default {
  tabs,
  paperProfile,
  name,
  buttonSus,
  textSus,
  unSuscribedDiv,
  unSuscribedPrmm,
  unSuscribedTxt,
  Text,
  buttonForm,
  profileBottom,
  bottomLogout,
  bottomTxt,
};
