import styled, { css } from 'styled-components';

const backgroundsTab = {
  'mon-academy': ' linear-gradient(254deg,#5833FF,#175DF9 50%,#21FFCF 100%)',
  informations: 'linear-gradient(0deg,var(--color-deep-sky-blue) 0%,#21FFCF 100%)',
  formations: 'linear-gradient(360deg,#21FFCF 0%, var(--color-deep-sky-blue) 100%)',
  echanges: 'linear-gradient(to bottom, var(--color-light-royal-blue), rgba(2, 127, 193, 0))',
};

export const Main = styled.main<any>`
  display: flex;
  width: 100vw;
  height: ${({ theme: { mobile }, headbandHeight }) => (mobile ? 'calc(100vh - var(--header-height))' : `calc(100vh - var(--footer-height) - var(--header-height) - ${headbandHeight})`)};
  background: ${({ hasBg, bgMain }) => hasBg && backgroundsTab[bgMain]};
  position: relative;
  overflow-x: hidden;
`;

export const Background = styled.div<any>`
  width: 100%;
  height: 42%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: ${({ bgHome }) => backgroundsTab[bgHome]}; */
  opacity: 0.77;
  ${({ bgHome }) => css`
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: ${backgroundsTab[bgHome]};
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 101%;
    display: block;
    background: linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(245, 245, 245, 0.5) 70%, rgba(245, 245, 245, 1));
  }
  `}
`;

export const ContentHome = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: hidden; */
  overflow: ${({ exploreAll }) => (exploreAll && 'hidden')};
`;

export const CenterMain = styled.div<any>`
  padding: ${({ isExtended, hasHeadband, hasThematic }) => {
    let ret = '9.5vh var(--layout-margin) 0 var(--layout-margin);';
    if (isExtended && hasHeadband) ret = '6.5vh 0 0 var(--layout-margin);';
    if (!isExtended && hasHeadband) ret = '6.5vh var(--layout-margin) 0 var(--layout-margin);';
    if (isExtended && !hasHeadband) ret = '6.5vh var(--layout-margin) 0 var(--layout-margin);';
    if (hasThematic && hasHeadband) ret = '6.5vh var(--layout-margin) 0 calc(14.22vw + (var(--layout-margin) * 2));';
    if (hasThematic && !hasHeadband) ret = '9.5vh var(--layout-margin) 0 calc(14.22vw + (var(--layout-margin) * 2));';
    return ret;
  }}

  height: ${({ isExtended, headbandHeight }) => (isExtended ? `calc(100vh - ${headbandHeight} - var(--header-height) - var(--footer-height));` : 'calc(100vh - var(--header-height) - var(--footer-height))')};
  margin-top: ${({ margin }) => margin && '8vh'};
`;

export const ButtonBackPosition = styled.div<any>`
  position: absolute;
  left: calc(var(--layout-margin) + 3.2vw);
  margin-top: ${({ hasHeadband }) => (!hasHeadband ? '2.5vh' : '1vh')};
  z-index: 102;
`;

export const loader = css`
  width: 10%;
  margin: auto;
  margin-top: 10vh;
`;
