import { css } from 'styled-components';
import { SvgMailExchange } from 'containers/Exchanges/exchangesSvgs';

const wrap = css`
    position: absolute;
    /* width: 3.4vw; */
    height: 2.4vh;
    background: #C7BBFF;
    padding: 0 1vh;
    display: flex;
    justify-content: start;
    border-radius: 35px;
    align-items: center;
    left: calc(100% - 2.5vw);
    top: 2vh;

    div {
        position: relative;
        width: 1.1vw;
        height: 1.4vh;
        display: flex;
    }

    p {
      line-height: 2.4vh;
      margin-left: 0.3vw;
      font-family: var(--font-family-sans);
      font-size: var(--size-21);
      font-weight: 600;
      color: var(--color-light-royal-blue);
      margin-bottom: 0.1vh;
    }
`;

const BulleNewMessage = ({ countUnRead }) => (
  <div css={wrap}>
    <div>
      <SvgMailExchange />
    </div>
    <p>
      +
      {countUnRead}
    </p>
    {/* <p>+254</p> */}
  </div>
);

export default BulleNewMessage;
