import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import hnaRequest from 'utils/hnaRequest';
import useAuth from './useAuth';

const useGetData = ({
  content, training, id, isFormation, baughtTraining,
}) => {
  const [contentData, setContentData] = useState(content?.content || content);
  const [trainingData, setTrainingData] = useState(training?.training || training);
  const refetchContent = !contentData?.id || contentData?.id !== id;
  const refetchTraining = !trainingData?.id || trainingData?.id !== id;
  const { user } = useAuth();
  const router = useRouter();

  const getTraining = async () => {
    let result: any;
    try {
      // const isBaught = baughtTraining?.find((el) => el.id === id && el.status === 'DRAFT');
      const forceDisplay = router?.query?.isPreview === 'true';
      // console.log({ isBaught });
      result = await hnaRequest('get', `training/get/${Number(id)}${forceDisplay ? '?forceDisplay=true' : ''}`, undefined);
      setTrainingData(result?.training);
    } catch (error) {
      setTrainingData({ error: error?.response?.data?.statusCode });
    }
  };
  const getContent = async () => {
    let result: any;
    try {
      const forceDisplay = router?.query?.isPreview === 'true';
      result = await hnaRequest('get', `content/get/${Number(id)}${forceDisplay ? '?forceDisplay=true' : ''}`, undefined);
      setContentData(result?.content);
    } catch (error) {
      setContentData({ error: error?.response?.data?.statusCode });
    }
  };

  useEffect(() => {
    if (id && isFormation && refetchTraining) getTraining();
    else if (id && !isFormation && refetchContent) getContent();
    else if (!id) {
      setTrainingData(null);
      setContentData(null);
    }
  }, [id, isFormation, refetchContent, refetchTraining, baughtTraining]);

  useEffect(() => {
    if (id && isFormation) getTraining();
    else if (id && !isFormation) getContent();
  }, [user]);

  return {
    contentData, trainingData, refetchContent, refetchTraining,
  };
};

export default useGetData;
