import styled, { css } from 'styled-components';

const ContentDetail = styled.article<any>`
  display: flex;
  flex-direction: column;
  width:  ${({ theme: { mobile } }) => (mobile ? '100%' : 'var(--content-width)')};
  margin: 0 auto 0 auto;
  background-color: white;
  padding:  ${({ theme: { mobile } }) => (mobile ? '10vw 3.125vw 3.125vw 3.125vw' : '7vh 3.125vw')};
  min-height: 100vh;
  position: relative;
`;

const close = css`
  width: ${({ theme: { mobile } }) => (mobile ? '6.4vw' : '2.4vw')};
  height: 2.4vw;
  position: fixed;
  right: 0;
  right: 10px;
  top: ${({ theme: { mobile } }) => (mobile ? '1vh' : '10px')};
  cursor: pointer;
`;

const contentType = css`
  height: 2.77vh;
  text-align: center;
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  color: #21FFCF;
  text-transform: uppercase;
  background: var(--color-black-02);
  padding: 0px 0.6vw;
  /* min-height: 2.77vh; */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

const theme = css`
  text-align: center;
  font-family: var(--font-family-sans);
  color: var(--color-gray-warm);
  text-transform: uppercase;
  margin: 0 0.3645833333333333vw;
  font-size: var(--size-21);
  white-space: nowrap;
`;

const title = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  color: var(--color-black-02);
  font-size: var(--size-32);
  margin-bottom: var(--height-xxs);
  line-height: 1.1;
  width: calc(100% - 8.8vw);
`;

const infos = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  color: var(--color-gray-warm);
  /* text-transform: uppercase; */
  /* margin-bottom: var(--height-xxs); */
  line-height: 1.1;
  position: relative;
`;

const memoirInfos = css`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--height-s);

  p {
    line-height: 2vh;
  }

  span {
    color:#969696;
    font-family: var(--font-family-sans);
    font-size: var(--size-14);
  }
`;

const buttonRedirect = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16vw;
  /* height: 6vh; */
  background: var(--color-black);
  box-shadow: 0px 3px 12px #00000070;
  margin:  var(--height-s) auto var(--height-xxs) auto;

  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-21);
  color: white;
  text-align: center;
  line-height: 1.1;
  padding: 1vh 2vh;

  &:hover {
    /* transform: scale(1.02); */
    color: white;
  }
`;

const connectYou = css`
  color: var(--color-black-02);
  font-family: var(--font-family-serif);
`;

const boldSerif = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
`;

const connectedTxt = css`
  font-family: var(--font-family-sans);
  color: #000;
  font-size: var(--size-18);
  line-height: 1;
  text-align: center;
  width: 30%;
  margin: 0 auto 1vh auto;
`;

const image = css`
  position: relative;
  width: 100%;
  height: 25.83vh;
  z-index: 100;
`;

const imageInterview = css`
  position: relative;
  width: 8.3vw;
  height: 8.3vw;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -5.5vw;
`;

const imageInterviewDefault = css`
  position: relative;
  width: 8.3vw;
  height: 8.3vw;
  overflow: hidden;
  margin-top: -5.5vw;
`;

const innerHtml = css`
  margin-top: 3.43vh;
`;

const author = css`
  color: var(--color-light-royal-blue);
  font-size: var(--size-21);
  font-family: var(--font-family-sans);
  text-transform: uppercase;
`;

const underImage = css`
width: 100%;
height: 3.4vh;
background: transparent linear-gradient(90deg, #5833FF 0%, #175DF9 42%, #20F5D2 85%, #21FFCF00 100%);
`;

const underMemoir = css`
  width: 100%;
  height: 1vh;
  background: transparent linear-gradient(90deg, #5833FF 0%, #175DF9 42%, #20F5D2 85%, #21FFCF00 100%) 0% 0% no-repeat padding-box;
  margin: 1vh 0px;
`;

const description = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-20);
  color: var(--color-black-02);
  /* margin-bottom: var(--height-s); */
  margin-top: var(--height-xxs);
  line-height: 1.55;
  width: 90%;
`;

const premium = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #FFFFFF;
  margin-top: 2vh;
`;

const fading = css`
  position: absolute;
  top: -21.944444444444443vh;
  height: 21.944444444444443vh;
  width: 100%;
  z-index: 100;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
`;

const fadingTop = css`
  height: 6.85vh;
  background: transparent linear-gradient(180deg,white 0%,#FFFFFFFC 26%,#FFFFFFD6 45%,#FFFFFF00 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  z-index: 1000;
  width: calc(var(--content-width) - 3.125vw);
  visibility: ${({ theme: { mobile } }) => (mobile && 'hidden')};
`;

const fadingBottom = css`
  height: 6.85vh;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFFFC 26%, #FFFFFFD6 45%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: calc(var(--content-width) - 3.125vw);
  visibility: ${({ theme: { mobile } }) => (mobile && 'hidden')};
`;

const resumeTitle = css`
  color: var(--color-black-02);
  font-size: var(--size-30);
  font-family: var(--font-family-serif);
`;

const interviewWrap = css`
  display: flex;
  justify-content: space-between;
`;

const sources = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-19);
  margin-bottom: var(--height-s);
`;

const sugestTxt = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-20);
  color: var(--color-black-02);
  margin-top: 4vh;
  margin-bottom: 4vh;
  line-height: 1.5;
`;

const listThemes = css`
  display: flex;
  flex-wrap: wrap;
  line-height: 1.2;
`;

const accessButton = css`
  min-height: 7vh;
  padding: 0 1.5vw;
  background: var(--color-black);
  display: flex;
  margin: 4.35vh auto 2.35vh auto;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: var(--size-13);
    color: white;
    line-height: 1.2;
  }

  span {
    /* margin: 2vh 2vw auto 2vw; */
    color: white;
    font-family: var(--font-family-sans);
    font-weight: 600;
    font-size: var(--size-26);
    text-transform: uppercase;
    line-height: 1.2;
  }
  #alone {
    margin: auto;
  }


`;

const needHelp = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-18);
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: #575757;
  display: flex;
  justify-content: center;
  margin: auto auto 1.48vh auto;
  background: none;
  text-transform: uppercase;
`;

const advTxt = css`
  margin: 4vh 0;
  width: 55%;
  text-align: center;
  line-height: 1.1;
  color: var(--color-black);
  font-size: var(--size-20);
`;

const hoverCoach = css`
  position: fixed;
  z-index: 2000;
`;

const animateur = css`
  text-transform: initial;
  font-size: var(--size-18);
  color: var(--color-gray-warm);
`;

const podcastImage = css`
  position: absolute;
  z-index: 100;
  color: var(--color-black);
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    background: #ffffffe6;
    /* border-radius: 7px; */
    font-size: var(--size-20);
    line-height: 1.4;
    padding: 1vh;
  }
`;

const podcastTitle = css`
    font-family: var(--font-family-serif);
    font-weight: 600;
    color: var(--color-black);
    font-size: var(--size-20);
    margin-bottom: 1vh;
    line-height: 1.1;
`;

const reduceVideo = css`
  position: absolute;
  width: 2.5vw;
  top: 0;
  right: 0;
  cursor: pointer;

  &:hover {
    width: 2.8vw;
  }
`;

const upperTime = css`
  text-transform: uppercase;
`;

const products = css`
  display: flex;
  margin-top: 2.7vh;

  a, a:hover {
  color: var(--color-black);
  }
`;

const svgProduct = css`
  position: relative;
  width: 1.45vw;
  margin-right: 0.5vw;
`;

const outils = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  font-weight: bold;
  line-height: 1.48;
  text-align: left;
  color: #7b7b7b;
  margin-right: 0.83vw;
`;

const product = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  font-weight: bold;
  line-height: 1.48;
  text-align: left;
  color: var(--color-black);

  cursor: pointer;

  a:hover {
    text-decoration: underline;
  }
`;

const calameoLink = css`
  height: 50vh;
`;

const deboeckHolder = css`
  width: 100%;
  height: 50vh;
  position: relative;
  cursor: pointer;

  p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 4vw;
    height: 8vh;
    background: #ffffffe6;
    text-align: center;
    font-size: var(--size-20);
    line-height: 1.4;
    padding: 1vh;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
`;

export default {
  ContentDetail,
  deboeckHolder,
  products,
  outils,
  svgProduct,
  podcastTitle,
  upperTime,
  product,
  premium,
  reduceVideo,
  calameoLink,
  animateur,
  listThemes,
  hoverCoach,
  podcastImage,
  sugestTxt,
  resumeTitle,
  fadingTop,
  sources,
  fadingBottom,
  advTxt,
  needHelp,
  fading,
  interviewWrap,
  author,
  description,
  underImage,
  underMemoir,
  accessButton,
  close,
  contentType,
  theme,
  title,
  infos,
  memoirInfos,
  imageInterview,
  imageInterviewDefault,
  buttonRedirect,
  connectYou,
  boldSerif,
  image,
  connectedTxt,
  innerHtml,
};
