import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import { useRouter } from "next/router";
import useAuth from "hooks/useAuth";
import { flexRow, pointer } from "styles/common";
import SC from "./style";
import Discover from "components/Discover";
import WhereComeFrom from "components/WhereComeFrom";
import { getPermanentUrl } from "utils/url";
import Catalogue from "components/Catalogue";
import getDaysLeft from "utils/getDaysLeft";
import formatTitleAsUrl from "utils/formatTitleAsUrl";
import SvgProducstTool from "../../../public/products-tool.svg";
import SC1 from "components/ContentDetail/style";
import { openSubscribtion } from "../../utils/link";

export interface TrainingDetailProps {
  data: any;
  suggestions: any;
}

const TrainingDetail: React.FC<TrainingDetailProps> = ({
  data,
  suggestions,
}) => {
  const { user, isSubscribed } = useAuth();
  const router = useRouter();
  const numbersDaysLeft = getDaysLeft(
    data?.programCreationDate,
    data?.doubleMaxTime,
    data?.maxCompleteDays
  );
  const isAccessibleForSubOnly = data?.pricing === "FREE_FOR_SUSCRIBERS";

  useEffect(() => {
    const element = document.querySelector("iframe");
    if (isAccessibleForSubOnly && !isSubscribed && element)
      element.style.display = "none";
  }, []);

  return (
    <SC.TrainingDetail>
      {data?.image && (
        <figure css={SC.image}>
          <Image
            itemProp="image"
            src={
              data?.image.slice(0, 4) === "http"
                ? data?.image
                : getPermanentUrl(data?.image, "-972x279")
            }
            alt="thumbnail"
            fill
            sizes="100vw"
            style={{
              objectFit: "cover",
            }}
          />
          {data?.vendorType === "EGNOSIA" && (
            <>
              <div css={SC.bgEgnosia} />
              <div css={SC.image2}>
                <Image
                  src="/logo-egnosia-white.png"
                  alt="HNA x EGNOSIA"
                  fill
                  sizes="100vw"
                />
              </div>
            </>
          )}
          {data?.programId &&
            numbersDaysLeft &&
            data?.accessUrl &&
            data?.vendorType !== "HNA" && (
              <div css={SC.daysLeft}>
                <span css={SC.daysLeftNbr}>
                  {numbersDaysLeft > 0 ? numbersDaysLeft : ""}
                </span>
                {(() => {
                  if (numbersDaysLeft > 0) {
                    //
                    return (
                      <span css={SC.daysLeftTxt}>
                        JOURS <br />
                        RESTANTS
                      </span>
                    );
                  }
                  if (numbersDaysLeft < 0) {
                    return (
                      <span css={SC.daysLeftTxtEnd}>
                        Temps <br />
                        écoulé
                      </span>
                    );
                  }
                  return null;
                })()}
              </div>
            )}
        </figure>
      )}

      {data?.vendorType === "EGNOSIA" && (
        <p css={SC.egnosiaDesc}>
          Cette formation est proposée par e-Gnosia en partenariat avec
          Happyneuron Academy, une fois la formation achetée, vous la
          retrouverez sur l'Academy dans "Mes formations" mais l'ensemble de la
          pédagogie sera dispensée directement sur le site{" "}
          <a href="https://www.e-gnosia.fr" target="_blank" rel="noreferrer">
            www.e-gnosia.fr
          </a>
          .
        </p>
      )}

      {!user && <WhereComeFrom type="formation" />}

      <div
        className="froala-editor-custom fr-view"
        css={SC.presentation}
        dangerouslySetInnerHTML={{ __html: data?.presentation }}
      />

      {isAccessibleForSubOnly && !isSubscribed && (
        <div
          css={SC.noAccessVideo}
          role="link"
          tabIndex={0}
          onClick={() => openSubscribtion()}
        >
          <p>
            Cette formation est réservée aux abonnés Happyneuron, pour en
            profiter, abonnez-vous !
          </p>
        </div>
      )}

      {data.summary?.length ? (
        <>
          <p css={SC.programTitle}>Le programme</p>
          <ul css={SC.program}>
            {data.summary.map((program, i) => (
              <SC.ListProgram
                noBorder={i === data.summary.length - 1}
                key={program.title}
              >
                <div css={SC.losange} />
                <p css={SC.title}>{program.title}</p>
                <p css={SC.description}>{program.description}</p>
                <ul>
                  {program.sub.map((sub) => (
                    <li key={sub.title} css={SC.listDot}>
                      <p css={SC.subTitle}>{sub.title}</p>
                      <p css={SC.subDescription}>{sub.description}</p>
                    </li>
                  ))}
                </ul>
              </SC.ListProgram>
            ))}
          </ul>
        </>
      ) : null}
      {!user && <Discover />}

      {data?.products?.length ? (
        <div css={SC1.products}>
          <div css={SC1.svgProduct}>
            <Image src={SvgProducstTool} alt="product" fill sizes="100vw" />
          </div>
          <p css={SC1.outils}>Outils associés :</p>
          <p css={SC1.product}>
            {data?.products?.map((c, i) => (
              <Link shallow key={c.id} href={`products/${formatTitleAsUrl(c)}`}>
                {i === data.products.length - 1 ? c.title : `${c.title}, `}
              </Link>
            ))}
          </p>
        </div>
      ) : null}

      <p css={SC.sugestTxt}>
        Cette formation vous intéresse ?
        <br />
        Allez plus loin et découvrez nos suggestions sur ce thème.
      </p>

      {suggestions?.length ? (
        <Catalogue
          user={user}
          name="suggestions"
          unClickable
          title="Suggestions"
          list={suggestions.slice(0, 3)}
        />
      ) : null}
    </SC.TrainingDetail>
  );
};

export default TrainingDetail;
