import Image from 'next/image';
import SC from './style';
import ImageCoach from '../../../public/placeholder-coach.svg';
import { DivFlex } from 'styles/common';
import { getPermanentUrl } from 'utils/url';
import useFading from 'hooks/useFading';

interface CardCoachProps {
  item: any
}

const CardCoach: React.FC<CardCoachProps> = ({ item }) => {
  const { hasFading } = useFading({ elementId: `coach-${item.id}` });
  const coach = item.roles.find((el) => el.type === 'coach');
  const author = item.roles.find((el) => el.type === 'author');

  return (
    <div key={item.id} css={SC.item}>
      <DivFlex>
        <div css={SC.image}>
          <Image
            src={item.image ? getPermanentUrl(item.image, '-195x195') : ImageCoach}
            alt="photo du coach"
            fill
            sizes="100vw"
          />

        </div>
        <div css={SC.name}>
          <p css={SC.firstname}>{item.firstName.toLowerCase()}</p>
          <p css={SC.lastname}>{item.lastName.toLowerCase()}</p>
        </div>
      </DivFlex>
      <p css={SC.expertises}>Expertises</p>
      <div css={SC.expBlock}>
        <div css={SC.expertisesList}>
          {item.themes.map((theme) => (
            <div key={theme.id} css={SC.skill}><p>{theme.name}</p></div>
          ))}
        </div>
      </div>
      {coach && (
        <p css={SC.since}>
          Formateur·ice depuis
          {' '}
          <b>{coach.since}</b>
        </p>
      )}
      {author && (
        <p css={SC.since}>
          Auteur·e depuis
          {' '}
          <b>{author.since}</b>
        </p>
      )}
      <p id={`coach-${item.id}`} css={SC.description}>{item.roles?.[0].description}</p>
      {hasFading && <div css={SC.fading} />}
    </div>
  );
};
export default CardCoach;
