import { notification } from 'antd';
import { queryGetProfile, queryListChannels } from './exchanges';

const getProfileQuery = async (dispatch) => {
  try {
    const { data } = await queryGetProfile();
    if (data.getProfile?.privacy) {
      const channels = await queryListChannels();
      dispatch({
        type: 'getProfileQuery',
        payload: {
          loading: false,
          acceptedPolicy: true,
          user: data.getProfile,
          channels: channels?.data?.listChannels,
        },
      });
    } else dispatch({ type: 'getProfileQuery', payload: { loading: false, acceptedPolicy: false } });
  } catch (error) {
    console.error({ error });
    notification.error({
      message: 'Veuillez nous excuser, une erreur est survenue.',
      description: 'Essayez de recharger votre page ou contactez le support si le problème persiste',
    });
  }
};

export default getProfileQuery;
