import styled, { css } from 'styled-components';
import { Steps as AntSteps, Form as AntForm } from 'antd';
import SC1 from 'styles/connect';

const DivSignup = styled.div`
  display: flex;
	flex-direction: column;
  align-items: center;
  a, a:hover {
    color: var(--color-blue-violet);
  }


`;

const Steps = styled(AntSteps)<any>`
	margin-top: 2vh;
	min-width: 150px;
	width: 19%;
`;

const Form = styled(AntForm)`
  justify-self: center;
`;

const loader = css`
  width: 100%;
  height: 60%;
`;

const loaderAutoSignin = css`
  width: 15%;
  margin: 4vh 0;
`;

const autoLoginMessage = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(96vh - var(--footer-height));

  p {
    font-family: var(--font-family-serif);
    font-size: var(--size-24);
  }
`;

const imgBack = css`
      position: fixed;
    margin-left: 7vw; 
`;

const backTitle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  min-height: 5vh;
`;
const text = css`
  margin-bottom: 2vh;
  text-align: center;
`;

const hasAdeli = css`
  margin-bottom: 5px;
`;

const buttonLink = css`
  background-color: transparent;
  padding: 0;
`;

const DivAutoLogin = styled.div`
  display: flex;
	flex-direction: column;
  align-items: center;
  a, a:hover {
    color: var(--color-blue-violet);
  }
  
  height: calc(100vh - var(--footer-height));
`;

export default {
  ...SC1,
  DivSignup,
  Steps,
  Form,
  buttonLink,
  loader,
  autoLoginMessage,
  DivAutoLogin,
  loaderAutoSignin,
  imgBack,
  backTitle,
  text,
  hasAdeli,
};
