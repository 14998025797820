import Image from 'next/image';
import { useRouter } from 'next/router';
import SvgLogo from '../../../public/Logo.svg';
import SC from './style';
import { SvgClose } from 'components/Svgs';
import Advantages from 'components/Advantages';
import useAuth from 'hooks/useAuth';
import { openSubscribtion } from '../../utils/link';

interface LaunchOfferProps {
  handleClose: any
}

const LaunchOffer: React.FC<LaunchOfferProps> = ({ handleClose }) => {
  const router = useRouter();
  const { user } = useAuth();
  const isHNPROMember = user?.profile?.isHNPROMember;
  return (
    <SC.LaunchOffer>
      <div css={SC.logo}>
        <Image src={SvgLogo} alt="subscription" fill sizes="100vw" />
      </div>
      <div tabIndex={0} role="link" css={SC.close} onClick={handleClose} onKeyPress={handleClose}>
        <SvgClose />
      </div>
      {isHNPROMember ? (
        <p css={SC.title}>
          3 RAISONS DE COMPLÉTER VOTRE ABONNEMENT
          AVEC L’ACADEMY
        </p>
      ) : (
        <p css={SC.title}>
          3 RAISONS DE S’ABONNER
          <br />
          À L’HAPPYNEURON ACADEMY
        </p>
      )}

      <Advantages isHNPROMember={isHNPROMember} />

      {isHNPROMember ? (
        <p css={SC.countdownTxt}>
          ACCÉDEZ TOUTE L’ANNÉE AUX CONTENUS
          {' '}
          <b>PREMIUM</b>
          <br />
          <span>Nouveautés, articles scientifiques, podcasts, interviews, etc.</span>
        </p>
      ) : (
        <p css={SC.countdownTxt}>
          ABONNEMENT À L’ACADEMY
          <br />
          <b>29€/mois</b>
          {' '}
          avec un engagement d’un an
        </p>
      ) }

      <button
        css={SC.accessButtonTrial}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          openSubscribtion();
          router.push('/abonnement', undefined, { shallow: true });
          handleClose(true);
        }}
      >
        <span>JE PROFITE DE L’OFFRE</span>
      </button>

      <a href="https://www.happyneuronpro.com/orthophonie/essai-hnpro-orthophonie/" target="_blank" rel="noreferrer">
        <p css={SC.needHelp}>
          Vous avez besoin d'aide ? Contactez-nous
        </p>
      </a>

      <p css={SC.finishTrial}>
        Une fois abonné, ajoutez une formation à votre panier et validez votre panier pour utiliser votre bon d’achat.
      </p>

    </SC.LaunchOffer>
  );
};

export default LaunchOffer;
