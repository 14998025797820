import SC from './style';

const Vendor: React.FC = () => (
  <SC.Vendor>
    <p>Vous avez reçu ce lien suite à un échange avec notre équipe commerciale.</p>
    <p>
      Merci de votre confiance !
    </p>
  </SC.Vendor>
);

export default Vendor;
