/* eslint-disable max-len */
import { useRouter } from 'next/router';
import minutesAsReadableDuration from 'components/InfosBulleTraining/minutesAsReadableDuration';
import { flexWrap } from 'styles/common';
import SC from './style';
import { isUserSubscribed } from 'utils/auth';
import useAuth from 'hooks/useAuth';
import useLaunchOffer from 'hooks/useLaunchOffer';

interface InfosBulleTrainingProps {
  data: any
}

const InfosBulleTraining: React.FC<InfosBulleTrainingProps> = ({ data }) => {
  const { user } = useAuth();
  const subscribed = isUserSubscribed(user);
  const { isOfferAvailable } = useLaunchOffer();
  const hasBuyed = data?.programId && data?.accessUrl;

  return (
    <div css={flexWrap}>
      {data?.duration && (
      <div css={[SC.bulle, SC.bulleDurationColor]}>
        {minutesAsReadableDuration(data?.duration)}
      </div>
      )}
      {!hasBuyed && data?.maxCompleteDays && (
      <div css={[SC.bulle, SC.bulleDurationColor]}>
        <SC.TxtAboveNbr>Disponible</SC.TxtAboveNbr>
        <p css={SC.nbDays}>{data.maxCompleteDays}</p>
        <SC.TxtAboveNbr>jours*</SC.TxtAboveNbr>
      </div>
      )}

      {!hasBuyed && data?.pricing !== 'FREE_FOR_SUSCRIBERS' && (
      <div css={[SC.bulle, SC.bullePriceColor]}>
        <SC.TxtAboveNbr white subscribed={subscribed}>
          Prix
        </SC.TxtAboveNbr>
        <span css={[SC.bulleValue]}>
          {data?.price.standard}
          €
        </span>
        <SC.TxtAboveNbr white invisible>
          invisible
        </SC.TxtAboveNbr>
      </div>
      )}

      {!hasBuyed && data?.pricing === 'FREE_FOR_SUSCRIBERS' && (
      <div css={[SC.bulle, SC.bullePriceSuscriber]}>
        <span css={SC.bulleInfo}>ABONNÉ</span>
        <span css={SC.bulleOffer}>OFFERTE</span>
      </div>
      )}
      {!hasBuyed && isOfferAvailable && (
        <div css={[SC.bulle, SC.bulleNewOffer]}>
          <SC.TxtAboveNbr color="#F91CD6">
            Offre
            <br />
            spéciale
          </SC.TxtAboveNbr>
          <span css={[SC.bulleValue, SC.bulleColorOffer]}>
            -20%
          </span>
        </div>
      )}
    </div>

  );
};

export default InfosBulleTraining;
