import Link from 'next/link';
import SC from './style';
import { DivFlex, pointer } from 'styles/common';
import BulleNewMessage from 'containers/Exchanges/components/BulleNewMessage';

export interface MenuProps {
  handleNav: any
  getCurrentPath: any
  menuProps: any
  countUnRead: any
 }

const getPath = (path) => (path === '/echanges' && typeof localStorage !== 'undefined' && localStorage?.getItem('channel')
  ? `${path}?channel=${localStorage?.getItem('channel')}` : path);

const Menu: React.FC<MenuProps> = ({
  handleNav, getCurrentPath, menuProps, countUnRead,
}) => (
  <SC.NavMenu>
    {menuProps.map(({
      path, title, SvgIcon,
    }) => {
      const selected = getCurrentPath(path);
      return (
        <DivFlex css={pointer} key={path} justify="center">
          <div onClick={handleNav} role="presentation">
            <Link href={getPath(path)} passHref shallow legacyBehavior>
              <SC.AnchorItem key={title}>
                <SC.SpanTitle selected={selected}>
                  {title}
                </SC.SpanTitle>
                <SC.DivDot />
                {path === '/echanges' && countUnRead > 0 && <BulleNewMessage countUnRead={countUnRead} />}
                <SC.DivIcon selected={selected}>
                  <SvgIcon hasTransition selected={selected} />
                </SC.DivIcon>
              </SC.AnchorItem>
            </Link>
          </div>

        </DivFlex>
      );
    })}
  </SC.NavMenu>
);

export default Menu;
