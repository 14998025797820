import styled, { css } from 'styled-components';

const ButtonProfile = styled.button<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.2037vh;
  height: 6.2037vh;
  border-radius: 50%;
  margin: 0 var(--layout-margin) 0 2.23958vw;
  cursor: pointer;
  background-color: ${({ suscribed }) => (suscribed ? 'var(--color-gold)' : '#F91CD6')};
  border: 0.15625vw solid ${({ suscribed }) => (suscribed ? 'var(--color-gold)' : '#F91CD6')};
`;

const paperProfile = css`
  width: 100%;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px 10px 0 0;
  padding: 1.1vh 1.15vw 0 1.15vw;
`;

const paperCart = css`
  width: 100%;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px;
  padding: 1.1vh 1.15vw 0 1.15vw;
`;

const tabs = css`
  position: absolute;
  width: 12vw;
  top: var(--header-height);
  right: var(--layout-margin);
  z-index: 50;
`;

const tabsCart = css`
  position: absolute;
  width: 21.71875vw;
  top: var(--header-height);
  right: var(--layout-margin);
  z-index: 50;
`;

const background = css`
  width: 100vw;
  position: fixed;
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: #00000021;
  z-index: 49;
`;

const Separator = styled.div`
  width: 173px;
  height: 0px;
  border: 1px solid #BFBFBF;
`;

const Text = styled.div<any>`
  text-align: left;
  font-family: var(--font-family-sans);
  color: var(--color-black-02);
  margin-top: 1px;
  font-size: var(--size-18);
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.1667vh;
  box-sizing: border-box;
  border-bottom: ${({ last }) => !last && '1px solid #BFBFBF'};
  cursor: pointer;


  &:hover {
    color: var(--color-black-02);
    font-family: var(--font-family-sans);
    font-weight: 600;
  }

`;

const acrony = css`
  color: var(--color-black);
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-21);
`;

const name = css`
  color: var(--color-black);
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-18);
  text-align: left;
  line-height: 2.03vh;
`;

const buttonSus = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 9vw;
  height: 3.33vh;
  background-color: var(--color-gold);
  margin: 1.5vh 0 0.8vh 0;
`;

const textSus = css`
  color: white;
  font-size: var(--size-16);
`;

const unSuscribedDiv = css`
  display: flex;
  justify-content: space-between;
  margin: 1.5vh 0 0.8vh 0;
`;

const unSuscribedTxt = css`
  font-size: var(--size-16);
`;

const unSuscribedPrmm = css`
  font-size: var(--size-17);
  color: var(--color-gold);
  font-family: var(--font-family-sans);
  font-style: italic;
`;

const profileBottom = css`
  text-align: left;
    margin-top: 6vh;
`;

const bottomTxt = css`
  margin-bottom: 1.1vh;
  font-size: var(--size-15);
  color: var(--color-black-02);
  font-family: var(--font-family-sans);
`;

const bottomLogout = css`
  background: transparent linear-gradient(261deg,#5833FF 0%,#175DF9 42%,#21FFCF 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  height: 4.16vh;
  display: inline-flex;
  width: 100%;
  padding-left: 1.15vw;
  color: white;
  align-items: center;
  font-size: var(--size-18);
  border: none;
`;

const buttonForm = css`
  text-align: left;
  font-family: var(--font-family-sans);
  color: var(--color-black-02);
  margin-top: 1px;
  font-size: var(--size-18);
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.1667vh;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;


  &:hover {
    color: var(--color-black-02);
    font-family: var(--font-family-sans);
    font-weight: 600;
  }
`;

const Info = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 53px;
  background: var(--color-gold);
  padding: 0 var(--cart-padding);
`;

const infoTxt = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  color: #FFFFFF;
  font-size: var(--size-15);
  text-align: left;
  line-height: var(--cart-info-height);
  b {
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-style: italic;
  }
`;

const profileImage = css`
  min-width: 5.55vh;
  min-height: 5.55vh;
  position: relative;
  border-radius: 50%;
`;

export default {
  profileImage,
  paperProfile,
  paperCart,
  ButtonProfile,
  tabs,
  tabsCart,
  background,
  Separator,
  Text,
  acrony,
  name,
  textSus,
  buttonSus,
  unSuscribedDiv,
  unSuscribedTxt,
  unSuscribedPrmm,
  profileBottom,
  bottomLogout,
  bottomTxt,
  buttonForm,
  Info,
  infoTxt,
};
