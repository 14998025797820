/* eslint-disable max-len */
import he from 'he';

const getMetaDescription = ({
  isFormation, trainingData, contentData, id,
}) => {
  const metaDesc = isFormation
    ? `${trainingData?.presentation?.replace(/<[^>]*>/g, '')
      .slice(0, 100)}... - Une formation ${trainingData?.themes?.[0]?.name?.toLowerCase()} proposée par ${trainingData?.coaches?.map(({ fullName }, i) => (i === trainingData.coaches.length - 1 ? fullName?.toLowerCase() : `${fullName?.toLowerCase()}, `))}. Happyneuron Academy, pour les orthophonistes.`
    : `${contentData?.html?.replace(/<[^>]*>/g, '')
      .slice(0, 100)}... - Un article ${contentData?.themes?.[0]?.name?.toLowerCase()} rédigé par ${contentData?.authors?.map(({ fullName }, i) => (i === contentData.authors.length - 1 ? fullName?.toLowerCase() : `${fullName?.toLowerCase()}, `))}. Happyneuron Academy, pour les orthophonistes.`;
  let metaDescription = isFormation
    ? trainingData?.seo?.metaDescription || metaDesc
    : contentData?.seo?.metaDescription || metaDesc;
  if (!id) metaDescription = "Happyneuron Academy, un espace dédié à votre profession d'orthophoniste. Accédez à une veille scientifique de pointe, développez vos compétences avec des formations de qualité sur des sujets au plus proche de votre métier et contribuez à une communauté pour échanger sur la pratique de l'orthophonie, sur des cas cliniques, des retours d'expérience, et bien d'autres choses encore !";
  metaDescription = he.decode(metaDescription);
  return metaDescription;
};

export default getMetaDescription;
