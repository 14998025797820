import styled, { css } from 'styled-components';

const Header = styled.header<any>`
  position: relative;
  width: 100vw;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: var(--color-white-01);
  border-bottom: 1px solid var(--color-black);
  height: var(--header-height);
  transition: transform 0.5s;
  
  ${(props) => (props.moveupandhide ? 'transform: translateY(-100%)' : '')}
`;

const divLogo = css`
  display: flex;
  margin: auto auto auto var(--layout-margin);
  height: 3vh;
    width: 11vw;
    cursor: pointer;
    position: relative;
`;

const UlRight = styled.ul<any>`
  display: flex;
  align-items: center;
  margin-left: ${({ user }) => (user ? '5.7291vw' : '0.7291vw')};

  & > * {
    margin-right: 1.30208vw;
  }

  & > :last-child {
    margin-right: 0;
  }

`;

const ConnectButton = styled.button<any>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 9.0625vw;
  line-height: 1;
  min-width: 125px;
  height: var(--header-height);
  background: var(--color-light-royal-blue);
  border: none;
  cursor: pointer;
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  color: white;
  margin-left: ${({ user }) => (user ? '9vw' : '3vw')};

  &:hover {
    font-family: var(--font-family-sans);
    /* font-weight: bold; */
  }
`;

const SigninButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 9.0625vw;
  line-height: 1;
  min-width: 125px;
  height: var(--header-height);
  background: white;
  border: none;
  cursor: pointer;
  font-family: var(--font-family-sans);
  /* font-weight: 600; */
  font-size: var(--size-21);
  line-height: 1;
  color: var(--color-light-royal-blue);

  &:hover {
    font-family: var(--font-family-sans);
    /* font-weight: bold; */
  }
`;

const menuBurger = css`
  width: 30px;
  height: 30px;
  position: relative;
  margin-left: var(--layout-margin);
  margin-right: auto;
`;

const background = css`
  width: 100vw;
  position: fixed;
  margin-top: var(--header-height);
  height: 100vh;
  left: 0;
  bottom: 0;
  background-color: #00000021;
  z-index: 7;
`;

const paper = css`
  position: absolute;
  top: var(--header-height);
  width: 100%;
  height: 38vh;
  z-index: 50;
  background-color: var(--color-white);
`;

const menulist = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  margin-left: var(--layout-margin);
  font-family: var(--font-family-serif);
  font-weight: bold;
`;

export default {
  Header,
  divLogo,
  UlRight,
  ConnectButton,
  SigninButton,
  menuBurger,
  background,
  paper,
  menulist,
};
