import { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { useRouter } from 'next/router';
import ButtonBack from 'components/ButtonBack';
import Catalogue from 'components/Catalogue';
import Events from 'components/Events';
import {
  CenterMain, ContentHome, ButtonBackPosition, loader, Background,
} from './style';
import { ThemesFiltersContextType } from 'providers/ThemesFilters';
import useThemesFilters from 'hooks/useThemesFilters';
import useAuth from 'hooks/useAuth';
import { SvgLogoSmallLoader } from 'components/Svgs';
import Error from 'pages/_error';
import Thematic from 'containers/Thematic';
import filterListByThemes from 'utils/filterListByThemes';
import sortBaughtTraining from 'utils/sortBaughtTraining';
import { isUserSubscribed } from 'utils/auth';
import { filterListLive } from 'utils/formatListByContentType';
import { DivFlex } from 'styles/common';
import useLaunchOffer from 'hooks/useLaunchOffer';
import useDatas from 'hooks/useDatas';

export interface AcademyProps {
  exploreAll?: any
  user?: any
  data?: any
  handleExploreAll?: any
  filters?: any
  contentData?: any
  trainingData?: any
  selectedForYou: any
  baughtTraining: any
  topTrainings: any
  topContents: any
  trainingList: any
  showcaseList: any
  premiumList: any
  events: any
  headbandHeight: any
}

const Academy: React.FC<AcademyProps> = ({
  exploreAll, data, handleExploreAll, headbandHeight,
}) => {
  const sliceSelection = 4;
  const { displayHeadband } = useLaunchOffer();
  // const today = Date.now() + 86400000 + 40400000 + 28400000;
  const today = Date.now();
  const {
    baughtTraining, events, selectedForYou, premiumList,
    showcaseList, topContents, trainingList, topTrainings, contentList,
  } = useDatas();

  const { mobile } = useContext(ThemeContext);
  const { filters }: ThemesFiltersContextType = useThemesFilters();
  const { user } = useAuth();
  const router = useRouter();
  const isDisplayed = (name) => !exploreAll || exploreAll === name;

  const isSubscribed = isUserSubscribed(user);

  const baughtTrainingSorted = sortBaughtTraining(baughtTraining);
  const liveTrainings = filterListLive(trainingList, exploreAll && filters, today);

  if (data?.error) return <Error statusCode={data.error} />;

  const tt = selectedForYou?.slice(0, sliceSelection);

  const hasHeadband = !user || displayHeadband;
  const filtersList = ([...(trainingList || []), ...(contentList || [])])
    ?.filter((el) => (
      new Date(el.publicationDate).getMonth() === new Date(today).getMonth()
  || new Date(el.creationDate).getMonth() === new Date(today).getMonth()
    ));
  const listProgrammeMonth = exploreAll ? filterListByThemes(filtersList, filters) : filtersList;

  return (
    <>
      {!mobile && exploreAll && <Thematic exploreAll={exploreAll} />}
      <ContentHome exploreAll={exploreAll} hasHeadband={hasHeadband}>
        {exploreAll && (
        <ButtonBackPosition hasHeadband={hasHeadband}>
          <ButtonBack
            category="training"
            onClick={() => {
              router.query.all = [];
              router.push(router, undefined, { shallow: true });
              handleExploreAll(null);
            }}
          />
        </ButtonBackPosition>
        )}

        <CenterMain
          isExtended={exploreAll}
          hasThematic={!mobile && exploreAll}
          hasHeadband={hasHeadband}
          headbandHeight={headbandHeight}
        >
          {!exploreAll && <Background bgHome="mon-academy" />}
          <DivFlex>
            {selectedForYou && isDisplayed('selectedForYou')
              && (
              <Catalogue
                category="mon-academy"
                user={user}
                name="selectedForYou"
                unClickable
                title="Sélectionnés pour vous"
                list={tt}
              />
              )}
            {!exploreAll && events && !mobile && <Events events={events} />}
          </DivFlex>

          {liveTrainings?.length && isDisplayed('liveTrainings') ? (
            <Catalogue
              user={user}
              name="liveTrainings"
              onClick={() => handleExploreAll('liveTrainings')}
              title="En direct !"
              list={exploreAll ? filterListByThemes(liveTrainings, filters) : liveTrainings}
              isExtended={exploreAll === 'liveTrainings'}
              category="mon-academy"
            />
          ) : null}

          {baughtTraining?.length && isDisplayed('baughtTraining') ? (
            <Catalogue
              user={user}
              name="baughtTraining"
              onClick={() => handleExploreAll('baughtTraining')}
              title="Mes formations achetées"
              list={exploreAll ? filterListByThemes(baughtTrainingSorted, filters) : baughtTrainingSorted}
              isExtended={exploreAll === 'baughtTraining'}
              category="mon-academy"
            />
          ) : null}

          {showcaseList?.length && isDisplayed('showcaseList') ? (
            <Catalogue
              user={user}
              name="showcaseList"
              onClick={() => handleExploreAll('showcaseList')}
              title="Zoom sur..."
              list={exploreAll ? filterListByThemes(showcaseList, filters) : showcaseList}
              isExtended={exploreAll === 'showcaseList'}
              category="mon-academy"
            />
          ) : null}

          {listProgrammeMonth?.length && isDisplayed('listProgrammeMonth') ? (
            <Catalogue
              user={user}
              name="listProgrammeMonth"
              onClick={() => handleExploreAll('listProgrammeMonth')}
              title="À la une ce mois-ci"
              list={listProgrammeMonth}
              isExtended={exploreAll === 'listProgrammeMonth'}
              category="mon-academy"
            />
          ) : null}

          {isSubscribed && premiumList?.length && isDisplayed('premiumList') ? (
            <Catalogue
              user={user}
              name="premiumList"
              onClick={() => handleExploreAll('premiumList')}
              title="Réservés aux abonnés"
              list={exploreAll ? filterListByThemes(premiumList, filters) : premiumList}
              isExtended={exploreAll === 'premiumList'}
              category="mon-academy"
            />
          ) : null}

          {topTrainings?.length && isDisplayed('topTrainings') ? (
            <Catalogue
              user={user}
              name="topTrainings"
              onClick={() => handleExploreAll('topTrainings')}
              title="Top 10 des formations"
              list={exploreAll ? filterListByThemes(topTrainings, filters) : topTrainings}
              isExtended={exploreAll === 'topTrainings'}
              category="mon-academy"
            />
          ) : null}

          {topContents?.length && isDisplayed('topContents') ? (
            <Catalogue
              user={user}
              name="topContents"
              onClick={() => handleExploreAll('topContents')}
              title="Top 10 des informations"
              list={exploreAll ? filterListByThemes(topContents, filters) : topContents}
              isExtended={exploreAll === 'topContents'}
              category="mon-academy"
            />
          ) : null}

          {!isSubscribed && premiumList?.length && isDisplayed('premiumList') ? (
            <Catalogue
              user={user}
              name="premiumList"
              onClick={() => handleExploreAll('premiumList')}
              title="Réservés aux abonnés"
              list={exploreAll ? filterListByThemes(premiumList, filters) : premiumList}
              isExtended={exploreAll === 'premiumList'}
              category="mon-academy"
            />
          ) : null}

          {!topTrainings?.length ? (
            <div css={loader}>
              <SvgLogoSmallLoader />
            </div>
          ) : null}

        </CenterMain>
      </ContentHome>
    </>
  );
};

export default Academy;
