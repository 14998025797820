import styled, { css } from 'styled-components';

const title = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-26);
  font-weight: bold;
  line-height: 0.92;
  text-align: center;
  color: #262626;
`;

const image = css`
    position: relative;
    width: 10vw;
    height: 10vh;
`;

const image1 = css`
    position: relative;
    width: 2vw;
    margin: 0 3vw;
`;

const image2 = css`
    position: relative;
    width: 6.615vw;
    height: 8.6vh;
`;

const description = css`
font-family: var(--font-family-serif);
  font-size: var(--size-18);
  line-height: 1.33;
  text-align: center;
  color: #262626;
  margin-bottom: 4vh;
`;

const DivEgnosia = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4vh;
`;

const imgVersus = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4vh 0;
`;

export default {
  title,
  image,
  image1,
  image2,
  description,
  DivEgnosia,
  imgVersus,
};
