import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Button from 'components/Button';
import SC from './style';

export interface DiscoverProps {
  type?: any
}

const Discover: React.FC<DiscoverProps> = ({ type }) => {
  const router = useRouter();
  return (
    <div css={SC.advantages}>
      <div css={SC.advantageHead}>
        <div css={SC.discoverHead}>
          <Image
            height="26"
            width="26"
            src="/infos2.svg"
            alt="thumbnail"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <p css={SC.advantageTitle}>Découvrez l&apos;Happyneuron Academy !</p>
        </div>
        <p css={SC.advantageTxt}>Profitez d&apos;une multitude de contenus scientifiques, de formations et d&apos;échanges professionnels</p>
      </div>
      <ul css={SC.listDots}>
        <li css={SC.listDot1}>
          Des contenus professionnels et validés par des orthophonistes
        </li>
        <li css={SC.listDot2}>
          Une sélection de formations animées par des experts et s&apos;adaptant à tous les rythmes et toutes les disponibilités
        </li>
        <li css={SC.listDot3}>
          Un espace d&apos;échange réservé aux orthophonistes pour aborder des problématiques concrètes de la profession
        </li>
        <Button value="S’inscrire gratuitement" onClick={() => router.push('/connect/signup')} />
        <a href="https://accueil.happyneuron.academy/" target="__blank">
          <p css={SC.reservedOrtho}>En savoir plus</p>
        </a>
      </ul>
    </div>
  );
};

export default Discover;
