import styled from 'styled-components';

const Vendor = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  width: ${({ theme: { mobile } }) => (mobile ? 'auto' : '19.58vw')};
  height: 13.98vh;
  position: absolute;
  background: red;
  bottom: 0;
  z-index: 100;
  right: 0;
  box-shadow: 0 3px 27px 0 rgb(0 0 0 / 33%);
  background-color: #fbfbfb;
  margin-bottom: 8.5185vh;
  margin-right: 1.719vw;
  padding: 1.04vh;
  text-align: center;
  
  p {
    color: var(--color-black);
    line-height: 1.2;
    font-family: var(--font-family-serif);
    font-size: var(--size-16);
  }
`;

export default {
  Vendor,
};
