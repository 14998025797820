import { SvgBack, SvgBackAcademy } from 'components/Svgs';
import SC from './style';

export interface ButtonBackProps {
  onClick: () => void
  category?: any
}

const ButtonBack: React.FC<ButtonBackProps> = ({ onClick, category }) => (
  <SC.ButtonBack onClick={onClick}>
    {category === 'mon-academy' ? <SvgBackAcademy /> : <SvgBack category={category} />}
    <span css={SC.backText}>Retour</span>
  </SC.ButtonBack>
);

export default ButtonBack;
