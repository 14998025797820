/* eslint-disable max-len */
import BulleCounter from 'components/BulleCounter';
import {
  ctaSearch, DivButtonIcon,
} from './style';

export interface ButtonIconProps {
  icon?: React.ReactElement
  hasHover?: boolean
  cartLen?: number
  size?: string
  bgColor?: string
  onClick?: any
  hasCta?: any
  cta?: any
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  hasCta, cartLen, icon, cta, ...rest
}) => (
  <DivButtonIcon {...rest}>
    {icon}
    {cartLen ? (
      <BulleCounter count={cartLen} />
    ) : null}
    {hasCta && (
    <div id="cta-search" css={ctaSearch}>
      <p>{cta}</p>
    </div>
    )}
  </DivButtonIcon>
);

export default ButtonIcon;
