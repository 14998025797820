// context part
import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { useRouter } from 'next/router';
import useAuth from 'hooks/useAuth';
import useDatas from 'hooks/useDatas';
import formatTitleAsUrl from 'utils/formatTitleAsUrl';

interface VideoProps {
  children: ReactNode
}

export type VideoContextType = {
  video: any
  prevVideo: any
  nextVideo: any
  fixedVideo: any
  setVideo: any
  setFixedVideo: any
}

const videoInfo = {
  id: null,
  show: null,
  url: false,
  asPath: null,
  currentTime: 0,
};

const initialContext = {
  video: videoInfo,
  prevVideo: videoInfo,
  nextVideo: videoInfo,
  fixedVideo: videoInfo,
  setVideo: () => {},
  setFixedVideo: () => {},
  setNextVideo: () => {},
};

export const VideoContext = createContext<VideoContextType>(initialContext);

const VideoProvider = ({ children }: VideoProps) => {
  const [video, setVideo] = useState(initialContext.video);
  const [prevVideo, setPrevVideo] = useState(initialContext.video);
  const [nextVideo, setNextVideo] = useState(initialContext.video);
  const [fixedVideo, setFixedVideo] = useState(initialContext.fixedVideo);
  const router = useRouter();

  const { isSubscribed } = useAuth();
  const { formattedContentsList } = useDatas();
  const { user } = useAuth();

  useEffect(() => {
    const indexVideo = formattedContentsList?.[fixedVideo.show]
      ?.findIndex((el) => el.id === fixedVideo.id);

    const prev = formattedContentsList?.[fixedVideo.show]?.[indexVideo + 1];
    if (prev) {
      if (!isSubscribed && prev.premium) return undefined;
      setPrevVideo({
        id: prev?.id,
        url: prev?.metadata?.videoLink,
        show: prev?.tags[0].name,
        asPath: `/informations/${formatTitleAsUrl({ title: prev.title, id: prev.id })}`,
        currentTime: 0,
      });
    } else setPrevVideo(initialContext.prevVideo);
    const next = formattedContentsList?.[fixedVideo.show]?.[indexVideo - 1];
    if (next) {
      if (!isSubscribed && next.premium) return undefined;
      setNextVideo({
        id: next?.id,
        url: next?.metadata?.videoLink,
        show: next?.tags[0].name,
        asPath: `/informations/${formatTitleAsUrl({ title: next.title, id: next.id })}`,
        currentTime: 0,
      });
    } else setNextVideo(initialContext.nextVideo);
    return undefined;
  }, [formattedContentsList, fixedVideo, router.asPath, video, isSubscribed]);

  useEffect(() => {
    if (!user) {
      setVideo(initialContext.video);
      setPrevVideo(initialContext.video);
      setNextVideo(initialContext.video);
      setFixedVideo(initialContext.video);
    }
  }, [user]);

  return (
    <VideoContext.Provider value={{
      video, setVideo, fixedVideo, setFixedVideo, prevVideo, nextVideo,
    }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export default VideoProvider;
