import styled, { css } from 'styled-components';

const preferences = css`
  position: absolute;
  top: 0;
  z-index: 40;
  display: flex;
  width: 100vw;
  height: ${({ theme: { mobile } }) => (mobile ? '100vh' : 'calc(100vh - var(--footer-height))')};
  background-color: var(--color-white-01);
  padding: 2.5vh var(--layout-margin);
  overflow: auto;
`;

const logo = css`
  position: absolute;
`;

const close = css`
  width: 2.4vw;
  height: 2.4vw;
  position: fixed;
  right: 0;
  right: 5.5vh;
  top: 5.5vh;
  cursor: pointer;
`;

const Content = styled.div<any>`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme: { mobile } }) => !mobile && '11vw'};
  margin-top: 2.5vh;
  width: ${({ theme: { mobile } }) => (!mobile ? '77%' : '100%')};
  padding: ${({ theme: { mobile } }) => mobile && '0 3vw'};
`;

const title = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  color: #575757;
  font-size: var(--size-36);
  margin-bottom: 2vh;
`;

const interest = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  color: #575757;
  font-size: var(--size-21);
  line-height: 2.5vh;
  margin-right: 3vw;
`;

const divInterest = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3vh;
`;

const grid = css`
  display: grid;
  grid-template-columns: ${({ theme: { mobile } }) => (mobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)')};
  grid-gap: 4vh;
  height: 55vh;
  overflow: auto;
`;

const fading = css`
  height: 8vh;
  background: transparent linear-gradient(180deg,var(--color-white-01) 0%,#F5F5F5FC 26%,#F5F5F5D6 45%,#F5F5F500 100%) 0% 0% no-repeat padding-box;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  position: absolute;
  bottom: -1px;
  z-index: 1000;
  width: 100%;
`;

const Button = styled.button<any>`
  min-width: 15.520833333333334vw;
  height: 9.074074074074074vh;
  cursor: pointer;
  border-radius: 5px;

 

  ${({ selected }) => (selected ? css`
      background-color: var(--color-black);
      border: none;
    ` : css`
        &:hover {
        background-color: var(--color-gray-01);
        border: 2px solid var(--color-gray-01);
        span {
          color: var(--color-black);
        }
      }
      background-color: #FFFFFF;
      border: 2px solid #707070;
    `)}

  span {
    font-size: var(--size-21);
    line-height: 2.5vh;

    ${({ selected }) => (selected ? css`
      color: var(--color-greenish-cyan);
    ` : css`
      color: var(--color-black);
    `)}

  }
`;

const proposeThemes = css`
  color: var(--color-black-02);
  font-size: var(--size-18);
  line-height: 1.3;
  margin-right: 2px;
`;

const linkForm = css`
  color: #5833FF;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const findIt = css`
  font-size: var(--size-24);
  color: #575757;
  line-height: 1.3;
  margin-bottom: 2.6vh;
`;

const buttons = css`
  /* position: fixed;
  bottom: var(--footer-height);
  right: var(--layout-margin); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  z-index: 1100;
  /* margin-bottom: 2vh; */
  & > * {
    margin-bottom: var(--height-s);
  }

  position: ${({ theme: { mobile } }) => (mobile ? 'fixed' : 'absolute')};
  right: 1vw;
  bottom: 0;
`;

const valided = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11.229vw;
  height: 5.66vh;
  background: var(--color-black) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000070;
  margin: 0 auto 2.5vh auto;
  cursor: pointer;

  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-18);
  color: white;
  border: none;


  &:hover {
    color: white;
  }
`;

const passed = css`
cursor: pointer;
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-21);
  color: #575757;
  border: none;
  background: none;

  &:hover {
    text-decoration: underline;
  }
`;

const flexCenter = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 7.407407407407407vh;
  margin-right: 1vw;
  margin-bottom: 1vh;

  span { 
    font-size: var(--size-21);
  }
`;

const fadingWrap = css`
  position: relative;
  height: 100%;
`;

const fadingTop = css`
  height: 7vh;
  background: transparent linear-gradient(180deg,var(--color-white-01) 0%,#F5F5F5FC 26%,#F5F5F5D6 45%,#F5F5F500 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: -1px;
  z-index: 1000;
  width: 100%;
`;

const loader = css`
  width: 100%;
  height: 60%;
`;

export default {
  preferences,
  logo,
  fadingTop,
  fading,
  loader,
  fadingWrap,
  Content,
  title,
  interest,
  divInterest,
  grid,
  Button,
  proposeThemes,
  findIt,
  buttons,
  valided,
  passed,
  linkForm,
  flexCenter,
  close,
};
