import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import ButtonBack from 'components/ButtonBack';
import Catalogue from 'components/Catalogue';
import Events from 'components/Events';
import {
  Background,
  ButtonBackPosition,
  CenterMain, ContentHome, loader,
} from './style';
import { ThemesFiltersContextType } from 'providers/ThemesFilters';
import useThemesFilters from 'hooks/useThemesFilters';
import useAuth from 'hooks/useAuth';
import Error from 'pages/_error';
import { SvgLogoSmallLoader } from 'components/Svgs';
import sortBaughtTraining from 'utils/sortBaughtTraining';
import { filterListMoment, formatListByFirstTheme } from 'utils/formatListByContentType';
import { DivFlex } from 'styles/common';
import useLaunchOffer from 'hooks/useLaunchOffer';
import getIdsFromUrl from 'utils/getIdsFromUrl';

const Training = dynamic(() => import('containers/Training'), {
  ssr: false,
});
export interface FormationsProps {
  exploreAll?: any
  data?: any
  headbandHeight: any
  trainingData?: any
  handleExploreAll?: any
  selectedForYou: any
  baughtTraining: any
  events: any
  showcaseList: any
  handleCloseModal: any
}

const Formations: React.FC<FormationsProps> = ({
  exploreAll, data,
  handleExploreAll, selectedForYou, baughtTraining,
  events, showcaseList, trainingData, headbandHeight,
  handleCloseModal,
}) => {
  const { user } = useAuth();
  const { mobile } = useContext(ThemeContext);
  const router = useRouter();
  const { query } = router;
  const { displayHeadband } = useLaunchOffer();

  const id = getIdsFromUrl(query?.home?.[1]);

  const { themes } = useThemesFilters();
  const { filters }: ThemesFiltersContextType = useThemesFilters();

  const listOrderer = themes?.filter((el) => el.id !== 0)?.map((el) => el.name);
  const sliceSelection = 3;

  const isDisplayed = (name) => !exploreAll || exploreAll === name;

  const filterList = !filters || (filters?.length === 1 && filters[0].id === 0)
    ? data
    : data
      ?.filter((el) => el.themes.filter((t) => filters?.find((e) => e.id === t.id)).length);

  const today = Date.now();

  const showcaseListFiltered = showcaseList && !showcaseList?.error && showcaseList?.filter((el) => el.displayType);

  const filteredListMoment = filterListMoment(data, filters, today);

  const filterListBaught = !filters || (filters?.length === 1 && filters[0].id === 0)
    ? baughtTraining
    : baughtTraining
      ?.filter((el) => el.themes.filter((t) => filters?.find((e) => e.id === t.id)).length);

  const sortFilterListBaught = sortBaughtTraining(baughtTraining);

  const formattedList = filterList && formatListByFirstTheme(filterList);

  // const listProgrammeMonth = filterList?.filter((el) => (new Date(el.creationDate).getMonth() === new Date(today).getMonth()));

  if (data?.statusCode) return <Error statusCode={data.statusCode} />;

  const hasHeadband = !user || displayHeadband;

  return (
    <ContentHome exploreAll={exploreAll} mobile={mobile} hasHeadband={hasHeadband}>

      {id ? (
        <Training
          data={trainingData}
          loading={trainingData?.id !== id}
          handleClose={handleCloseModal}
        />
      ) : null}

      {exploreAll && (
      <ButtonBackPosition hasHeadband={hasHeadband}>
        <ButtonBack
          category="training"
          onClick={() => {
            router.query.all = [];
            router.push(router, undefined, { shallow: true });
            handleExploreAll(null);
          }}
        />
      </ButtonBackPosition>
      )}

      <CenterMain
        headbandHeight={headbandHeight}
        isExtended={exploreAll}
        hasThematic={!mobile}
        hasHeadband={hasHeadband}
      >
        {!exploreAll && <Background bgHome="formations" />}
        <DivFlex>
          {selectedForYou && isDisplayed('selection')
              && (
              <Catalogue
                user={user}
                name="selection"
                unClickable
                title="Les formations sélectionnées pour vous"
                list={selectedForYou?.filter((el) => !el.contentType).slice(0, sliceSelection)}
                category="training"
              />
              )}
          {!exploreAll && events && !mobile && <Events events={events} />}
        </DivFlex>

        {filterListBaught?.length && isDisplayed('baughtTraining') ? (
          <Catalogue
            user={user}
            name="baughtTraining"
            onClick={() => handleExploreAll('baughtTraining')}
            title="Mes formations achetées"
            list={sortFilterListBaught}
            isExtended={exploreAll === 'baughtTraining'}
            category="training"
          />
        ) : null}

        {/* {listProgrammeMonth?.length && isDisplayed('listProgrammeMonth') ? (
          <Catalogue
            user={user}
            name="listProgrammeMonth"
            onClick={() => handleExploreAll('listProgrammeMonth')}
            title="À la une ce mois-ci"
            list={listProgrammeMonth}
            isExtended={exploreAll === 'listProgrammeMonth'}
            category="training"
          />
        ) : null} */}

        {showcaseListFiltered?.length && isDisplayed('showcaseList') ? (
          <Catalogue
            user={user}
            name="showcaseList"
            onClick={() => handleExploreAll('showcaseList')}
            title="Zoom sur des formations"
            list={showcaseListFiltered}
            isExtended={exploreAll === 'showcaseList'}
            category="training"
          />
        ) : null}

        {filteredListMoment?.length && isDisplayed('newFormations') ? (
          <Catalogue
            user={user}
            name="newFormations"
            onClick={() => handleExploreAll('newFormations')}
            title="Les formations du moment"
            list={filteredListMoment}
            isExtended={exploreAll === 'newFormations'}
            category="training"
          />
        ) : null}

        {formattedList && listOrderer.map((value) => formattedList?.[value] && isDisplayed(value)
                && (
                  <Catalogue
                    key={value}
                    user={user}
                    name={value}
                    onClick={() => handleExploreAll(value)}
                    title={value}
                    list={formattedList[value]}
                    isExtended={exploreAll === value}
                    category="training"
                  />
                ))}

        {!data?.length && (
        <div css={loader}>
          <SvgLogoSmallLoader />
        </div>
        )}

      </CenterMain>
      {/* {mobile && <Footer />} */}
    </ContentHome>
  );
};

export default Formations;
