const getHumanMinutes = (d) => {
  if (!d) return '';
  const date = new Date(d);
  const minutes = date?.getMinutes();

  if (minutes === 0) return '00';
  if (minutes < 10) return `0${minutes}`;
  return minutes;
};

export default getHumanMinutes;
