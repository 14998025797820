const AuthorsWithCardHover = ({ content, setCardCoach }) => content.authors.map((a, i) => {
  if (i !== content.authors.length - 1) {
    return (
      <span key={a.id} onMouseEnter={() => setCardCoach(a.id)}>
        {a.fullName?.toLowerCase()}
        ,
        {' '}
      </span>
    );
  }
  return (
    <span
      key={a.id}
      onMouseEnter={() => setCardCoach(a.id)}
    >
      {a.fullName?.toLowerCase()}
    </span>
  );
});

export default AuthorsWithCardHover;
