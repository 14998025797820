import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import getIdsFromUrl from 'utils/getIdsFromUrl';
import SC from './style';

import ButtonBack from 'components/ButtonBack';
import Catalogue from 'components/Catalogue';
import useAuth from 'hooks/useAuth';
import { getPermanentUrl } from 'utils/url';
import Logo from 'components/Logo';
import Error from 'pages/_error';
import hnaRequest from '../../utils/hnaRequest';

interface ProductsProps {
  handleClose: any
}

const Products: React.FC<ProductsProps> = ({ handleClose }) => {
  const [product, setProduct] = useState(null);
  const router = useRouter();
  const id = getIdsFromUrl(router.asPath);
  const { user } = useAuth();

  useEffect(() => {
    const fetchProductFn = async () => {
      try {
        const res = await hnaRequest('get', `product/get/${id}`, undefined);
        setProduct(res?.products?.[0]);
      } catch (error) {
        console.error({ error });
        setProduct(error?.response?.data);
      }
    };
    fetchProductFn();
  }, [id]);

  if ([404, 500].includes(product?.statusCode)) return <Error statusCode={product.statusCode} />;

  return (
    <div className="scrollbar" css={SC.products}>
      <div css={SC.header}>
        <Logo />
      </div>
      <div css={SC.divInfos}>
        <div css={SC.backWrap}>
          <ButtonBack category="content" onClick={handleClose} />
        </div>
        <div css={SC.blockInfoTxt}>
          <p css={SC.productTitle}>{product?.title}</p>
          <p css={SC.productDesc}>{product?.description}</p>
          {product?.active && (
          <a
            target="_blank"
            href={product?.buttonLink || 'https://www.happyneuronpro.com/orthophonie/essai-hnpro-orthophonie/'}
            css={SC.buttonDemo}
            rel="noreferrer"
          >
            {product?.buttonTitle || 'Demander une Démo'}
          </a>
          )}
        </div>
        {product?.image && (
          <>
            <div css={SC.bgImage}>
              <Image src={getPermanentUrl(product.image)} alt="bg" fill sizes="100vw" />
            </div>
            {/* <div css={SC.bgImage} /> */}
          </>
        )}
      </div>

      <div css={SC.catalog}>
        {product?.trainings?.length ? (
          <Catalogue
            user={user}
            name={`Formations en lien avec ${product?.title}`}
            title={`Formations en lien avec ${product?.title}`}
            list={product?.trainings}
            unClickable
          />
        ) : null}
        {product?.contents?.length ? (
          <Catalogue
            user={user}
            name={`Contenus en lien avec ${product?.title}`}
            title={`Contenus en lien avec ${product?.title}`}
            list={product?.contents}
            unClickable
          />
        ) : null}
      </div>
    </div>
  );
};

export default Products;
