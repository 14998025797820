/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const NavMenu = styled.nav`
  position: relative;
  /* max-width: 900px; */
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: auto 0 auto 0;
`;

const AnchorItem = styled.a`
  height: 100%;
  width: 11vw;
  display: flex;
  padding-top: 2.222vh;
  justify-content: center;
  align-items: flex-start;
  z-index: 6;
`;

const SpanTitle = styled.span<any>`
  cursor: pointer;
  color: var(--color-gray-warm);
  line-height: 1.76vh;
  font-family: var(--font-family-sans);
  font-weight: 300;
  font-size: var(--size-21);
  user-select: none;

  ${({ selected }) => selected && css`
    color: var(--color-black);
  font-family: var(--font-family-sans);
  font-weight: 600;
    font-size: var(--size-21);
  `}
  
  ${AnchorItem}:hover & {
    color: var(--color-black);
  font-family: var(--font-family-sans);
  font-weight: 600;
    font-size: var(--size-21);
  }
`;

const DivDot = styled.div`
  width: 16px;
  height: 16px;
  background: var(--color-black-02);
  position: absolute;
  bottom: -8px;
  border-radius: 50%;
  z-index: 40;
`;

const ImgDot = styled.img`
  cursor: pointer;
  position: absolute;
  bottom: -27px;
`;

const DivIcon = styled.div<any>`
  position: absolute;
  top: 4.5vh;
  z-index: ${({ selected }) => (selected ? '40' : '1')};

  transition-duration: 0.2s;
`;

const menuItem = css`

`;

export default {
  NavMenu,
  AnchorItem,
  SpanTitle,
  DivDot,
  ImgDot,
  DivIcon,
  menuItem,
};
