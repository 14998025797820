import { useEffect } from 'react';
import SC from '../Signup/style';
import useAuth from 'hooks/useAuth';
import { SvgLogoSmallLoader } from 'components/Svgs';

const LoginWithToken = ({ token }) => {
  const index = token?.indexOf('?t=');
  const goodToken = token && index !== -1 ? token.slice(0, index) : token;

  const { autoLogin } = useAuth();

  useEffect(() => {
    if (goodToken?.length) {
      autoLogin(goodToken);
    }
  }, []);

  return (
    <SC.DivAutoLogin>
      <div css={SC.autoLoginMessage}>
        <p>Votre connexion sécurisée est en cours.</p>
        <p>Plus que quelques secondes, l'Academy ouvre ses portes...</p>
        <div css={SC.loaderAutoSignin}>
          <SvgLogoSmallLoader />
        </div>
        <p>Vous allez être redirigé vers la page d'accueil.</p>
      </div>
    </SC.DivAutoLogin>
  );
};

export default LoginWithToken;
