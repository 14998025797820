import styled, { css } from 'styled-components';

const tabsCart = css`
  position: absolute;
  width: 21.71875vw;
  top: var(--header-height);
  right: var(--layout-margin);
  z-index: 50;
`;

const Info = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 53px;
  background: var(--color-gold);
  padding: 0 var(--cart-padding);
`;

const infoTxt = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  color: #FFFFFF;
  font-size: var(--size-15);
  text-align: left;
  line-height: var(--cart-info-height);
  b {
    font-family: var(--font-family-sans);
    font-weight: 600;
    font-style: italic;
  }
`;

const wrapCart = css`
  width: 100%;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  cursor: default;
  border-radius: 10px;
  /* padding: 1.1vh 0 0 0; */
`;

const iconLight = css`
  min-width: 1.46vw;
  height: 1.46vw;
  position: relative;
  margin-right: var(--cart-padding);
`;

const itemList = css`
  padding: 0 0 3.43vh 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const crowlableList = css`
  display: flex;
  flex-direction: column;
  max-height: 32vh;
  overflow-y: auto;
  padding: 0 var(--cart-padding);
`;
const item = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const itemTitle = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-19);
  color: var(--color-black);
  margin-top: 1.4vh;
  margin-bottom: 0.6vh;
  text-align: left;
`;
const itemContent = css`
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
`;
const itemDesc = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-15);
  color: var(--color-black);
  width: 13.6vw;
  line-height: 1.4;
`;
const itemTotal = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-20);
  color: var(--color-black);
  margin-top: auto;
  margin-bottom: auto;
`;

const separator = css`
  background-color: #C8C8C8;
  height: 1px;
  width: 100%;
  margin-top: 1.4vh;
`;
const total = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 3.43vh var(--cart-padding) 0 auto;
  min-width: 9vw;
`;
const totalLabel = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-24);
  color: var(--color-black);
  line-height: 0.8;
`;
const totalPrice = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-30);
  color: var(--color-black);
  line-height: 0.8;
  padding-left: 1vw;
`;

const paid = css`
  background-color: var(--color-black-02);
  width: calc(100% - (var(--cart-padding)*2));
  min-height: 5.1vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
  margin: 0 var(--cart-padding);
  margin-top: 3.43vh;

  /* &:hover {
  background-color: white;
  border: 1px solid var(--color-black-02);
  p {
    color: var(--color-black-02);
  }
  } */
`;

const paidTxt = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-18);
  color: white;
  line-height: 1.2;
  padding: 0.5vh 0;
`;

const logoTrash = css`
  position: relative;
  width: 2vw;
  height: 2vw;
  margin-bottom: -2vw;
  margin-left: auto;
  top: 0.8vh;
  cursor: pointer;
`;

const emptyCart = css`
  margin: 2.2vw 0vw;
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-19);
  line-height: 1.15;
  color: var(--color-gray);
  padding: 0 1vw;
  text-align: center;
`;

export default {
  tabsCart,
  wrapCart,
  iconLight,
  infoTxt,
  itemList,
  crowlableList,
  item,
  logoTrash,
  itemTitle,
  itemContent,
  itemDesc,
  itemTotal,
  separator,
  total,
  totalLabel,
  totalPrice,
  paidTxt,
  emptyCart,
  Info,
  paid,
};
