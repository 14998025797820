import { css } from 'styled-components';

const tuto = css`
  width: 100%;
  height: calc(100vh - var(--footer-height));
  position: absolute;
  top: 0;
  z-index: 500;
  background-color: #f5f5f5;
`;

const close = css`
  width: 2.55vw;
  height: 2.55vw;
  position: fixed;
  right: 2.3vw;
  margin-top: 2.4%;
  cursor: pointer;
`;

const logo = css`
  position: absolute;
  width: 10.78vw;
  height: 4.7vh;
  margin: 4vh 0 1.85vh 2.5vw;
`;

const content = css`
  width: 66%;
  margin: auto;
  padding-top: 10vh;
`;

const title = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-36);
  line-height: 1.25;
  text-align: left;
  color: #575757;
  margin-bottom: 5vh;
`;

const innerHtml = css`
  height: 75vh;
  overflow-y: auto;
`;

export default {
  close,
  tuto,
  content,
  title,
  logo,
  innerHtml,
};
