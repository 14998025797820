import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import Dropdown from 'containers/DropdownMenu';
import SC from './style';
import {
  SearchIcon,
  CollectionIcon, ShopIcon, SvgAcademy, SvgInformer, SvgSeFormer, SvgEchanger,
} from '../../components/Svgs';
import Menu from '../../components/Menu';
import ButtonIcon from 'components/ButtonIcon';
import useAuth from 'hooks/useAuth';
import useCart from 'hooks/useCart';
import useHandleKeyPress from 'hooks/useHandleKeyPress';
import Logo from 'components/Logo';
import useHistory from 'hooks/useHistory';
import useExchanges from 'containers/Exchanges/hooks/useExchanges';

export interface HeaderProps {
  showSearch: boolean;
  setOpenModal: (name: string) => void
  handleOpenSearch: () => void;
  setCurrentStep: (step: number) => void;
}

const menuProps = [
  {
    path: '/mon-academy',
    title: 'Mon academy',
    SvgIcon: SvgAcademy,
  },
  {
    path: '/informations',
    title: "S'informer",
    SvgIcon: SvgInformer,
  },
  {
    path: '/formations',
    title: 'Se former',
    SvgIcon: SvgSeFormer,
  },
  {
    path: '/echanges',
    title: 'Échanger',
    SvgIcon: SvgEchanger,
  },
];

const Header: React.FC<HeaderProps> = ({
  showSearch,
  handleOpenSearch, setCurrentStep, setOpenModal,
}) => {
  const { user } = useAuth();
  const { mobile } = useContext(ThemeContext);
  const [openBurger, setOpenBurger] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const router = useRouter();
  const { list, newItemAdded, setNewItemAdded } = useCart();
  const handleNav = () => {
    document.getElementById('main').scrollTop = 0;
  };

  const { home } = useHistory();

  const { state: { channels } } = useExchanges();

  const countUnRead = channels.reduce((acc, value) => acc + value.userRead.unreadUserMessage, 0);

  useHandleKeyPress('Escape', () => setOpenDropdown(null)); // escapeKey

  const getCurrentPath = (path: string) => path === `/${home}`;

  useEffect(() => {
    if (newItemAdded) {
      setOpenDropdown('cart');
    }
  }, [newItemAdded, setNewItemAdded]);

  return (
    <SC.Header moveupandhide={showSearch}>
      {!mobile && (
        <Logo />
      )}

      {!mobile && (
        <Menu
          handleNav={handleNav}
          getCurrentPath={getCurrentPath}
          menuProps={menuProps}
          countUnRead={countUnRead}
        />
      )}

      {mobile && (
        <div
          role="presentation"
          css={SC.menuBurger}
          onClick={() => setOpenBurger(!openBurger)}
        >
          <Image src="/menu.svg" alt="menu" fill sizes="100vw" />
        </div>
      )}

      {openBurger && (
        <>
          <div
            css={SC.background}
            onClick={() => setOpenBurger(false)}
            role="presentation"
          />
          <div css={SC.paper}>
            <div
              role="presentation"
              css={SC.menulist}
              onClick={() => setOpenBurger(false)}
            >
              {menuProps.map((el) => (
                <div
                  key={el.title}
                  role="presentation"
                  onClick={handleNav}
                >
                  <Link href={el.path} shallow legacyBehavior>
                    {el.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <SC.UlRight user={Boolean(user)}>
        <li>
          <ButtonIcon
            onClick={() => user && handleOpenSearch()}
            hasHover
            hasCta={!user}
            cta="La recherche n'est pas disponible en mode non-connecté, connectez-vous gratuitement pour faire une recherche dans toute l'Academy&#160;!"
            size="5vh"
            icon={<SearchIcon />}
          />
        </li>
        {/* {user && (
        <li>
          <ButtonIcon
            hasHover
            hasCta
            cta="Ici, retrouvez très prochainement vos collections personnelles de contenus et vos favoris&#160;!"
            size="5vh"
            icon={<CollectionIcon />}
          />
        </li>
      )} */}
        {user && (
          <li>
            <ButtonIcon
              onClick={() => setOpenDropdown('cart')}
              cartLen={list?.items?.length}
              hasHover
              size="5vh"
              icon={<ShopIcon />}
            />
          </li>
        )}
      </SC.UlRight>

      {user && (
        <Dropdown
          setCurrentStep={setCurrentStep}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
          setOpenModal={setOpenModal}
        />
      )}

      {!user && (
        <>
          <SC.ConnectButton
            user={Boolean(user)}
            type="button"
            role="link"
            onClick={() => router.push('/connect/login')}
          >
            Je me connecte

          </SC.ConnectButton>
          <SC.SigninButton
            type="button"
            role="link"
            onClick={() => router.push('/connect/signup')}
          >
            Je m'inscris gratuitement
          </SC.SigninButton>
        </>
      )}
    </SC.Header>
  );
};

export default Header;
