import { getPermanentUrl } from './url';

export const getMeta = ({
  title, description, url, image,
}) => (
  <>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="article" />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="og:site_name" content="Happyneuron Academy" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:type" content="article" />
    <meta property="twitter:url" content={url} />
    <meta property="twitter:image" content={image} />
    <meta property="twitter:site_name" content="Happyneuron Academy" />
  </>
);

export const getTitle = ({
  isFormation, refetchTraining, refetchContent, trainingData, contentData, home,
}) => {
  if (isFormation && !refetchTraining) return trainingData?.seo?.metaTitle;
  if (!isFormation && !refetchContent) return contentData?.seo?.metaTitle;
  if (home === 'paiement' || home === 'abonnement') return 'Paiement - Happyneuron Academy';
  return 'Happyneuron Academy';
};
export const getTitleOG = ({
  isFormation, refetchTraining, refetchContent, trainingData, contentData, home,
}) => {
  if (isFormation && !refetchTraining) return trainingData?.title;
  if (!isFormation && !refetchContent) return contentData?.title;
  if (home === 'paiement' || home === 'abonnement') return 'Paiement - Happyneuron Academy';
  return 'Happyneuron Academy';
};

export const getOgImage = ({
  home, id, contentData, isFormation, trainingData,
}) => {
  let ogImage = getPermanentUrl('sharing-assets/share-default.png');
  if (home === 'tuto') {
    ogImage = getPermanentUrl('sharing-assets/share-tuto.png');
  } else if (id) {
    if (contentData?.contentType === 'MEMOIR') {
      ogImage = getPermanentUrl('sharing-assets/vignette-memoir.png');
    } else if (contentData?.contentType === 'INTERVIEW') {
      ogImage = getPermanentUrl('sharing-assets/vignette-interview.png');
    } else ogImage = getPermanentUrl(isFormation ? trainingData?.image : contentData?.image, '-1200x630');
  }
  return ogImage;
};
