const getHumanHoursWithZero = (d) => {
  if (!d) return '';
  const date = new Date(d);
  const hours = date?.getHours();
  if (hours === 0) return '00';
  if (hours < 10) return `0${hours}`;
  return hours;
};

export default getHumanHoursWithZero;
