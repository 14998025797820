import styled from 'styled-components';

const DivLoginLayout = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	position: relative;
`;

const DivAutoLoginLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--footer-height));
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100;
`;

const DivLeftSection = styled.div`
  background-color: #fff;
  height: 100%;
  position: relative;
  width: 42%;
`;

const DivRightSection = styled.div<any>`
  background-color: var(--color-white-01);
  height: calc(100vh - var(--footer-height));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ mobile }) => (mobile ? '100%' : '58%')};
  overflow-y: auto;
  padding-bottom: 4vh;
  padding: ${({ mobile }) => mobile && '0 var(--layout-margin)'};
`;

const ImgBg = styled.div`
  height:  100%;
	object-fit: cover;
	object-position: left top;
	width: 100%;
`;

const ImgLogo = styled.div`
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 77%;
  height: 14%;
  z-index: 5;
`;

export default {
  DivLoginLayout,
  DivAutoLoginLayout,
  DivLeftSection,
  DivRightSection,
  ImgBg,
  ImgLogo,
};
